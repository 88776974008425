import DashboardTableList from "../../../componets/dashboard/dashboard-list-table";
import { TOP_PERFOMERS_HEADER } from "../../../utils/constants";
import useDashboardData from "../../../hooks/use-dashboard-data";
import { SCHOOL_TOP_PERFOMERS_API } from "../../../utils/api-constants";

const TopPerfomers = () => {
    const { loading, error, data } = useDashboardData(SCHOOL_TOP_PERFOMERS_API);
    return <DashboardTableList
        title='Top Performers'
        headers={TOP_PERFOMERS_HEADER}
        lists={data}
        loading={loading}
        error={error}
        month={true}
        schoolToppers={true}
    />
}

export default TopPerfomers;