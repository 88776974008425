import { NavLink, useLocation } from "react-router-dom";

const Tabbar = ({ tabs }) => {
    const location = useLocation();
    return <div className="tabbar radius3 elevation3">
        <div className="tabbar__content p1 radius2 depth3 d-flex gap3">
            {tabs.map((tab, i) =>
                <NavLink
                    key={i}
                    to={tab.path}
                    className={`tabbar__tab fl1 d-flex align-center justify-center p3 ${location.pathname.includes(tab.path) ? 'active' : ''}`}
                >
                    {tab.title}
                </NavLink>
            )}
        </div>
    </div>
}

export default Tabbar;