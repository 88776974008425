import { useDispatch, useSelector } from "react-redux";
import { Search } from "lucide-react";
import { updateSearch } from '../redux/global-slice';

const SearchFilter = () => {
    const dispatch = useDispatch();
    const search = useSelector(state => state.global.search);

    return <div className="p1 radius3 elevation3" style={{ width: '472px', position: 'relative' }}>
        <input
            placeholder="Search..." value={search}
            className="depth3 px4 radius2 large__body__regular"
            style={{ width: '100%', height: '48px', color: '#82868E' }}
            onChange={e => {
                var val = e.target.value;
                if (val && val.length > 0) {
                    const x = String(val).toLowerCase();
                    dispatch(updateSearch(x))
                } else {
                    dispatch(updateSearch(''))
                }
            }}
        />
        <div className="py2 px8" style={{
            borderRadius: '6px',
            border: '1.4px solid #3A308733',
            background: 'linear-gradient(132.44deg, rgba(102, 68, 200, 0.4) 0%, rgba(102, 68, 200, 0.16) 101.7%)',
            boxShadow: '-2px -2px 1.5px 0px #92929240 inset, 3px 3px 3.5px 0px #92929240 inset, 2px 2px 1.5px 0px #FFFFFF59 inset',
            position: 'absolute',
            right: '8px',
            top: '8px',
            bottom: '8px',
        }}>
            <Search color="#3A3087" size={24} />
        </div>
    </div>
}

export default SearchFilter;