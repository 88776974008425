const ComingSoonTag = () => {
    return <div
        style={{
            position: 'absolute',
            width: ' 95px',
            height: '20px',
            padding: '2px 4px',
            background: 'linear-gradient(135deg, #FD6E6A 0%, #FFC600 100%)',
            fontFamily: 'Source Sans 3',
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: 1,
            zIndex: 1000,
            right: '10px',
            bottom: '-12px',
            color: '#FFFFFF',
        }}
        className='radius1'
    >Coming Soon</div>
}

export default ComingSoonTag;