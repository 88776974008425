import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trash2 } from 'lucide-react';
import ThemeCategory from "./theme-category";
import { CLASSES_PATH, CLASSROOM_PATH } from "../utils/route-constants";
import CircularProgress from './progress/circular-progress';
import { addToFavouriteAPI, removeFromFavouriteAPI } from "../service/favourite-service";
import { filtersReducer } from "../redux/reducers";

const IlmList = ({ lists = [], isFavourites = false, getList }) => {
    const navigate = useNavigate();
    const [ilms, setIlms] = useState([]);
    const search = useSelector(state => state.global.search);
    const filters = useSelector(filtersReducer);

    useEffect(() => { setIlms(lists) }, [lists]);

    // filter by theme && filter for search
    useEffect(() => {
        if (search.length > 0 || filters.length > 0) {
            var itemes = [...lists];
            if (search.length > 0) {
                itemes = lists.filter(x => x.name.toLowerCase().includes(search));
            }
            if (filters.length > 0) {
                itemes = itemes.filter(e => filters.includes(e['skill_id']));
            }
            setIlms(itemes);
        } else {
            setIlms(lists);
        }
    }, [search, filters]);

    return <div className="d-flex flex-col gap12">
        {ilms && ilms.length > 0 ? ilms.map(list => <div
            key={list.id}
            className="d-flex gap12 animate"
            style={{ height: '198px', cursor: 'pointer' }}
            onClick={e => {
                e.stopPropagation();
                navigate(`/${CLASSROOM_PATH}/${list['ilm_id']}/${CLASSES_PATH}`);
            }}
        >
            <div className="bg-surface-element radius3 p2 elevation3" style={{ width: '340px' }}>
                <img src={list.thumbnail} alt={list.name} style={{ borderRadius: '7px' }} />
            </div>
            <div className="d-flex flex-col justify-between fl1">
                <div>

                    <div className="large__header5__bold" style={{ marginBottom: '6px' }}>{list.name}</div>
                    <div className="d-flex gap4 align-center">
                        <ThemeCategory theme={list['theme_category']} shadow={true} />
                        <div className="large__body__regular" style={{ color: '#4839CD' }}>{list['skill_name']}</div>
                    </div>
                </div>
                <div className="d-flex gap8">
                    <div className="p4 radius3 elevation3 d-flex gap4" style={{ background: 'linear-gradient(132deg, #F7F7F8 -2.9%, #F4F4F6 102.91%)', }}>
                        <CircularProgress size="72px" progress={list['progress_percentage'] ?? 0} text={`${list['progress_percentage'] ?? 0}%`} />
                        <div className="depth3" style={{ width: '160px', borderRadius: '6px', padding: '6px 16px' }}>
                            <div className="d-flex">
                                <div className="large__header6__bold">{list['completed_classes']}</div>
                                <div className="large__header6__regular">/{list['total_classes']}</div>
                            </div>
                            <div className="large__header6__regular" style={{ color: '#696F79' }}>Classes</div>
                        </div>
                        {Number(list['total_projects']) > 0 && <div className="depth3" style={{ width: '160px', borderRadius: '6px', padding: '6px 16px' }}>
                            <div className="d-flex">
                                <div className="large__header6__bold">{list['uploaded_projects']}</div>
                                <div className="large__header6__regular">/{list['total_projects']}</div>
                            </div>
                            <div className="large__header6__regular" style={{ color: '#696F79' }}>Project</div>
                        </div>}
                    </div>
                    {Number(list['total_daily_quests']) > 0 && <div className="p4 elevation3" style={{ width: '192px', borderRadius: '6px', background: 'linear-gradient(132deg, #F7F7F8 -2.9%, #F4F4F6 102.91%)', }}>
                        <div style={{ padding: '6px 16px' }}>
                            <div className="d-flex">
                                <div className="large__header6__bold">{list['completed_daily_quests']}</div>
                                <div className="large__header6__regular">/{list['total_daily_quests']}</div>
                            </div>
                            <div className="large__header6__regular" style={{ color: '#696F79' }}>Daily Quest</div>
                        </div>
                    </div>}
                </div>
            </div>
            {isFavourites && <div
                className="bg-surface-element radius3 elevation3 d-flex align-center justify-center"
                style={{ height: '48px', width: '48px' }}
                onClick={e => {
                    e.stopPropagation();
                    if (list['is_favorites'] === '1') {
                        removeFromFavouriteAPI(list['skill_id'], list['theme_id'], list['ilm_id']).then((status) => {
                            if (String(status) === '200') {
                                getList();
                            }
                        });
                    } else {
                        addToFavouriteAPI(list['skill_id'], list['theme_id'], list['ilm_id']).then((status) => {
                            if (String(status) === '200') {
                                getList();
                            }
                        });
                    }
                }}>
                <Trash2 size={24} color="red" />
            </div>}
        </div>) : <div className="d-flex align-center justify-center">No ilms found</div>}
    </div>
}

export default IlmList;