// type: pending is 0, approved is 1, rejected is 2
const ProjectStatusChip = ({ type = 0, table = false }) => {
  return (
    type > 0 && (
      <div
        className="large__label2__bold radius1"
        style={{
          textAlign: "center",
          height: "22px",
          padding: "2px 8px",
          background: type === 2 ? "#FF7448" : "#5DDA5D",
          color: "white",
        }}
      >
        {type === 2
          ? !table
            ? "Rejected"
            : "To Be Rejected"
          : !table
          ? "Approved"
          : "To Be Approved"}
      </div>
    )
  );
};

export default ProjectStatusChip;
