import SupportTicketList from '../../componets/support/support-ticket-list';
import TicketStatus from '../../componets/support/ticket-status'
import { useEffect, useState } from "react";
import { RAISE_TICKET_PATH } from '../../utils/route-constants';
import { useDispatch } from "react-redux";
import { updateLoading } from "../../redux/global-slice";
import { useNavigate } from 'react-router-dom';
import { getSupportListAPI } from '../../service/support-service';

const TicketList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tickets, setTickets] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        dispatch(updateLoading(true));
        getSupportListAPI().then(res => {
            setTickets(res);
            if (res && res.length > 0) {
                setSelected(res[0]);
            }
        }).finally(() => dispatch(updateLoading(false)));

    }, []);

    return (
        <div className="ticket__list">
            <div className="support__tickets elevation4 radius6 d-flex flex-col justify-between">
                <div className='p4' style={{ paddingBottom: '8px' }}>
                    <div className="large__header3__bold">Support Tickets</div>
                    <div className="large__header5__regular">Please find all you support tickets here</div>
                </div>
                <SupportTicketList tickets={tickets ?? []} selected={selected} setSelected={setSelected} />
                <div className='p4' style={{ paddingTop: '8px !important' }}>
                    <button style={{ width: '100%' }} className='primary' onClick={() => navigate(`${RAISE_TICKET_PATH}`)}>Raise a Concern</button>
                </div>
            </div>
            <div className="support__ticket__view fl1 elevation4 radius6 p4 d-flex flex-col gap3">
                <div className="d-flex justify-between gap3">
                    <div className="large__header3__bold">Support ID</div>
                    <div className="large__header5__bold py1 px3 radius1" style={{ minWidth: '165px', height: '38px', background: '#FFDEF6', color: 'var(--pRose500)' }}>
                        {selected ? `${selected.ticket}` : '-'}
                    </div>
                </div>
                <div className="d-flex gap6">
                    <div className="small__caption__caption" style={{ width: '130px' }}>Created Date</div>
                    <div className="small__caption__caption" style={{ color: 'var(--neutral600)' }}>
                        {selected ? selected.issue_raised_date : '-'}
                    </div>
                </div>
                <div className="d-flex gap6">
                    <div className="small__caption__caption" style={{ width: '130px' }}>Status</div>
                    {selected ? <TicketStatus status={selected.status} /> : '-'}
                </div>
                <div className="d-flex gap6">
                    <div className="small__caption__caption" style={{ width: '130px' }}>Resolution Date</div>
                    <div className="small__caption__caption" style={{ color: 'var(--neutral600)' }}>
                        {selected && selected.issue_resolved_date !== null ? selected.issue_resolved_date : '-'}
                    </div>
                </div>
                {selected && <div className="small__caption__caption">Reported Issue</div>}
                {selected && <div className="small__body__body__text2 p3 radius2" style={{ background: 'var(--surfaceBGWhite)', boxShadow: 'var(--depth3)' }}>
                    {selected.queries}
                </div>}
            </div>
        </div>
    );
}
export default TicketList;