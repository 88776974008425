import { useRef, useState } from "react";

const VideoPlayerCard = ({
  src = "",
  videoKey,
  title,
  poster,
  updateProgress = () => {},
}) => {
  const videoRef = useRef(null);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <div className="player__container">
      {/* video element */}
      {src !== null && src.length > 0 && (
        <video
          ref={videoRef}
          key={videoKey}
          title={title}
          src={src}
          autoPlay={true}
          poster={poster}
          controls
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          disableRemotePlayback
          onContextMenu={(e) => e.preventDefault()}
          onTimeUpdate={() => {
            let totalDuration = Math.floor(videoRef.current?.duration);
            let seconds = Math.floor(videoRef.current?.currentTime);
            let per = Math.round((seconds / totalDuration) * 100);
            setCurrentDuration(seconds);
            setPercentage(per);
            updateProgress(seconds, totalDuration);
            console.log(percentage + " %");
          }}
          onSeeking={() => {
            // console.log('current ' + videoRef.current.currentTime, currentDuration)
            if (videoRef.current.currentTime > currentDuration) {
              videoRef.current.currentTime = currentDuration;
            }
          }}
          onWaiting={() => setLoading(true)}
          onPlay={() => setLoading(false)}
          onPlaying={() => setLoading(false)}
          onLoadStart={() => setLoading(true)}
          onLoadedData={() => setLoading(false)}
          onEnded={() => {
            setCurrentDuration(0);
            setPercentage(0);
          }}
          onError={() => setError(true)}
        />
      )}
      {/* video source is empty or null */}
      {(src === null || src.length <= 0 || error) && (
        <div
          className="d-flex flex-col align-center justify-center elevation2"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: "white",
          }}
        >
          <span>Couldn't load media. Please try again! or</span>
          <span>Contact Support</span>
        </div>
      )}
      {/* loader while loading the video */}
      {src == null && src.length > 0 && !error && loading && (
        <div className="spinner__parent">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

export default VideoPlayerCard;

/*onProgress={() => {
                // if (!videoRef.current.buffered) return;
                // const bufferedEnd = videoRef.current.buffered.end(videoRef.current.buffered.length - 1);
                // const duration = videoRef.current.duration;
                // const per = (bufferedEnd / duration) * 100;
                // console.log(currentDuration, bufferedEnd, duration, per, videoRef.current.buffered);
            }}*/

/*import { Maximize, Pause, Play, Rewind, Volume2, VolumeX } from 'lucide-react';
import LinearProgress from './progress/linear-progress';
import { useEffect, useRef, useState } from 'react';

const VideoPlayerCard = ({
    src = 'https://ilm-uploads.s3.amazonaws.com/content-module-ilms/ilms/Intro_video_L2C03_Ci.mp4',
    key,
    poster,
    updateProgress = () => { }
}) => {

    const videoRef = useRef();
    const videoParent = useRef();
    const [isPlaying, setIsPlaying] = useState(true);
    const [isWaiting, setIsWaiting] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [currentDuration, setCurrentDuration] = useState(0);
    const [error, setError] = useState(false);
    const [buffered, setBuffered] = useState(0);

    // handle play/pause
    const handlePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
        }

    };

    // rewind back 10 sec
    const rewindBack = () => {
        const totalDuration = Math.floor(videoRef.current.duration);
        const seconds = currentDuration - 10;
        const perc = Math.round((seconds / totalDuration) * 100);
        videoRef.current.currentTime = seconds;
        setPercentage(perc);
    };

    // vide full screen
    const fullScreenFunction = () => {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            // if (videoParent)
            if (videoParent.current.requestFullscreen) {
                videoParent.current.requestFullscreen();
            } else if (videoParent.current.mozRequestFullScreen) {
                videoParent.current.mozRequestFullScreen();
            } else if (videoParent.current.webkitRequestFullScreen) {
                videoParent.current.webkitRequestFullScreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    };

    // while video is getting buffered
    const onWaiting = () => {
        console.log('on waiting')
        setIsWaiting(true);
        setError(false);
    };

    // while video is playing
    const onPlay = () => {
        console.log('on play')
        if (isWaiting) setIsWaiting(false);
        setIsPlaying(true);
        setError(false);
    };

    // while video is paused
    const onPause = () => {
        console.log('on pause')
        setIsPlaying(false);
    };

    // set error
    const onError = () => setError(true);

    // when the video is playing & time frames are getting updated
    const onTimeUpdate = e => {
        setIsWaiting(false);
        const existingDuration = Math.floor(e.target?.duration);
        const seconds = Math.floor(e.target?.currentTime);
        const percentage = Math.round((seconds / existingDuration) * 100);
        setCurrentDuration(seconds);
        setPercentage(percentage);
        updateProgress();
        console.log('here ', isWaiting, isPlaying);
    };

    // initialize 
    useEffect(() => {
        if (!videoRef.current) return;

        const element = videoRef.current;

        // while progress
        const onProgress = () => {
            if (!element.buffered) return;
            // const len = (element.buffered.length <= 0 || element.buffered.length > 1) ? 1 : element.buffered.length;
            const bufferedEnd = element.buffered.end(element.buffered.length - 1);
            const duration = element.duration;
            const per = (bufferedEnd / duration) * 100;
            setBuffered(per);
            console.log(currentDuration, bufferedEnd, duration, per, element.buffered);
        };

        element.addEventListener('error', onError);
        element.addEventListener('click', handlePlayPause);
        element.addEventListener('progress', onProgress);
        element.addEventListener('timeupdate', onTimeUpdate);
        element.addEventListener('waiting', onWaiting);
        element.addEventListener('play', onPlay);
        element.addEventListener('playing', onPlay);
        element.addEventListener('pause', onPause);

        // clean up
        return () => {
            setPercentage(0);
            setCurrentDuration(0);
            // videoRef.current?.load();
            element.removeEventListener('error', onError);
            element.removeEventListener('click', handlePlayPause);
            element.removeEventListener('waiting', onWaiting);
            element.removeEventListener('play', onPlay);
            element.removeEventListener('playing', onPlay);
            element.removeEventListener('pause', onPause);
            element.removeEventListener('progress', onProgress);
            element.removeEventListener('timeupdate', onTimeUpdate);
        };
    }, [src]);

    return <div className='player__container' ref={videoParent}>
        {src !== null && src.length > 0 && <video ref={videoRef} key={key} src={src} muted={isMuted} autoPlay={false} poster={poster} />}
        {!error && src !== null && src.length > 0 && <div className='lesson__controls d-flex gap2 align-center'>
            {!isPlaying ?
                <Play color='white' size={18} onClick={handlePlayPause} /> :
                <Pause color='white' size={18} onClick={handlePlayPause} />
            }
            <LinearProgress percent={percentage} isLesson={true} />
            <Rewind size={18} onClick={rewindBack} color='white' />
            {!isMuted ?
                <Volume2 size={18} onClick={() => setIsMuted(true)} color='white' /> :
                <VolumeX size={18} onClick={() => setIsMuted(false)} color='white' />
            }
            <Maximize size={18} color='white' onClick={() => fullScreenFunction()} />
        </div>} 
        {(src === null || src.length <= 0) && <div
            className='d-flex align-center justify-center'
            style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        >Coming Soon!</div>}
        {isWaiting && <div className='spinner__parent'>
            <div className='spinner' />
        </div>} 
        <div style={{ color: 'white', position: 'absolute', bottom: '20px', }}>{buffered} poor network connection</div> 
    </div>
}

export default VideoPlayerCard;*/
