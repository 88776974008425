import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getParsedGrade } from '../../utils/constants';
import LoadingError from '../dashboard/loading-error';

// grade render
const _renderSectionTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    return <text x={x} y={y + 16} textAnchor='middle' className='large__header6__regular'>{payload.value}</text>
};

const StackedChartCard = ({ detail = true, loading, error, lists, setFinalData }) => {

    const [data, setData] = useState([]);

    // grade render
    const _renderGradeTick = (props) => {
        const { x, index, payload } = props;
        const current = data[index];
        const allFiltered = data.filter(e => e['grade'] === payload.value)
        if (allFiltered.length <= 1) {
            return <text x={x} y={-4} textAnchor='middle' className='large__label1__bold'>Grade {payload.value}</text>
        }
        const currentIndex = allFiltered.findIndex(e => e['grade'] === current['grade'] && e['section'] === current['section']);
        const updatedX = allFiltered.length % 2 !== 0 ? x : x - (allFiltered.length * 8);
        const textAnchor = allFiltered.length % 2 === 0 ? 'end' : 'middle';
        return Math.floor(allFiltered.length / 2) === currentIndex && <text x={updatedX} y={-4} textAnchor={textAnchor} className='large__label1__bold'>Grade {payload.value}</text>
    };

    useEffect(() => {
        var finalData = [];
        const keys = Object.keys(lists);
        for (let k = 0; k < keys.length; k++) {
            const res = lists[keys[k]];
            const sectionkeys = Object.keys(res);
            for (let i = 0; i < sectionkeys.length; i++) {
                const x = res[sectionkeys[i]];
                const grade = getParsedGrade(keys[k]);
                const active = detail ? 'logged_in' in x ? x['logged_in'] : 0 : 'active' in x ? x['active'] : 0;
                const active0 = detail ? 'not_logged_in' in x ? x['not_logged_in'] : 0 : 'fairly_active' in x ? x['fairly_active'] : 0;
                var obj = {
                    grade: grade,
                    section: sectionkeys[i],
                    name: grade + ' ' + sectionkeys[i],
                    [!detail ? 'Active' : 'Logged In']: active,
                    [!detail ? 'Fairly Active' : 'Yet To Login In']: active0,
                }
                if (!detail) {
                    let inactive = 'inactive' in x ? x['inactive'] : 0;
                    obj['Inactive'] = inactive
                }
                finalData.push(obj)
            }
        }
        setData(finalData);
        setFinalData(finalData);
    }, [detail, lists]);

    return (loading || error) ?
        <LoadingError loading={loading} error={error} height='100%' /> :
        lists === null || lists.length <= 0 ?
            <div className='d-flex align-center justify-center' style={{ height: '100%' }}>Inadequate Product Usage to Display Data</div> :
            <ResponsiveContainer width='100%' height='100%' className='mt3'>
                <BarChart data={data} >
                    <Tooltip />
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='section' strokeDasharray={0.01} tickLine={false} tick={_renderSectionTick} />
                    <XAxis dataKey='grade' axisLine={false} tickLine={false} height={1} tick={_renderGradeTick} scale='auto' xAxisId='grade' />
                    <YAxis tickLine={false} strokeDasharray={0.01} fontSize={19} fontWeight={500} />
                    <Bar dataKey={!detail ? 'Active' : 'Logged In'} barSize={44} stackId='a' fill={detail ? '#BCFEBC' : '#5DDA5D'} />
                    <Bar dataKey={!detail ? 'Fairly Active' : 'Yet To Login In'} barSize={44} stackId='a' fill={detail ? '#FED07E' : '#F4A71D'} />
                    {!detail && <Bar dataKey='Inactive' barSize={44} stackId='a' fill='#FF7448' />}
                </BarChart>
            </ResponsiveContainer>;
}
export default StackedChartCard;
