import { useState } from "react";
import dailyQuest from "../assets/project/daily-quest.png";
import Dialog from "./dialog";
import { getFileSoucePath, videoFilesFomatList } from "../utils/constants";
import { LockIcon } from "lucide-react";
import ToastMessage from "./toast-message";
import VideoPlayerCard from "./players/video-player-card";
import ScormPlayerCard from "./players/scorm-player-card";

const DailyQuestCard = ({ index, quest }) => {
  const [dialog, setDialog] = useState(false);
  const [toast, setToast] = useState(false);
  return (
    <>
      <div
        className="radius3 p2 elevation4 bg-surface-element"
        onClick={() => {
          if (quest) setDialog(true);
          else setToast(true);
        }}
      >
        <div
          className="radius3 d-flex align-center justify-center"
          style={{
            width: "100%",
            height: "176px",
            overflow: "hidden",
            position: "relative",
            cursor: "pointer",
            background:
              "linear-gradient(0deg, #7364EC, #7364EC), radial-gradient(50% 50% at 50% 50%, rgba(82, 71, 168, 0) 0%, rgba(82, 71, 168, 0.9) 100%)",
          }}
        >
          <img src={dailyQuest} alt="Daily Quest" />
          <div
            className="d-flex align-center justify-center"
            style={{
              fontFamily: "Madimi One",
              fontSize: "56px",
              fontWeight: "400",
              letterSpacing: "2.200000047683716px",
              textAlign: "center",
              position: "absolute",
              height: "100%",
              width: "100%",
              color: "#F3A61D",
              WebkitTextStroke: "2px #FFFFFF",
            }}
          >
            Daily Quest {index}
          </div>
          {!quest && (
            <div
              className="d-flex justify-between"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "10px 18px",
                background:
                  "linear-gradient(0deg, #3020AB 3.79%, rgba(48, 32, 171, 0) 100%)",
              }}
            >
              <div className="large__label1__bold" style={{ color: "#FFFFFF" }}>
                Daily Quest {index}
              </div>
              <LockIcon color="#ffffff" />
            </div>
          )}
        </div>
      </div>
      {/* view daily quest */}
      {quest && (
        <Dialog dialog={dialog} setDialog={() => setDialog(false)}>
          <div className="fl1" style={{ minHeight: "60vh", minWidth: "760px" , display: 'flex'}}>
            {videoFilesFomatList.includes(quest["file_type"]) ? (
              <VideoPlayerCard
                src={quest.class_source}
                title={quest.class_name}
                quest={quest}
              />
            ) : (
              <ScormPlayerCard
                src={getFileSoucePath(quest.file_type, quest.class_source)}
                title={quest.class_name}
                quest={quest}
              />
            )}
          </div>
        </Dialog>
      )}
      {/* toast message */}
      <ToastMessage
        setToast={setToast}
        toast={toast}
        seconds={5000}
        title="Content Unvailable"
        text={
          "Sorry, this content is currently unavailable. We're working on it.\n Please check back later."
        }
      />
    </>
  );
};

export default DailyQuestCard;
