import { useState } from "react";
import Dialog from "../dialog";
import { getFileExtension } from "../../utils/utils";
import ProjectStatusChip from "../chips/project-status-chip";
import ProjectSubmissionDate from "./project-submission-date";
import {
  videoFilesFomatList,
  imgFilesFomatList,
  iaFilesFomatList,
  audioFilesFormat,
} from "../../utils/constants";
import { ChevronRight, Film, Fullscreen } from "lucide-react";
import projectAudio from "../../assets/project/project_audio.png";
import projectDoc from "../../assets/project/project_doc.png";
import projectImage from "../../assets/project/project_image.png";
import projectMedia from "../../assets/project/project_media.png";
import VideoPlayerCard from "../players/video-player-card";
import ScormPlayerCard from "../players/scorm-player-card";

const ProjectViewCard = ({ project, type }) => {
  const [dialog, setDialog] = useState(false);
  return (
    <>
      <div
        className="d-flex flex-col gap3 p3"
        style={{ height: "100%", overflow: "hidden" }}
      >
        <div
          className="p4 radius4 depth4 d-flex flex-col gap5"
          style={{ overflowY: "scroll", height: "100%" }}
        >
          <div className="d-flex flex-col gap2">
            <div className="d-flex align-center justify-between">
              <div
                className="large__header6__bold"
                style={{ color: "#3E444F" }}
              >
                {project["student_name"]}
              </div>
              <ProjectStatusChip type={type} />
            </div>
            <ProjectSubmissionDate date={project["created"]} />
          </div>
          <div className="d-flex gap8">
            <div className="d-flex flex-col">
              <div className="large__label2__regular">Theme</div>
              {/* // 1 is core, 2 is special, 3 is creative */}
              <div className="large__body__bold" style={{ color: "#594BDD" }}>
                {String(project["theme_category"]) === "1"
                  ? "Core"
                  : String(project["theme_category"]) === "2"
                  ? "Special"
                  : "Creative"}
              </div>
            </div>
            <div className="d-flex flex-col">
              <div className="large__label2__regular">Skill Program Name</div>
              <div className="large__body__bold" style={{ color: "#594BDD" }}>
                {project["program_name"]}
              </div>
            </div>
            <div className="d-flex flex-col fl1">
              <div className="large__label2__regular">ILM Name</div>
              <div
                className="large__body__bold text__elipsis"
                style={{ color: "#594BDD", WebkitLineClamp: 1 }}
              >
                {project["ilm_name"] ?? project["title"]}
              </div>
            </div>
          </div>
          <div className="d-flex gap2">
            <div className="d-flex flex-col fl1" style={{ gap: "6px" }}>
              <div className="large__body__bold">Submitted Project FIle</div>
              <div
                className={`radius3 ${
                  project["thumbnail"] === null ||
                  project["thumbnail"].length < 1
                    ? "d-flex flex-col align-center justify-center elevation2 gap2"
                    : ""
                }`}
                style={{
                  overflow: "hidden",
                  position: "relative",
                  height: "212px",
                  width: "212px",
                  background:
                    project["thumbnail"] !== null &&
                    project["thumbnail"].length > 1
                      ? "#F7F7FB"
                      : "transparent",
                  cursor:
                    project["thumbnail"] !== null &&
                    project["thumbnail"].length > 1
                      ? "defaul"
                      : "pointer",
                }}
                onClick={() => {
                  if (
                    project["thumbnail"] === null ||
                    project["thumbnail"].length < 1
                  ) {
                    setDialog(true);
                  }
                }}
              >
                {project["thumbnail"] !== null &&
                  project["thumbnail"].length > 1 && (
                    <img
                      src={project["thumbnail"]}
                      alt={project["description"]}
                    />
                  )}
                {project["thumbnail"] !== null &&
                  project["thumbnail"].length > 1 && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        background:
                          "radial-gradient(60.16% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 100%)",
                      }}
                    />
                  )}
                {project["thumbnail"] !== null &&
                  project["thumbnail"].length > 1 && (
                    <div
                      className="d-flex align-center justify-center"
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                      }}
                    >
                      <Film color="#ffffff" />
                    </div>
                  )}
                {project["thumbnail"] !== null &&
                  project["thumbnail"].length > 1 && (
                    <button
                      className="link medium"
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        background: "transparent",
                        color: "#ffffff",
                      }}
                      onClick={() => setDialog(true)}
                    >
                      <Fullscreen size={20} />
                      <span>View Project File</span>
                    </button>
                  )}
                {(project["thumbnail"] === null ||
                  project["thumbnail"].length < 1) && (
                  <div style={{ height: "88px", width: "88px" }}>
                    <img
                      src={
                        videoFilesFomatList.includes(
                          getFileExtension(project["project_src"])
                        )
                          ? projectMedia
                          : iaFilesFomatList.includes(
                              getFileExtension(project["project_src"])
                            )
                          ? projectImage
                          : audioFilesFormat.includes(
                              getFileExtension(project["project_src"])
                            )
                          ? projectAudio
                          : projectDoc
                      }
                      alt={project["title"]}
                    />
                  </div>
                )}
                {(project["thumbnail"] === null ||
                  project["thumbnail"].length < 1) && (
                  <div
                    className="d-flex align-center justify-center gap1 elevation1 px2 py1 radius1"
                    style={{ maxWidth: "180px" }}
                  >
                    <div
                      className="large__label2__bold"
                      style={{ color: "#7364EC" }}
                    >
                      {project["title"]}
                    </div>
                    <ChevronRight color="#7364EC" size={16} />
                  </div>
                )}
              </div>
            </div>
            {/* <div className='d-flex flex-col fl1' style={{ gap: '6px' }}>
                        <div className='large__body__bold'>Reference File</div>
                        <div className='radius3' style={{ height: '212px', width: '212px', background: '#695BD7' }}></div>
                    </div> */}
          </div>
          <div className="d-flex flex-col" style={{ gap: "6px" }}>
            <div className="large__body__bold">Project Description</div>
            <div className="large__body__regular" style={{ color: "#696F79" }}>
              {project["description"]}
            </div>
          </div>
        </div>
      </div>
      {/* preview file */}
      <Dialog dialog={dialog} setDialog={() => setDialog(false)} maxWidth="">
        {videoFilesFomatList.includes(
          getFileExtension(project["project_src"])
        ) ? (
          <div
            style={{
              minHeight: "50vh",
              maxHeight: "90vh",
              aspectRatio: "16 / 9",
            }}
          >
            <VideoPlayerCard
              videoKey={project["id"]}
              src={project["project_src"]}
              title={project["title"]}
            />
          </div>
        ) : imgFilesFomatList.includes(
            getFileExtension(project["project_src"])
          ) ? (
          <img
            src={project["project_src"]}
            alt={project["description"]}
            style={{
              minHeight: "40vh",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          />
        ) : (
          <div
            style={{
              minHeight: "60vh",
              maxHeight: "90vh",
              aspectRatio: "16 / 12",
            }}
          >
            <ScormPlayerCard
              key={project["id"]}
              title={project["title"]}
              src={project["project_src"]}
            />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ProjectViewCard;
