import { useState } from "react";
import Dialog from "../dialog";
import ToastMessage from "../toast-message";
import ProjectStatusChip from "../chips/project-status-chip";
import { getFullFormattedDate } from "../../utils/utils";
import { confirmProjectSelectionService } from "../../service/project-service";
import { updateRelodProjects } from "../../redux/global-slice";
import { useDispatch } from "react-redux";

const _className =
  "radius1 large__body__bold d-flex align-center justify-center";

const ProjectConformSelection = ({ lists = [] }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [toast, setToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(200);

  const confirmHandle = (e) => {
    e.preventDefault();
    const uid = window.localStorage.getItem("userId");
    const updatedList = lists.map((e) => {
      return {
        skill_id: e["skill_id"],
        user_id: e["user_id"],
        point: 150,
        type: e["type"],
        validator_id: uid,
        theme_id: e["theme_id"],
        ilm_id: e["ilm_id"],
        project_id: e["id"],
        status: e["status"], // approve 1, reject 2
      };
    });
    setLoading(true);
    confirmProjectSelectionService(updatedList)
      .then((res) => {
        setCount(lists.length);
        setStatus(Number(res));
        setToast(true);
        setDialog(false);
        dispatch(updateRelodProjects(true));
      })
      .catch(() => setToast(true))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {/* buttons */}
      <div className="mx5 d-flex justify-between align-center">
        <div className="d-flex gap6">
          <div className="d-flex flex-col" style={{ gap: "2px" }}>
            <div className="large__body__regular" style={{ color: "#696F79" }}>
              Marked Rejected
            </div>
            <div
              style={{
                height: "24px",
                width: "32px",
                background: "#FF7448",
                color: "white",
              }}
              className={_className}
            >
              {lists.filter((e) => e["status"] === 2).length}
            </div>
          </div>
          <div className="d-flex flex-col" style={{ gap: "2px" }}>
            <div className="large__body__regular" style={{ color: "#696F79" }}>
              Marked Approved
            </div>
            <div
              style={{
                height: "24px",
                width: "32px",
                background: "#5DDA5D",
                color: "white",
              }}
              className={_className}
            >
              {lists.filter((e) => e["status"] === 1).length}
            </div>
          </div>
        </div>
        <button
          className="primary meduim"
          onClick={() => setDialog(true)}
          disabled={lists.length <= 0}
        >
          Confirm Selection
        </button>
      </div>
      {/* confirm selection dialog */}
      <Dialog
        dialog={dialog}
        setDialog={() => setDialog(false)}
        maxWidth="1300px"
      >
        <div className="d-flex flex-col gap8 radius6 align-center py6 px18">
          <div className="d-flex flex-col align-center justify-center gap1">
            <div className="large__header3__bold">Confirm Project Status</div>
            <div
              className="large__body__regular"
              style={{ color: "#594BDD", textAlign: "center" }}
            >
              Please confirm your selections, All the projects submitted by
              following students will be updated as per selection
            </div>
          </div>
          <div className="radius2 p3 project__submission__table d-flex flex-col gap3">
            <div className="project__dialog__header">
              <div
                style={{
                  width: "70px",
                  padding: "12px 8px",
                  textAlign: "center",
                }}
              >
                Sl No.
              </div>
              <div style={{ width: "200px" }}>Student Name</div>
              <div>Submission Date</div>
              <div style={{ width: "270px" }}>Skill Program Name</div>
              <div className="fl1">ILM Name</div>
              <div style={{ width: "150px" }}>Status</div>
            </div>
            <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
              <table>
                <tbody>
                  {lists.map((e, i) => (
                    <tr key={i}>
                      <td style={{ width: "70px" }}>
                        {String(i + 1).padStart(2, "0")}
                      </td>
                      <td style={{ width: "200px" }}>{e["student_name"]}</td>
                      <td style={{ width: "153.5px", textAlign: "center" }}>
                        {getFullFormattedDate(e["created"])}
                      </td>
                      <td style={{ width: "270px" }}>
                        <div
                          className="large__label2__bold text__elipsis"
                          style={{ WebkitLineClamp: 2, color: "#3E444F" }}
                        >
                          {e["program_name"]}
                        </div>
                      </td>
                      <td>
                        <div
                          className="large__label2__regular text__elipsis"
                          style={{ WebkitLineClamp: 2, color: "#3E444F" }}
                        >
                          {e["ilm_name"] ?? e["title"]}
                        </div>
                      </td>
                      <td style={{ width: "148px" }}>
                        <ProjectStatusChip type={e["status"]} table={true} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex gap6" style={{ width: "100%" }}>
            <button className="fl1 secondary" onClick={() => setDialog(false)}>
              Go Back
            </button>
            <button className="fl1 primary" onClick={confirmHandle}>
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      {/* loading dialog */}
      <Dialog
        dialog={loading}
        setDialog={() => setLoading(false)}
        close={false}
      >
        <div className="mini__loader" />
      </Dialog>
      {/* toast message */}
      <ToastMessage
        type={status === 200 ? 0 : -1}
        setToast={setToast}
        toast={toast}
        title={status === 200 ? "Updated" : "Error"}
        text={
          status === 200
            ? `${count} entries updated`
            : "Something went wrong. Try again!"
        }
      />
    </>
  );
};

export default ProjectConformSelection;
