import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLesson, updateWatchedClasses } from "../../redux/lesson-slice";
import { updateLessonProgress } from "../../service/ilm-service";
import { classDetailsReducer, isLevel0Reducer, conductSessionReducer, lessonsReducer, playingLessonReducer, watchedClassesReducer } from "../../redux/reducers";
import { lessonsToCallAPI } from "../../utils/constants";
import VideoPlayerCard from '../players/video-player-card';

const LessonVideoPlayer = ({ grade, section }) => {

    const dispatch = useDispatch();
    const conductSession = useSelector(conductSessionReducer);
    const detail = useSelector(classDetailsReducer);
    const lesson = useSelector(playingLessonReducer);
    const lessons = useSelector(lessonsReducer);
    const watchedClasses = useSelector(watchedClassesReducer);
    const isLevel0 = useSelector(isLevel0Reducer);
    const [watched, setWatched] = useState([]);

    // update lesson progress
    const updateProgress = async (seconds, totalSeconds) => {
        if (!watchedClasses.includes(lesson['id']) && seconds > 0 && (seconds === 5 || totalSeconds === seconds) && !watched.includes(seconds) && (isLevel0 || (conductSession && lessonsToCallAPI.includes(lesson.classType)))) {
            var updated = [...watched];
            if (!updated.includes(seconds)) {
                updated.push(seconds);
                setWatched(updated);
                updateLessonProgress(
                    detail.skillType,
                    lesson.themeId,
                    lesson.skillId,
                    lesson.ilmId,
                    seconds,
                    lesson.id,
                    lesson.classType,
                    lesson.fileType,
                    totalSeconds === seconds ? 1 : 0,
                    detail.themeCategory,
                    grade,
                    section,
                ).then(() => {
                    if(totalSeconds === seconds) {
                        // when vide ends, go to next
                        const index = lessons.findIndex(e => e['id'] === lesson['id']) + 1;
                        dispatch(updateWatchedClasses(lesson['id']));
                        dispatch(changeLesson(index));
                        setWatched([]);
                    }
                });
            }
        }
    }

    // reset all params when lesson changes
    useEffect(() => { setWatched([]) }, [lesson]);

    return <VideoPlayerCard
        videoKey={lesson.id}
        src={lesson.classSource}
        poster={detail.thumbnail}
        title={lesson.className}
        updateProgress={updateProgress}
    />
}

export default LessonVideoPlayer;

// import { Maximize, Pause, Play, Rewind, Volume2, VolumeX } from "lucide-react";
// import { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { changeLesson, updateWatchedClasses } from "../../redux/lesson-slice";
// import { updateLessonProgress } from "../../service/ilm-service";
// import LinearProgress from '../progress/linear-progress';
// import { classDetailsReducer, isLevel0Reducer, conductSessionReducer, lessonsReducer, playingLessonReducer, watchedClassesReducer } from "../../redux/reducers";
// import { lessonsToCallAPI } from "../../utils/constants";

// const LessonVideoPlayer = ({ grade, section }) => {

//     const videoRef = useRef();
//     const videoParent = useRef();
//     const dispatch = useDispatch();
//     const conductSession = useSelector(conductSessionReducer);
//     const detail = useSelector(classDetailsReducer);
//     const lesson = useSelector(playingLessonReducer);
//     const lessons = useSelector(lessonsReducer);
//     const watchedClasses = useSelector(watchedClassesReducer);
//     const isLevel0 = useSelector(isLevel0Reducer);
//     const [watched, setWatched] = useState([]);
//     const [isPlaying, setIsPlaying] = useState(true);
//     const [isMuted, setIsMuted] = useState(false);
//     const [percentage, setPercentage] = useState(0);
//     const [currentDuration, setCurrentDuration] = useState(0);

//     // update lesson progress
//     const updateProgress = (e) => {
//         const existingDuration = Math.floor(e.target.duration);
//         const seconds = Math.floor(e.target.currentTime);
//         setCurrentDuration(seconds);
//         const percentage = Math.round((seconds / existingDuration) * 100);
//         setPercentage(percentage);
//         if (!watchedClasses.includes(lesson['id']) && seconds > 0 && (seconds === 5 || existingDuration === seconds) && !watched.includes(seconds) && (isLevel0 || (conductSession && lessonsToCallAPI.includes(lesson.classType)))) {
//             var updated = [...watched];
//             if (!updated.includes(seconds)) {
//                 updated.push(seconds);
//                 setWatched(updated);
//                 updateLessonProgress(
//                     detail.skillType,
//                     lesson.themeId,
//                     lesson.skillId,
//                     lesson.ilmId,
//                     seconds,
//                     lesson.id,
//                     lesson.classType,
//                     lesson.fileType,
//                     existingDuration === seconds ? 1 : 0,
//                     detail.themeCategory,
//                     grade,
//                     section,
//                 );
//             }
//         }
//         if (existingDuration === seconds) {
//             const index = lessons.findIndex(e => e['id'] === lesson['id']) + 1;
//             dispatch(updateWatchedClasses(lesson['id']));
//             dispatch(changeLesson(index));
//             setWatched([]);
//         }
//     }

//     // handle play/pause
//     const handlePlayPause = () => {
//         const video = videoRef.current;
//         if (video.paused) {
//             video.play();
//             setIsPlaying(true);
//         } else {
//             video.pause();
//             setIsPlaying(false);
//         }
//     };

//     // rewind back 10 sec
//     const rewindBack = () => {
//         const totalDuration = Math.floor(videoRef.current.duration);
//         const seconds = currentDuration - 10;
//         const perc = Math.round((seconds / totalDuration) * 100);
//         videoRef.current.currentTime = seconds;
//         setPercentage(perc);
//     };

//     // vide full screen
//     const fullScreenFunction = () => {
//         if (
//             !document.fullscreenElement &&
//             !document.mozFullScreenElement &&
//             !document.webkitFullscreenElement &&
//             !document.msFullscreenElement
//         ) {
//             // if (videoParent)
//             if (videoParent.current.requestFullscreen) {
//                 videoParent.current.requestFullscreen();
//             } else if (videoParent.current.mozRequestFullScreen) {
//                 videoParent.current.mozRequestFullScreen();
//             } else if (videoParent.current.webkitRequestFullScreen) {
//                 videoParent.current.webkitRequestFullScreen();
//             }
//         } else {
//             if (document.exitFullscreen) {
//                 document.exitFullscreen();
//             } else if (document.mozCancelFullScreen) {
//                 document.mozCancelFullScreen();
//             } else if (document.webkitExitFullscreen) {
//                 document.webkitExitFullscreen();
//             }
//         }
//     };

//     // reset all params when lesson changes
//     useEffect(() => {
//         setWatched([]);
//         setPercentage(0);
//         setCurrentDuration();
//         videoRef.current?.load();
//     }, [lesson]);

//     return <div className='player__container' ref={videoParent}>
//         {lesson.classSource !== null && lesson.classSource.length > 0 && <video
//             ref={videoRef}
//             key={lesson.id}
//             src={lesson.classSource}
//             onTimeUpdate={updateProgress}
//             muted={isMuted} autoPlay
//             poster={detail.thumbnail}
//             onClick={handlePlayPause}
//         />}
//         {lesson.classSource !== null && lesson.classSource.length > 0 && <div className="lesson__controls d-flex gap2 align-center">
//             {!isPlaying ?
//                 <Play color="white" size={18} onClick={handlePlayPause} /> :
//                 <Pause color="white" size={18} onClick={handlePlayPause} />
//             }
//             <LinearProgress percent={percentage} isLesson={true} />
//             <Rewind size={18} onClick={rewindBack} color="white" />
//             {!isMuted ?
//                 <Volume2 size={18} onClick={() => setIsMuted(true)} color="white" /> :
//                 <VolumeX size={18} onClick={() => setIsMuted(false)} color="white" />
//             }
//             <Maximize size={18} color="white" onClick={() => fullScreenFunction()} />
//         </div>}
//         {(lesson.classSource === null || lesson.classSource.length <= 0) && <div
//             className='d-flex align-center justify-center'
//             style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
//         >Coming Soon!</div>}
//     </div>
// }

// export default LessonVideoPlayer;