import { useSelector } from "react-redux";
import CircularChartCard from "../charts/circular-chart-card";
import LoginStatusIndicator from "./login-status-indicator";
import { dashboardUserStatsReducer } from "../../redux/reducers";

const UserStatsCard = () => {
  const data = useSelector(dashboardUserStatsReducer);
  return (
    <div className="dashboard__card">
      <h6>User Stats</h6>
      <CircularChartCard loginStatus={false} />
      <div className="d-flex align-center justify-center gap6">
        <LoginStatusIndicator
          status={1}
          title="Active Users"
          count={data.active}
          percentage={data.per_active}
        />
        <LoginStatusIndicator
          status={0}
          title="Fairly Active Users"
          count={data.fairy_active}
          percentage={data.per_fairy_active}
        />
        <LoginStatusIndicator
          status={-1}
          title="Inactive Users"
          count={data.inactive}
          percentage={data.per_inactive}
        />
      </div>
    </div>
  );
};

export default UserStatsCard;
