import { useDispatch, useSelector } from "react-redux";
import { selectedMonthReducer } from "../../redux/reducers";
import { updateSelectedMonth } from "../../redux/global-slice";
import { useEffect } from "react";
import { monthsList } from "../../utils/constants";
import { ChevronDown } from "lucide-react";

const SelectMonth = ({ title = 'Month', disabled = false, short = false, dashboard = false, isPrevious = false }) => {
    const dispatch = useDispatch();
    const month = useSelector(selectedMonthReducer);

    useEffect(() => {
        return () => {
            var res = new Date().getMonth();
            if (isPrevious) dispatch(updateSelectedMonth(res));
            else dispatch(updateSelectedMonth(res + 1));
        }
    }, []);

    return <div className={`input__container ${dashboard && 'dashboard__input__container'}`}>
        {title && <label htmlFor='grade'>{title}</label>}
        <select onChange={(e) => dispatch(updateSelectedMonth(e.target.value))} value={month} disabled={disabled} >
            {monthsList.map((m, i) => <option
                key={i}
                value={i + 1}
            >{short ? m.short : m.title}</option>)}
        </select>
        <ChevronDown color='#3F3782' />
    </div>
}

export default SelectMonth;
