const UserIdInput = ({ userId, setUserId }) => {
    return (
        <div className="input__container">
            <label htmlFor="userid">User ID</label>
            <input
                type="text"
                id="userid"
                name="userid"
                placeholder="userid@mail.com"
                value={userId}
                onChange={(e) => setUserId(e.target.value)} />
        </div>
    );
}

export default UserIdInput;