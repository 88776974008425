import { Outlet } from "react-router-dom";
import DashboardSideMenu from "../../../componets/dashboard/dashboard-side-menu";
import { SCHOOL_DASHBOARD_MENU } from "../../../utils/constants";

const SchoolDashboard = () => {

    return <div className='dashboard__content'>
        <DashboardSideMenu menus={SCHOOL_DASHBOARD_MENU} />
        <Outlet />
    </div>;
}

export default SchoolDashboard;