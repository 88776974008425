import { Outlet } from "react-router-dom";
import Tabbar from "../../../../componets/tabbar";
import { SELECTED_SKILLS_TABS } from "../../../../utils/constants";
import TableHeaderCard from "../../../../componets/dashboard/table-header-card";

const SelectedSkills = () => {
    return <div className='dashboard__card'>
        <TableHeaderCard title='Selected Skills' padding='' />
        <Tabbar tabs={SELECTED_SKILLS_TABS} />
        <Outlet />
    </div>
}

export default SelectedSkills;