import { useLocation, useNavigate } from 'react-router-dom';
import SelectGrade from '../input/select-grade';
import SelectSection from '../input/select-section';
import { getDashboardDate } from '../../utils/utils';
import DashboardTooltipContentCard from './dashboard-tooltip-content-card';
import { School, Undo2 } from 'lucide-react';
import useDashboardData from '../../hooks/use-dashboard-data';
import { GET_GRADE_SECTION_DATA_API } from '../../utils/api-constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateSelectedGrade } from '../../redux/global-slice';

const DashboardHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const gradeDashboard = location.pathname.includes('grade');
    const { data, grade, section, userDetail } = useDashboardData(GET_GRADE_SECTION_DATA_API);

    useEffect(() => {
        if (!gradeDashboard && grade.length > 0 && section.length > 0) {
            navigate('/dashboard/grade/overview');
        }
    }, [gradeDashboard, grade, section]);

    return <div className='d-flex justify-between align-center'>
        <div className='d-flex align-center gap8'>
            <div className='d-flex align-center gap3'>
                {userDetail['role_id'] !== '10' && gradeDashboard && <button className='primary square neo' onClick={() => {
                    dispatch(updateSelectedGrade(''));
                    navigate('/dashboard/school/overview');
                }}>
                    <div className='d-flex align-center justify-center gap1 px3'>
                        <Undo2 size={16} />
                        <School size={16} />
                    </div>
                </button>}
                <div className='large__header5__bold'>{gradeDashboard ? 'Grade' : 'School'} Dashboard</div>
            </div>
            <div className='d-flex gap3'>
                <SelectGrade select={userDetail['role_id'] === '10'} title={false} dashboard={true} isMass={false} />
                <SelectSection title={false} dashboard={true} />
            </div>
        </div>
        <div className='d-flex gap8'>
            <div>
                <div className='large__label2__regular' style={{ color: '#696F79' }}>Total Students</div>
                <div className='large__header6__bold' style={{ color: '#444B57' }}>
                    {data ? _getTotalStudent(data, gradeDashboard, grade, section) : '--'}
                </div>
            </div>
            {gradeDashboard && <div style={{ position: 'relative' }}>
                <div className='large__label2__regular d-flex gap2' style={{ color: '#696F79' }}>
                    <span>Average Rank Point</span>
                    <DashboardTooltipContentCard />
                </div>
                <div className='large__header6__bold' style={{ color: '#444B57' }}>{_getRankPoints(data, grade, section)}</div>
            </div>}
            {gradeDashboard && <div>
                <div className='large__label2__regular' style={{ color: '#696F79' }}>Grade Rank</div>
                <div className='large__header6__bold' style={{ color: '#444B57' }}>{_getGradeRanks(data, grade, section)}</div>
            </div>}
            {!gradeDashboard && <div>
                <div className='large__label2__regular' style={{ color: '#696F79' }}>Implementation Mode</div>
                <div className='large__header6__bold' style={{ color: '#444B57' }}>
                    {data['implementation_mode'] === '1' ? 'Home' : data['implementation_mode'] === '2' ? 'School' : 'School + Home'}
                </div>
            </div>}
            <div>
                <div className='large__label2__regular' style={{ color: '#696F79' }}>Date</div>
                <div className='large__header6__bold' style={{ color: '#444B57' }}>{getDashboardDate()}</div>
            </div>
        </div>
    </div>
}

export default DashboardHeader;

// get total students from object
const _getTotalStudent = (data, gradeDashboard, grade, section) => {
    if (typeof data === 'object') {
        if (gradeDashboard) {
            if ('grades_sections' in data) {
                var res = data['grades_sections'].find(e => e['grade'] === grade && e['section'] === section);
                if (res && res['total_students'] !== null) {
                    return String(res['total_students']).padStart(2, '0');
                }
                return '00';
            }
            return '00';
        } else {
            if ('total_students' in data && data['total_students'] !== null) {
                return String(data['total_students']).padStart(2, '0');
            }
            return '00';
        }
    }
    return '--';
}

// get total rank points
const _getRankPoints = (data, grade, section) => {
    if (typeof data === 'object') {
        if ('grades_sections' in data && data['grades_sections'] !== null) {
            var res = data['grades_sections'].find(e => e['grade'] === grade && e['section'] === section);
            if (res && res['average_rank_points'] !== null && res['average_rank_points'] !== 'null' && res['average_rank_points'] !== undefined) {
                return Number(res['average_rank_points']).toFixed(1);
            }
            return '00';
        }
        return '00';
    }
    return '--';
}

// get grade ranks
const _getGradeRanks = (data, grade, section) => {
    if (typeof data === 'object') {
        if ('grades_sections' in data && data['grades_sections'] !== null) {
            var res = data['grades_sections'].find(e => e['grade'] === grade && e['section'] === section);
            if (res && res['grades_sections_ranks'] !== null && res['grades_sections_ranks'] !== 'null' && res['grades_sections_ranks'] !== undefined && res['grades_sections_ranks'].length > 0) {
                return res['grades_sections_ranks'][0]['grade_rank'];
            }
            return '00';
        }
        return '00';
    }
    return '--';
}
