import { useState, useEffect } from "react";
import { getNetworkConnection } from "../utils/utils";

function getNetworkConnectionInfo() {
  const connection = getNetworkConnection();
    if (!connection) {
        return {
            rtt: 0,
        };
    }
    return {
        rtt: connection.rtt,
        type: connection.type,
        saveData: connection.saveData,
        downLink: connection.downLink,
        downLinkMax: connection.downLinkMax,
        effectiveType: connection.effectiveType,
    };
}
function useNetworkHook() {
    // network state
    const [state, setState] = useState(() => {
        return {
            since: undefined,
            online: navigator.onLine,
            ...getNetworkConnectionInfo(),
        };
    });
    
    // registering & listening to events
    useEffect(() => {
        const handleOnline = () => {
            setState((prevState) => ({
                ...prevState,
                online: true,
                since: new Date().toString(),
            }));
        };
        const handleOffline = () => {
            setState((prevState) => ({
                ...prevState,
                online: false,
                since: new Date().toString(),
            }));
        };
        const handleConnectionChange = () => {
            setState((prevState) => ({
                ...prevState,
                ...getNetworkConnectionInfo(),
            }));
        };
        // event listeners
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
        const connection = getNetworkConnection();
        connection?.addEventListener("change", handleConnectionChange);
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
            connection?.removeEventListener("change", handleConnectionChange);
        };
    }, []);
    return state;
}
export default useNetworkHook;