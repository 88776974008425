import DashboardTableList from "../../../componets/dashboard/dashboard-list-table";
import { SKILLS_COMPLATION_HEADER } from "../../../utils/constants";
import useDashboardData from "../../../hooks/use-dashboard-data";
import { SKILL_COMPLETION_API } from "../../../utils/api-constants";
import { useEffect, useState } from "react";

const SkillCompletion = () => {
    const { loading, error, data } = useDashboardData(SKILL_COMPLETION_API);
    const [lists, setLists] = useState([]);

    useEffect(() => {
        if (data !== null && typeof data === 'object' && !Array.isArray(data)) {
            setLists(data['student_skills'])
        }
    }, [data]);

    return <DashboardTableList
        title='Skill Completion'
        headers={SKILLS_COMPLATION_HEADER}
        lists={lists}
        loadin={loading}
        error={error}
    >
        <div className='d-flex align-center gap14'>
            <div className='d-flex align-center gap3'>
                <div className='large__body__regular' style={{ color: '#696F79' }}>Total Skills</div>
                <div className='large__body__bold'>{data && data['total_skills']}</div>
            </div>
            <div className='d-flex align-center gap3'>
                <div className='large__body__regular' style={{ color: '#696F79' }}>Skills Completed</div>
                <div className='large__body__bold' >{data && data['completed_skills']}</div>
            </div>
            <div className='d-flex align-center gap3'>
                <div className='large__body__regular' style={{ color: '#696F79' }}>Skills Yet To Complete</div>
                <div className='large__body__bold'>{data && data['yet_to_complete']}</div>
            </div>
        </div>
    </DashboardTableList>
}
export default SkillCompletion;