// import { FilePenLine } from "lucide-react";
import { useSelector } from "react-redux";
import { skillsReducer, userDetailReducer } from "../../redux/reducers";
import ResetPassword from '../../componets/reset-password';
import { useEffect, useState } from "react";
import { groupBy, removeDuplicatesGrade } from '../../utils/utils';

const Profile = () => {
    const detail = useSelector(userDetailReducer);
    const skills = useSelector(skillsReducer);
    const [dialog, setDialog] = useState(false);
    const [lists, setLists] = useState([]);

    useEffect(() => {
        var updatedList = [];
        const res = groupBy(skills, 'name');
        const entries = Object.entries(res);
        entries.forEach(val => {
            var child = [];
            for (let i = 0; i < val[1].length; i++) {
                for (let c = 0; c < val[1][i]['classes'].length; c++) {
                    child.push(val[1][i]['classes'][c])
                }
            }
            updatedList.push({ name: val[0], skills: removeDuplicatesGrade(child) })
        })
        setLists(updatedList);
    }, [skills]);

    return <div className="profile__screen d-flex justify-center">
        <div className="profile d-flex flex-col gap8">
            <div className="bg-surface-element p2 radius4 elevation4 animate">
                <div className="profile__card__content d-flex flex-col align-center justify-center gap4">
                    <div className="d-flex flex-col align-center justify-center" style={{ textTransform: 'capitalize' }}>
                        <div className="large__header3__bold" style={{ color: '#3E444F' }}>{detail.fullname}</div>
                        <div className="large__header6__bold" style={{ color: '#695BD7' }}>{detail.school_name}</div>
                    </div>
                    <div className="d-flex flex-col">
                        <div className="d-flex gap2 large__body__bold" style={{ color: '#696F79' }}>
                            <span>{detail.email}</span>
                            <div className="d-flex justify-center align-center" style={{ width: '26px', height: '26px' }}>
                                <div style={{ height: '5px', width: '5px', borderRadius: '50%', background: '#696F79' }} />
                            </div>
                            <span>{detail.phone}</span>
                        </div>
                        {/* <button className="link small" style={{ background: 'transparent' }} onClick={() => setDialog(true)}>
                            <FilePenLine size={16} />
                            <span>Change Password</span>
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="bg-surface-element radius4 p4 elevation4 d-flex flex-col gap4 animate">
                <div className="large__header5__bold">Assigned Grades</div>
                <div className="d-flex flex-col gap5">
                    {
                        lists.map((skill, i) => <div key={i} className="d-flex flex-col gap3 animate">
                            <div className="large__label2__bold" style={{ color: '#594BDD' }}>{skill['name']}</div>
                            <div className="d-flex gap3" style={{ flexWrap: 'wrap' }}>
                                {
                                    skill['skills'].map((x, j) => <div
                                        key={j}
                                        className="radius1 px2 py1 large__label2__regular grade__section__chip animate"
                                    >
                                        Grade {x['grade']} {x['section']}
                                    </div>)
                                }
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
        {/* reset password dialog */}
        {dialog && <ResetPassword closeDialog={() => setDialog(false)} />}
    </div>
}
export default Profile;
