import IlmList from '../componets/ilms-list';
import Tabbar from "../componets/tabbar";
import HistoryFilter from '../componets/history-filter';
import { HISTORY_FAVOURITE as tabs } from '../utils/constants';
import { useEffect, useState } from "react";
import { getWatchHistoryILMs } from "../service/favourite-service";
import { useDispatch } from "react-redux";
import { updateLoading } from "../redux/global-slice";

const WatchHistory = () => {
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateLoading(true));
        getWatchHistoryILMs('')
            .then(res => setData(res))
            .finally(() => dispatch(updateLoading(false)));
    }, []);

    return <div className="d-flex flex-col gap8" style={{ width: '100%' }}>
        <Tabbar tabs={tabs} i={1} />
        <HistoryFilter />
        <IlmList lists={data} />
    </div>
}

export default WatchHistory;