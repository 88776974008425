import { useEffect, useState } from "react";
import { canJoinEvent, isEventEnded, isEventStarted } from "../../utils/utils";
import { enrollEventService, joinEventService } from "../../service/event-service";
import { useNavigate } from "react-router-dom";
import { EVENTS_PATH } from "../../utils/route-constants";

const EventJoinButton = ({ type, event, eventId }) => {
    const navigate = useNavigate();
    const [updatedEvent, setUpdatedEvent] = useState(event);
    const [started, setStarted] = useState(false);
    const [ended, setEnded] = useState(false);
    const [join, setJoin] = useState(false);

    const init = () => {
        const x = isEventStarted(updatedEvent['scheduled_date']);
        setStarted(x);
        const y = isEventEnded(updatedEvent['scheduled_date'], updatedEvent['duration']);
        setEnded(y);
        const z = canJoinEvent(updatedEvent['scheduled_date']);
        if (Number(event['is_enrolled']) === 1 && z) {
            setJoin(true);
        } else {
            setJoin(false);
        }
    }

    useEffect(() => { init() }, [event]);

    useEffect(() => {
        const timer = window.setInterval(() => { init() }, 1000);
        return () => {
            window.clearInterval(timer);
        }
    }, [updatedEvent, event]);

    return <>
        {
            (type === 4 || ended) ?
                <button style={{ width: '100%' }} className="primary medium" onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/${EVENTS_PATH}/${eventId}/view`, {
                        state: type
                    });
                }}>View Recording</button> :
                (Number(updatedEvent['is_enrolled']) === 1) ?
                    <button style={{ width: '100%' }} className="primary medium" onClick={(e) => {
                        try {
                            e.stopPropagation()
                            joinEventService(event.id)
                                .then(res => {
                                    if (String(res) === String(200)) {
                                        var updated = {
                                            ...updatedEvent,
                                            'is_joined': '1',
                                        }
                                        setUpdatedEvent(updated);
                                        navigate(`/${EVENTS_PATH}/${eventId}/view`, {
                                            state: type
                                        });
                                    } else {
                                        alert(res.message ?? 'Something went wrong while joining the event!');
                                    }
                                });
                        } catch (error) {
                            console.error("Error:", error);
                            alert("Error! Please try again");
                        }
                    }} disabled={!join}> Join Live Session</button > :
                    <button style={{ width: '100%' }} className="primary medium" onClick={(e) => {
                        e.stopPropagation()
                        enrollEventService(event['id']).then(res => {
                            var x = {
                                ...updatedEvent,
                                is_enrolled: '1',
                            }
                            setUpdatedEvent(x);
                            alert('Event enrolled successfully!');
                        }).catch(e => {
                            console.error(e);
                            alert('Something went wrong while enrolling the event!');
                        })
                    }} disabled={started}>Enroll</button>}
    </>
}

export default EventJoinButton;