import { ChevronDown } from "lucide-react";

const SelectLevel = ({ options, value, setValue }) => {
    return (
        <div className="input__container">
            <label htmlFor="level">Level</label>
            <select onChange={(e) => setValue(e.target.value)} id="level" value={value}>
                <option value='' disabled>Select Level</option>
                {options.map((option, index) =>
                    <option key={index} value={option}> Level {option} </option>
                )}
            </select>
            <ChevronDown color='#3F3782' />
        </div>
    );
}

export default SelectLevel;