const CountryInput = ({ value, setValue, disabled = false }) => {
    return <div className="input__container">
        <label htmlFor="country">Country</label>
        <input
            type="text"
            id="country"
            name="country"
            placeholder="Enter country"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
        />
    </div>
}

export default CountryInput;