import { CalendarDays, Clock, Hourglass } from "lucide-react";
import EventJoinButton from "./event-join-button";
import { getFormattedDate, getFormattedTime, isEventEnded } from "../../utils/utils";
import { useEffect, useState } from "react";

const EventInfoCard = ({ eventId, event, type, fromDetail = true }) => {
    const [ended, setEnded] = useState(false);

    const init = () => {
        const res = isEventEnded(event['scheduled_date'], event['duration']);
        setEnded(res);
    }

    useEffect(() => { init() }, [event]);

    useEffect(() => {
        const timer = window.setTimeout(() => { init() }, 1000);
        return () => {
            return window.clearInterval(timer);
        }
    }, [event]);
    return <div className=" fl5 radius3 p4 elevation5 d-flex flex-col gap4 bg-surface-element">
        <div className="depth4 p2" style={{ borderRadius: '10px', aspectRatio: '16 / 9' }}>
            <img src={event['thumbnail']} alt={event['name']} style={{ borderRadius: '8px' }} />
        </div>
        {!fromDetail && <div className="large__header5__bold" style={{ color: '#4F4E4E' }}>Event Name : {event['name']} </div>}
        <div
            className="bg-surface-element py4 px2 depth6 d-flex flex-col gap3"
            style={{
                borderRadius: '10px',
                background: fromDetail ? 'transparent' : 'linear-gradient(118.69deg, rgba(244, 167, 29, 0.5) 0%, rgba(254, 211, 133, 0.5) 0.01%, rgba(255, 255, 255, 0.5) 52%, rgba(192, 184, 250, 0.5) 100%)'
            }}>
            <div className="d-flex px3 py2">
                <div className="d-flex gap3 fl2">
                    <CalendarDays size={24} color='#444B57' />
                    <div className="large__body__bold" style={{ color: '#444B57' }}>Start Date</div>
                </div>
                <div className="large__body__bold fl3" style={{ color: '#7364EC' }}>{getFormattedDate(event['scheduled_date'])}</div>
            </div>
            <div className="d-flex px3 py2">
                <div className="d-flex gap3 fl2">
                    <Hourglass size={24} color='#444B57' />
                    <div className="large__body__bold" style={{ color: '#444B57' }}>Event Duration</div>
                </div>
                <div className="large__body__bold fl3" style={{ color: '#7364EC' }}>~ {event['duration']} Hours</div>
            </div>
            <div className="d-flex px3 py2">
                <div className="d-flex gap3 fl2">
                    <Clock size={24} color='#444B57' />
                    <div className="large__body__bold" style={{ color: '#444B57' }}>Session Time</div>
                </div>
                <div className="large__body__bold fl3" style={{ color: '#7364EC' }}>{getFormattedTime(event['scheduled_date'])}</div>
            </div>
        </div>
        {fromDetail && <div className="d-flex gap4">
            <div
                className="radius2 p2 fl2 d-flex align-center justify-center large__body__bold"
                style={{
                    border: ended ? 'none' : '2px solid #5DDA5D',
                    color: ended ? '#696F79' : '#5DDA5D'
                }}
            >
                {
                    ended ?
                        event['is_enrolled'] === '1' ?
                            'Event Attended' : 'Event Not Attended' :
                        event['is_enrolled'] === '1' ?
                            'Enrolled' : 'Not Enrolled'
                }
            </div>
            {/* TODO: change type */}
            <div className="fl3">
                <EventJoinButton type={type} event={event} eventId={eventId} />
            </div>
        </div>}
    </div>
}

export default EventInfoCard;