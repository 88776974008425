import { MoveRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import HomeLoginStatus from "./home-login-status";
import HomeUserStats from "./home-user-stats";
import useDashboardData from "../../hooks/use-dashboard-data";
import { TOP_PERFOMERS_API } from "../../utils/api-constants";
import LoadingError from "../dashboard/loading-error";
import { getParsedGrade } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { userClassesReducer } from "../../redux/reducers";
import {
  updateSelectedGrade,
  updateSelectedSection,
} from "../../redux/global-slice";
import IndividualTopPerfomanceCard from "../dashboard/individual-top-performance-card";
import {
  rearrangeFirstThreeRank,
  removeDuplicatesGrade,
} from "../../utils/utils";
import { useEffect } from "react";

const HomeDashbaord = ({ teacher }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useSelector(userClassesReducer);
  const { loading, error, data, grade, section } = useDashboardData(
    TOP_PERFOMERS_API,
    { type: "1" }
  );

  useEffect(() => {
    if (teacher) {
      const unique = removeDuplicatesGrade(classes);
      if (unique.length > 0) {
        const x = unique[unique.length - 1];
        dispatch(updateSelectedGrade(x["grade"]));
        dispatch(updateSelectedSection(x["section"]));
      }
    }

    // reset grade & section while leaving
    return () => {
      if (teacher) {
        dispatch(updateSelectedGrade(""));
      }
    };
  }, [classes]);

  return (
    <div className="d-flex flex-col gap6">
      <div className="d-flex flex-col gap1">
        <div className="large__header3__bold" style={{ color: "#3A2CB8" }}>
          {teacher ? "Grade" : "School"} Dashboard
        </div>
        <div className="large__header5__regular" style={{ color: "#4839CD" }}>
          Find all your achievements here.
        </div>
      </div>
      {teacher && (
        <div
          className="d-flex align-center gap6"
          style={{ flexWrap: "nowrap", overflowY: "scroll" }}
        >
          {classes.map((x, i) => (
            <div
              key={i}
              className={`bg-surface-element px6 py1 radius2 elevation2 large__header5__bold d-flex align-center justify-center my1`}
              style={{
                color:
                  grade === x["grade"] && section === x["section"]
                    ? "#FFFFFF"
                    : "#82868E",
                cursor: "pointer",
                border: "border: 1.2px solid #F7F7F8",
                background:
                  grade === x["grade"] && section === x["section"]
                    ? "var(--secondaryButtonBorder)"
                    : "var(--surfaceELementWhite)",
              }}
              onClick={() => {
                dispatch(updateSelectedGrade(x["grade"]));
                dispatch(updateSelectedSection(x["section"]));
              }}
            >
              Grade {getParsedGrade(x["grade"])} {x["section"]}
            </div>
          ))}
        </div>
      )}
      <div className="bg-surface-element elevation4 radius6 pt4 px4 pb2 d-flex flex-col gap3">
        <div style={{ minHeight: "200px" }} className="d-flex gap4">
          <HomeLoginStatus />
          <HomeUserStats />
          <div className="d-flex flex-col gap2" style={{ width: "32%" }}>
            {/* <div className='d-flex align-center justify-between gap2'>
                        <div className='d-flex gap4'>
                            <div className='d-flex flex-col'>
                                <span className='large__label2__regular'>Projects Submitted</span>
                                <span className='large__label2__bold'>19</span>
                            </div>
                            <div className='d-flex flex-col'>
                                <span className='large__label2__regular'>Pending Project Approvals</span>
                                <span className='large__label2__bold'>19</span>
                            </div>
                        </div>
                        <button className='primary neo small'>
                            <span>Review</span>
                            <MoveRight color='#FFFFFF' />
                        </button>
                    </div> */}
            <div className="d-flex flex-col gap4 depth4 bg-surface-element p3 radius3 fl1">
              <div className="large__header6__bold">Top Perfomers</div>
              <div className="top__perfomers__content">
                {loading || error ? (
                  <LoadingError error={error} loading={loading} />
                ) : data.length > 0 ? (
                  rearrangeFirstThreeRank(data).map((user) => (
                    <IndividualTopPerfomanceCard
                      key={user["user_id"]}
                      large={user["rank_no"] === "1" || user["rank_no"] === 1}
                      rank={user["rank_no"]}
                      name={user["fullname"]}
                      grade={`Grade ${getParsedGrade(user["grade"] ?? "1")} ${
                        user["section"]
                      }`}
                      points={user["points"]}
                    />
                  ))
                ) : (
                  [2, 1, 3].map((e) => (
                    <IndividualTopPerfomanceCard
                      large={e === 1}
                      key={e}
                      rank={e}
                      isEmpty={true}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "40px" }} className="d-flex justify-end">
          <button
            className="link small"
            style={{ background: "transparent" }}
            onClick={() =>
              navigate(`/dashboard/${teacher ? "grade" : "school"}/overview`)
            }
          >
            <span style={{ fontSize: "15px", fontWeight: 600 }}>
              View Dashboard
            </span>
            <MoveRight size={20} color="#F3A61D" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeDashbaord;
