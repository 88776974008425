import { createSlice } from '@reduxjs/toolkit'

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        loading: false,
        search: '',
        accessToken: localStorage.getItem('accessToken'),
        carousels: [],
        selectedGrade: '',
        selectedSection: '',
        filteres: [],
        reloadProjects: false,
        startDate: new Date(),
        endDate: new Date(),
        selectedMonth: new Date().getMonth() + 1,
    },
    reducers: {
        updateLoading: (state, action) => {
            state.loading = action.payload
        },
        updateSearch: (state, action) => {
            state.search = action.payload
        },
        updateAccessToken: (state, action) => {
            localStorage.setItem('accessToken', action.payload._data);
            state.accessToken = action.payload._data;
        },
        updateCarouselList: (state, action) => {
            state.carousels = action.payload;
        },
        updateSelectedGrade: (state, action) => {
            state.selectedGrade = action.payload;
        },
        updateSelectedSection: (state, action) => {
            state.selectedSection = action.payload;
        },
        updateFilters: (state, action) => {
            const id = action.payload;
            var items = [...state.filteres];
            if (items.includes(id)) {
                items = items.filter(e => e !== id);
            } else {
                items.push(id)
            }
            state.filteres = items;
        },
        resetFilters: (state) => {
            state.filteres = [];
        },
        updateRelodProjects: (state, action) => {
            state.reloadProjects = action.payload;
        },
        updateRangeDates: (state, action) => {
            const { startDate, endDate } = action.payload
            state.startDate = startDate;
            state.endDate = endDate;
        },
        updateSelectedMonth: (state, action) => {
            state.selectedMonth = action.payload;
        }
    },
});

export const {
    updateLoading,
    updateSearch,
    updateAccessToken,
    updateCarouselList,
    updateSelectedGrade,
    updateSelectedSection,
    updateFilters,
    resetFilters,
    updateRelodProjects,
    updateRangeDates,
    updateSelectedMonth,
} = globalSlice.actions

export default globalSlice.reducer