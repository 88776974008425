import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress = ({ fontSize = '27px', size = '100px', text = 1, progress = 50, disabled = false, }) => {
    return <div style={{ height: size, width: size }}>
        <CircularProgressbar
            strokeWidth={12}
            value={progress}
            text={text}
            counterClockwise={true}
            styles={{
                path: {
                    stroke: disabled ? '#DDDDDD' : progress === '100' ? '#5DDA5D' : '#453B8F',
                    height: '100%'
                },
                trail: {
                    stroke: disabled ? '#DDDDDD' : '#B7A4FA'
                },
                text: {
                    fontSize: fontSize,
                    fontFamily: 'Madimi One',
                    fill: disabled ? '#6B6B6B' : '#453B8F',
                }
            }}
        />
    </div>
}

export default CircularProgress;