import { Suspense, useEffect } from "react";
import { useLoaderData, Await, Outlet, useNavigate } from "react-router-dom";
import Loader from '../loader';
import { AuthProvider } from "../../hooks/use-auth";
import { useDispatch, useSelector } from "react-redux";
import { updateAccessToken } from "../../redux/global-slice";
import { globalLoadingReducer } from "../../redux/reducers";

const AuthLayout = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { token } = useLoaderData();
    const loading = useSelector(globalLoadingReducer)

    useEffect(() => {
        dispatch(updateAccessToken(token));
    }, [token]);

    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={token}
                errorElement={<div severity="error">Something went wrong!</div>}
                children={(user) => (
                    <AuthProvider userData={user}>
                        {/* <div>{online ? <Wifi color='green' /> : <WifiOff color='grey' />}</div>
                        <div>Since:- {since}</div>
                        <div>downLink:- {downLink}</div>
                        <div>downLinkMax:- {downLinkMax}</div>
                        <div>effectiveType:- {effectiveType}</div>
                        <div>rtt:- {rtt}</div>
                        <div>saveData:- {saveData}</div>
                        <div>type:- {type}</div> */}
                        <Outlet />
                    </AuthProvider>
                )}
            />
            {(navigation.state === 'loading' || loading) ? <Loader /> : null}
        </Suspense>
    );
};

export default AuthLayout;