import { Outlet, useLocation } from 'react-router-dom';
import Tabbar from '../../componets/tabbar';
import { classroomTabsReducer } from '../../redux/reducers';
import { useSelector } from 'react-redux';

const Classroom = () => {
    const location = useLocation();
    const tabs = useSelector(classroomTabsReducer);
    return <div className='d-flex flex-col gap8' style={{ width: '100%' }}>
        {!location.state && <Tabbar tabs={tabs} />}
        <Outlet />
    </div>
}

export default Classroom;