const LoginStatusChip = ({ status }) => {
  return (
    <div
      className="large__label2__bold"
      style={{
        padding: "6px 5px 6px 5px",
        borderRadius: "4px",
        background: status === 0 ? "#FBE3B9" : "#E2EFE2",
        color: status === 0 ? "#F3A61D" : "#059D05",
        textAlign: "center",
      }}
    >
      {status === 0 ? "Pending Login" : "Logged In"}
    </div>
  );
};

export default LoginStatusChip;
