import TicketStatus from './ticket-status';
const SupportTicketList = ({ tickets = [], selected, setSelected }) => {
    return (
        <div className="support__ticket__list d-flex flex-col gap3 p4">
            {
                tickets.length > 0 ?
                    tickets.map(ticket => <div key={ticket.id} className={'ticket__card radius3 p1 ' + (ticket.id === selected?.id ? 'elevation3' : '')} onClick={() => setSelected(ticket)}>
                        <div className={"p3 radius3 d-flex flex-col gap2 " + (ticket.id === selected?.id ? 'ticket__card__content__bg depth3' : '')}>
                            <div className='d-flex justify-between'>
                                <div className="large__header6__bold">Support ID</div>
                                <div className="large__header6__bold" style={{ color: 'var(--pRose500)' }}>{ticket.ticket}</div>
                            </div>
                            <div className='d-flex justify-between'>
                                <div className='d-flex justify-center align-center gap3'>
                                    <div className="large__label1__regular">Status</div>
                                    <TicketStatus status={ticket.status} />
                                </div>
                                <div className='d-flex justify-center align-center gap3'>
                                    <div className="large__label1__regular">Created Date</div>
                                    <div className="small__caption__caption" style={{ color: 'var(--neutral600)' }}>{ticket.issue_raised_date}</div>
                                </div>
                            </div>
                        </div>
                    </div>) :
                    <div className='d-flex flex-col justify-center align-center gap3'>
                        <div className="large__header6__bold">No Support Ticket Found</div>
                        <div className="large__body__regular">You may raise a concern below</div>
                    </div>
            }
        </div>
    );
}

export default SupportTicketList;