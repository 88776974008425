import SkillprogramsList from '../componets/skill-programs-list';
import SelectSkill from '../componets/input/select-skill';
import SelectLevel from '../componets/input/select-level';
import { useEffect, useState } from 'react';
import { fetchSkillProgramILMs } from '../service/ilm-service';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoading, updateSelectedGrade } from '../redux/global-slice';
import { schoolSkillsReducer } from '../redux/reducers';

// theme & level assigned to school will be in select
const SkillProgramIlms = () => {
    const [theme, setTheme] = useState('');
    const [level, setLevel] = useState('');
    const [options, setOptions] = useState([]);
    const [lists, setLists] = useState([]);
    const dispatch = useDispatch();
    const schoolSkills = useSelector(schoolSkillsReducer);

    const getList = () => {
        dispatch(updateLoading(true))
        fetchSkillProgramILMs(theme, level)
            .then(res => setLists(res))
            .finally(() => dispatch(updateLoading(false)));
    }

    // get levels list & set
    useEffect(() => {
        dispatch(updateSelectedGrade(''));
        if (theme.length > 0) {
            const filtered = schoolSkills.find(e => e['program_id'] === String(theme));
            const levels = filtered['levels'] !== null ? filtered['levels'].split(',') : [];
            const updated = [...new Set(levels)]
            setOptions(updated);
        } else {
            setOptions([]);
        }
        setLevel('');
        setLists([]);
    }, [theme, schoolSkills]);

    return <div className='d-flex flex-col gap8' style={{ width: '100%' }}>
        <div>
            <div className='large__header3__bold' style={{ paddingBottom: '4px' }}>Skill Programs</div>
            <div className='large__header5__regular'>Access all your ILMs by selecting skill.</div>
        </div>
        <div className='d-flex align-end gap6'>
            <div style={{ width: '288px' }}>
                <SelectSkill value={theme} setValue={setTheme} />
            </div>
            <div style={{ width: '288px' }}>
                <SelectLevel options={options} value={level} setValue={setLevel} />
            </div>
            <button className='primary neo' style={{ width: '260px' }} onClick={() => getList()} disabled={theme.length < 1 || level.length < 1}>View ILMs</button>
        </div>
        <SkillprogramsList lists={lists} getList={getList} />
    </div>

}

export default SkillProgramIlms;
