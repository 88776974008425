import CircularChartCard from "../charts/circular-chart-card";
import LoginStatusIndicator from "../dashboard/login-status-indicator";
import { useSelector } from "react-redux";
import { dashboardLoginStatusReducer } from "../../redux/reducers";
const HomeLoginStatus = () => {
  const data = useSelector(dashboardLoginStatusReducer);
  return (
    <div
      className="d-flex flex-col gap4 depth4 bg-surface-element p3 radius3"
      style={{ width: "28%" }}
    >
      <div className="large__header6__bold">Login Status</div>
      <div className="fl1 mt8 mb4">
        <CircularChartCard height={120} />
      </div>
      <div className="d-flex align-center justify-center gap6">
        <LoginStatusIndicator
          status={1}
          title="Logged In"
          count={data.logged_in}
          percentage={data.per_logged_in}
        />
        <LoginStatusIndicator
          status={0}
          title="Pending Log In"
          count={data.pending_logged_in}
          percentage={data.per_pending_logged_in}
        />
      </div>
    </div>
  );
};

export default HomeLoginStatus;
