import {
  FETCH_CAROUSEL_API,
  FETCH_CLASSROOM_DETAIL_API,
  FETCH_DAILYQUEST_API,
  FETCH_SKILL_PROGRAMS_API,
  FETCH_TECHER_JOURNEY_API,
  FTECH_SKILLS_BY_TEACHER_API,
  UPDATE_SCORM_PROGRESS_API,
  UPDATE_WATCH_HISTORY_API,
  apiRequestOptions,
} from "../utils/api-constants";

// get conduct session skills list
export async function getConductSessionILMs(grade, section) {
  const userId = window.localStorage.getItem("userId");
  try {
    const options = apiRequestOptions({
      user_id: userId,
      grade: grade,
      section: section,
    });
    return await fetch(FTECH_SKILLS_BY_TEACHER_API, options)
      .then((res) => res.json())
      .then((json) => json["details"])
      .catch((e) => {
        throw new Error(e);
      });
  } catch (e) {
    console.error(e);
  }
}

// journey ilms
export async function getJourneyILMS(skillId, section, grade) {
  const userId = window.localStorage.getItem("userId");
  try {
    const options = apiRequestOptions({
      user_id: userId,
      skill_id: skillId,
      section: section,
      grade: grade,
    });
    return await fetch(FETCH_TECHER_JOURNEY_API, options)
      .then((res) => res.json())
      .then((json) => json["details"])
      .catch((e) => {
        throw new Error(e);
      });
  } catch (e) {
    console.error(e);
  }
}

// fetch skill programs
export async function fetchSkillProgramILMs(skillId, level) {
  const userId = window.localStorage.getItem("userId");
  try {
    const options = apiRequestOptions({
      user_id: userId,
      skill_id: skillId,
      level: level,
    });
    // TODO: theme_id, skill_id, is_favourite needed
    return await fetch(FETCH_SKILL_PROGRAMS_API, options)
      .then((res) => res.json())
      .then((json) => json["details"])
      .catch((e) => {
        throw new Error(e);
      });
  } catch (e) {
    console.error(e);
  }
}

// get classroom detail
export async function getClassroommDetails(ilmId, grade, section) {
  try {
    const userId = window.localStorage.getItem("userId");
    const options = apiRequestOptions({
      user_id: userId,
      ilm_id: ilmId,
      grade: grade,
      section: section,
    });
    return await fetch(FETCH_CLASSROOM_DETAIL_API, options)
      .then((res) => res.json())
      .then((json) => json["details"])
      .catch((e) => {
        throw new Error(e);
      });
  } catch (e) {
    console.error(e);
  }
}

// get classroom detail
export async function getClassroomDailyQuest(ilmId) {
  try {
    const options = apiRequestOptions({ ilm_id: ilmId });
    return await fetch(FETCH_DAILYQUEST_API, options)
      .then((res) => res.json())
      .then((json) => json["details"])
      .catch((e) => {
        throw new Error(e);
      });
  } catch (e) {
    console.error(e);
  }
}

// update lesson progress
export async function updateLessonProgress(
  skillType,
  themeId,
  skillId,
  ilmId,
  duration,
  classId,
  classType,
  lessonType,
  isDone,
  themeCategory,
  grade,
  section,
  retryCount = 0
) {
  try {
    const userId = window.localStorage.getItem("userId");
    const options = apiRequestOptions({
      skill_type: skillType,
      user_id: userId,
      theme_id: themeId,
      skill_id: skillId,
      ilm_id: ilmId,
      total_duration: duration,
      class_id: classId,
      class_type: classType,
      lesson_type: lessonType,
      is_done: isDone,
      theme_category: themeCategory,
      grade: grade,
      section: section,
    });
    const response = await fetch(UPDATE_WATCH_HISTORY_API, options);
    if (!response.ok) {
      if (response.status === 500 && retryCount < 5) {
        const delay = Math.pow(2, retryCount) * 250; // Exponential backoff
        console.log(`Retrying in ${delay} milliseconds`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return await updateLessonProgress(
          skillType,
          themeId,
          skillId,
          ilmId,
          duration,
          classId,
          classType,
          lessonType,
          isDone,
          themeCategory,
          grade,
          section,
          retryCount + 1
        );
      }
      throw new Error("Network response was not ok");
    }

    const json = await response.json();
    return json["details"];
  } catch (e) {
    console.error(e);
  }
}

// update scorm progress
export async function updateScormProgress(
  classType,
  skillType,
  programId,
  themeId,
  ilmId,
  classId,
  grade,
  section,
  scormData,
  retryCount = 0
) {
  try {
    const userId = window.localStorage.getItem("userId");
    const options = apiRequestOptions({
      user_details: {
        class_type: classType,
        type: skillType,
        user_id: userId,
        program_id: programId,
        theme_id: themeId,
        ilm_id: ilmId,
        class_id: classId,
        grade: grade,
        section: section,
      },
      details: scormData,
    });
    const response = await fetch(UPDATE_SCORM_PROGRESS_API, options);
    if (!response.ok) {
      if (response.status === 500 && retryCount < 5) {
        const delay = Math.pow(2, retryCount) * 250; // Exponential backoff
        console.log(`Retrying in ${delay} milliseconds`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return await updateScormProgress(
          classType,
          skillType,
          programId,
          themeId,
          ilmId,
          classId,
          grade,
          section,
          scormData,
          retryCount + 1
        );
      }
      throw new Error("Network response was not ok");
    }

    const json = await response.json();
    return json["details"];
  } catch (e) {
    console.error(e);
  }
}

// get carousel list
export async function getCarouselListService() {
  try {
    const userId = window.localStorage.getItem("userId");
    const options = apiRequestOptions({ user_id: userId });
    return await fetch(FETCH_CAROUSEL_API, options)
      .then((res) => res.json())
      .then((json) => json["details"])
      .catch((e) => {
        throw new Error(e);
      });
  } catch (e) {
    console.error(e);
  }
}
