import { SquarePlay } from 'lucide-react';
import { useState } from 'react';
import Dialog from '../dialog';
import VideoPlayerCard from '../players/video-player-card';
const TutorialVideosList = ({ lists }) => {
    const [preview, setPreview] = useState(false);
    const [selected, setSelected] = useState(null);
    return <>
        <div className='d-flex align-center gap6 p2' style={{ flexWrap: 'nowrap', overflowX: 'scroll' }}>
            {
                lists.map(e => <div
                    key={e['lesson_id']}
                    className='radius3 p2 elevation3 bg-surface-element animate'
                    style={{ minWidth: '325px', maxWidth: '325px', cursor: 'pointer', aspectRatio: '16 / 9' }}
                    onClick={() => {
                        setSelected(e);
                        setPreview(true);
                    }}
                >
                    {/* <img className='radius2' src='https://cdn.pixabay.com/photo/2016/05/05/02/37/sunset-1373171_1280.jpg' /> */}
                    <div
                        className='radius2 d-flex flex-col align-center justify-center depth4 gap1 p2'
                        style={{
                            height: '100%', width: '100%',
                            background: 'linear-gradient(118.69deg, rgba(251, 213, 144, 0.5) 0%, rgba(255, 255, 255, 0.5) 52%, rgba(217, 211, 254, 0.5) 100%)',
                        }}
                    >
                        <SquarePlay color='#3A2CB8' />
                        <div className='large__body__bold' style={{ color: '#3A2CB8', textAlign: 'center' }}>{e['lesson_title']}</div>
                    </div>
                </div>)
            }
        </div>
        {preview && selected && <Dialog
            title={selected['lesson_title']}
            dialog={preview}
            setDialog={() => {
                setPreview(false);
                setSelected(null);
            }}
        >
            <div className='fl1' style={{ maxHeight: '75vh' }}>
                <VideoPlayerCard src={selected['lesson_src']} title={selected['lesson_title']} />
            </div>
        </Dialog>}
    </>
}

export default TutorialVideosList;