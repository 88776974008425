export const AUTH_PATH = '/auth';
export const LOGIN_PATH = 'login';
export const FORGOT_PASSWORD_PATH = 'forgot-password';
export const SET_NEW_PASSWORD_PATH = 'set-new-password';
export const ROOT_PATH = '/';
export const SELECT_MODE_PATH = 'select-mode';
export const EVENTS_PATH = 'events';
export const UPCOMING_EVENTS_PATH = 'upcoming';
export const ENROLLED_EVENTS_PATH = 'enrolled';
export const RECORDING_EVENTS_PATH = 'recording';
export const DASHBOARD_PATH = 'dashboard';
export const GRADE_PATH = 'grade';
export const SCHOOL_PATH = 'school';
export const OVERVIEW_PATH = 'overview';
export const LOGIN_DETAIL_PATH = 'login-details';
export const STUDENTS_REPORT_PATH = 'students-report';
export const SKILL_WISE_REPORT_PATH = 'skill-wise';
export const STUDENT_WISE_REPORT_PATH = 'details';
export const USER_TYPE_SUMMARY_PATH = 'user-type-summary';
export const SKILLS_COMPLETION_PATH = 'skills-completion';
export const POPULAR_ILMS_PATH = 'popular-ilms';
export const PERFORMANCE_TREND_PATH = 'perfomance-trend';
export const USER_TREND_PATH = 'user-trend';
export const TOP_PERFOMERS_PATH = 'top-perfomers';
export const USAGE_SUMMARY_PATH = 'usage-summary';
export const USER_STATS_PATH = 'user-stats';
export const SELECTED_SKILLS_PATH = 'selected-skills';
export const SCHOOL_OPTED_SKILLS_PATH = 'school-opted';
export const STUDENT_ENROLLED_SKILLS_PATH = 'student-enrolled';
export const PROJECTS_PATH = 'projects';
export const PENDING_PROJECTS_PATH = 'pending';
export const APPROVED_PROJECTS_PATH = 'approved';
export const REJECTED_PROJECTS_PATH = 'rejected';
export const CERTIFICATION_PROGRAM_PATH = 'certification-program';
export const SKILL_PROGRAM_ILMS_PATH = 'skill-programs-ilms';
export const CONDUCT_SESSION_PATH = 'conduct-session';
export const CLASSROOM_PATH = 'classroom';
export const CLASSES_PATH = 'classes';
export const DAILYQUEST_PATH = 'daily-quest';

// profile
export const PROFILE_PATH = 'profile';
export const EDIT_PROFILE_PATH = 'profile/edit';
export const WATCH_HISTORY_PATH = 'watch-history';
export const FAVOURITES_PATH = 'favourites';
export const SUPPORT_VIDEOS_PATH = 'support-videos';
export const SUPPORT_PATH = 'support';
export const RAISE_TICKET_PATH = 'raise-ticket';