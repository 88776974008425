import { useState, useEffect } from 'react';
import { apiRequestOptions } from '../utils/api-constants';
import { useSelector } from 'react-redux';
import {
    endDateReducer,
    selectedGradeReducer,
    selectedMonthReducer,
    selectedSectionReducer,
    startDateReducer,
    userDetailReducer,
    userIdReducer,
    userSchoolCodeReducer
} from '../redux/reducers';
import { getPostFormattedDate } from '../utils/utils';

export default function useDashboardData(url, body = {}) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const userId = useSelector(userIdReducer);
    const schoolCode = useSelector(userSchoolCodeReducer);
    const userDetail = useSelector(userDetailReducer);
    const grade = useSelector(selectedGradeReducer);
    const section = useSelector(selectedSectionReducer);
    const month = useSelector(selectedMonthReducer);
    const startDate = useSelector(startDateReducer);
    const endDate = useSelector(endDateReducer);

    // if (location.pathname.includes('grade') && grade && grade.length > 0 && section && section.length > 0) {
    //     fetchData();
    // } else {
    //     fetchData();
    // }

    useEffect(() => {
        const fetchData = () => {
            if (url) {
                const date1 = startDate !== null ? getPostFormattedDate(new Date(startDate)) : null;
                const date2 = endDate !== null ? getPostFormattedDate(new Date(endDate)) : null;
                const options = apiRequestOptions({
                    ...body,
                    user_id: userId,
                    school_code: schoolCode,
                    grade: grade,
                    section: section,
                    month: month,
                    from_date: date1,
                    to_date: date2,
                });
                setLoading(true);
                setError(null);
                fetch(url, options)
                    .then(res => res.json())
                    .then(res => setData(res['details'] ?? []))
                    .catch(() => setError('Something went wrong!'))
                    .finally(() => setLoading(false));
            }
        }

        fetchData();
    }, [url, userId, schoolCode, grade, section, month, month, startDate, endDate]);

    return { loading, error, data, userId, schoolCode, userDetail, grade, section };
}