const SkillOptionChip = ({ status }) => {
  return (
    <div
      className="large__label2__bold"
      style={{
        padding: "6px 5px 6px 5px",
        borderRadius: "4px",
        background: status === 0 ? "#FBC5EC" : "#D0CBFE",
        color: status === 0 ? "#7E1F64" : "#5247A8",
        textAlign: "center",
      }}
    >
      {status === 0 ? "Optional" : "Mandatory"}
    </div>
  );
};

export default SkillOptionChip;
