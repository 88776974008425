import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PieChart, Pie, Cell } from "recharts";
import useDashboardData from "../../hooks/use-dashboard-data";
import {
  GET_LOGIN_STATUS_API,
  GET_USER_STATS_API,
} from "../../utils/api-constants";
import LoadingError from "../dashboard/loading-error";
import {
  updateLoginStatus,
  updateUserStats,
} from "../../redux/dashboard-slice";

const CircularChartCard = ({ loginStatus = true, height = 188 }) => {
  const dispatch = useDispatch();
  const { loading, error, data } = useDashboardData(
    loginStatus ? GET_LOGIN_STATUS_API : GET_USER_STATS_API
  );
  const [lists, setLists] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const getData = () => {
      var items = [];
      var cols = [];
      if (loginStatus) {
        dispatch(updateLoginStatus(data));
        var per_logged_in = parseFloat(data["per_logged_in"]);
        var per_pending_logged_in = parseFloat(data["per_pending_logged_in"]);
        if (per_logged_in > 0) {
          cols.push("#5DDA5D");
          items.push({
            name: "Logged In",
            count: data["logged_in"],
            value: per_logged_in < 1 ? 1 : per_logged_in,
          });
        }
        if (per_pending_logged_in > 0) {
          cols.push("#F4A71D");
          items.push({
            name: "Pending Log In",
            count: data["pending_logged_in"],
            value: per_pending_logged_in < 1 ? 1 : per_pending_logged_in,
          });
        }
      } else {
        dispatch(updateUserStats(data));
        var per_active = parseFloat(data["per_active"]);
        var per_fairy_active = parseFloat(data["per_fairy_active"]);
        var per_inactive = parseFloat(data["per_inactive"]);
        if (per_active > 0) {
          cols.push("#5DDA5D");
          items.push({
            name: "Active Users",
            count: data["active"],
            value: per_active < 1 ? 1 : per_active,
          });
        }
        if (per_fairy_active > 0) {
          cols.push("#F4A71D");
          items.push({
            name: "Fairly Active Users",
            count: data["fairy_active"],
            value: per_fairy_active < 1 ? 1 : per_fairy_active,
          });
        }
        if (per_inactive > 0) {
          cols.push("#FF7448");
          items.push({
            name: "Inactive Users",
            count: data["inactive"],
            value: per_inactive < 1 ? 1 : per_inactive,
          });
        }
      }
      setColors(cols);
      setLists(items);
    };

    if (data != null) getData();
  }, [loginStatus, data]);

  return loading || error ? (
    <LoadingError loading={loading} error={error} height={height} />
  ) : (
    <div
      className="d-flex align-center justify-center my2"
      style={{ overflow: "visible" }}
    >
      {lists.length > 0 ? (
        lists.every((e) => e["value"] === 0) ? (
          <div
            className="d-flex align-center justify-center"
            style={{ height: height + "px" }}
          >
            Inadequate Product Usage to Display Data
          </div>
        ) : (
          <PieChart
            width={height}
            height={height}
            style={{ overflow: "visible" }}
          >
            <Pie
              cx="50%"
              cy="50%"
              data={lists}
              innerRadius={55}
              outerRadius={75}
              // cornerRadius={40}
              // paddingAngle={-10}
              dataKey="value"
              // label={renderActiveShape}
            >
              {colors.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry} strokeWidth={1.6} />
              ))}
            </Pie>
          </PieChart>
        )
      ) : (
        <div
          className="d-flex align-center justify-center"
          style={{ height: height + "px" }}
        >
          Inadequate Product Usage to Display Data
        </div>
      )}
    </div>
  );
};

export default CircularChartCard;
