import TooltipButton from "../tooltip-button"

const UserTypeSummaryTooltipContent = () => {
    return <TooltipButton style={{ width: '352px', left: '-60px' }}>
        <div className='large__label2__bold' style={{ color: '#4839CD' }}>User Type Summary</div>
        <div className='d-flex flex-col' style={{ gap: '6px' }}>
            <div className='d-flex gap1'>
                <div className='d-flex align-center justify-center large__caption3__bold' style={{ height: '16px', minWidth: '16px', color: '#7364EC' }}>1</div>
                <div className='large__caption3__regular' style={{ color: '#696F79' }}>Please note <strong>User Type Summary</strong> data is always updated till the last completed month</div>
            </div>
            <div className='d-flex gap1'>
                <div className='d-flex align-center justify-center large__caption3__bold' style={{ height: '16px', minWidth: '16px', color: '#7364EC' }}>2</div>
                <div className='large__caption3__regular' style={{ color: '#696F79' }}>
                    The data for a running month would be reflected here once the previous month is completed.
                </div>
            </div>
        </div>

    </TooltipButton>
}

export default UserTypeSummaryTooltipContent;