import { CONFIRM_PROJECT_SELECTION_API, DISPLAY_PROJECTS_API, apiRequestOptions } from "../utils/api-constants";
// get project list by type
// 0 is pending, 1 is approved, 2 rejected
export async function getProjectListAPI(grade, section, schoolCode) {
    try {
        const options = apiRequestOptions({
            class: grade,
            section: section,
            school_code: schoolCode,
        });
        return await fetch(DISPLAY_PROJECTS_API, options)
            .then(res => res.json())
            .then(json => json['details'])
            .catch(e => { throw new Error(e) })
        // return fetch('https://jsonplaceholder.typicode.com/users').then(res => res.json()).then(res => res);
    } catch (e) {
        console.error(e)
    }
}

// approve project
export async function confirmProjectSelectionService(lists = []) {
    try {
        const options = apiRequestOptions({ project_list: lists });
        return await fetch(CONFIRM_PROJECT_SELECTION_API, options)
            .then(res => res.json())
            .then(json => json['status'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}
