import { Heart } from "lucide-react";
import { addToFavouriteAPI, removeFromFavouriteAPI } from "../service/favourite-service";

const FavouriteILMButton = ({ isFavourites, skillId, themeId, ilmId, callback = () => { } }) => {
    return <button
        className='icon'
        style={{ background: 'transparent' }}
        onClick={e => {
            e.stopPropagation();
            if (isFavourites) {
                removeFromFavouriteAPI(skillId, themeId, ilmId).then((status) => {
                    if (String(status) === '200') {
                        callback();
                    }
                });
            } else {
                addToFavouriteAPI(skillId, themeId, ilmId).then((status) => {
                    if (String(status) === '200') {
                        callback();
                    }
                });
            }
        }}
    >
        <Heart size={20} fill={`${isFavourites ? '#FCC000' : 'none'}`} color='#FCC000' />
    </button>
}

export default FavouriteILMButton;