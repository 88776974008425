import { createSlice } from '@reduxjs/toolkit'

export const historySlice = createSlice({
    name: 'history',
    initialState: {
        selected: null,
    },
    reducers: {
        setSelected: (state, action) => {
            state.selected = action.payload;
        }
    },
});

export const selectedEvent = (state) => state.history.selected;

export const { setSelected } = historySlice.actions

export default historySlice.reducer;


