import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import { ROOT_PATH } from "../../utils/route-constants";
// import AuthHeader from "./auth-header";

const PublicLayout = () => {
    const { user } = useAuth();
    return user ?
        <Navigate to={ROOT_PATH} replace /> :
        <div className='p11 d-flex flex-col gap9' style={{ minHeight: '100vh' }}>
            {/* <AuthHeader /> */}
            <Outlet />
        </div>
};

export default PublicLayout;