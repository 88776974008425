import { ADD_TO_FAVOURITE_API, FAVOURITE_LIST_API, REMOVE_FROM_FAVOURITE_API, WATCH_HISTORY_LIST_API, apiRequestOptions } from '../utils/api-constants';

// get favourites ilms
export async function getFavouriteILMs() {
    try {
        const _userId = window.localStorage.getItem('userId');
        const options = apiRequestOptions({ user_id: _userId });
        return await fetch(FAVOURITE_LIST_API, options)
            .then(res => res.json())
            .then(json => json['details'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}

// get watch histroy ilms
export async function getWatchHistoryILMs(type) {
    try {
        const _userId = window.localStorage.getItem('userId');
        const options = apiRequestOptions({ user_id: _userId, type: type });
        return await fetch(WATCH_HISTORY_LIST_API, options)
            .then(res => res.json())
            .then(json => json['details'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}

// add to favourite
export async function addToFavouriteAPI(skillId, themeId, ilmId) {
    try {
        const _userId = window.localStorage.getItem('userId');
        const options = apiRequestOptions({
            user_id: _userId,
            skill_id: skillId,
            theme_id: themeId,
            ilm_id: ilmId,
            version: 1,
        });
        return await fetch(ADD_TO_FAVOURITE_API, options)
            .then(res => res.json())
            .then(json => json['status'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}

// remove from favourite
export async function removeFromFavouriteAPI(skillId, themeId, ilmId) {
    try {
        const _userId = window.localStorage.getItem('userId');
        const options = apiRequestOptions({
            user_id: _userId,
            skill_id: skillId,
            theme_id: themeId,
            ilm_id: ilmId,
            version: 1,
        });
        return await fetch(REMOVE_FROM_FAVOURITE_API, options)
            .then(res => res.json())
            .then(json => json['status'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}