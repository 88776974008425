import { Info } from 'lucide-react';
import useComponentVisible from '../../hooks/use-component-visible';
const TooltipButton = ({ children, style }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    return <div ref={ref} className='tooltip__button d-flex align-center justify-center'>
        <Info
            style={{ cursor: 'pointer' }} fill='#594BDD' color='#ffffff' size={20}
            onMouseEnter={() => setIsComponentVisible(true)}
            onMouseLeave={() => setIsComponentVisible(false)}
        />
        {isComponentVisible && <div className='tooltip__button__content bubble__left' style={style}>
            <div className='tooltip__button__content__inner d-flex flex-col' style={{ gap: '10px' }}>{children}</div>
        </div>}
    </div>
}

export default TooltipButton;