import { Outlet } from "react-router-dom";
import DashboardSideMenu from '../../../componets/dashboard/dashboard-side-menu';
import { GRADE_DASHBOARD_MENU } from '../../../utils/constants';

const GradeDashboard = () => {

    return <div className='dashboard__content'>
        <DashboardSideMenu menus={GRADE_DASHBOARD_MENU} />
        <Outlet />
    </div>;
}

export default GradeDashboard;