const ProjectApprovalStatusChip = ({ status }) => {
  const _lists = ["0", "1", "2"];
  return (
    <div
      className={`d-flex align-center justify-center large__label2__${
        _lists.includes(status) ? "bold" : "regular"
      }`}
      style={{
        borderRadius: "4px",
        width: "100%",
        padding: "6px 5px",
        background: _lists.includes(status) ? "#E9EDFE" : "",
        color: _lists.includes(status) ? "#1E4FEE" : "#3E444F",
      }}
    >
      {status === "0"
        ? "Pending"
        : status === "1"
        ? "Approved"
        : status === "2"
        ? "Rejected"
        : "NA"}
    </div>
  );
};

export default ProjectApprovalStatusChip;
