import { Outlet } from 'react-router-dom';
import DashboardHeader from '../../componets/dashboard/dashboard-header';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedGrade, updateSelectedMonth } from '../../redux/global-slice';
import { userDetailReducer } from '../../redux/reducers';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const detail = useSelector(userDetailReducer);

    useEffect(() => {
        var res = new Date().getMonth();
        dispatch(updateSelectedMonth(res));
        if (detail['role_id'] !== '10') dispatch(updateSelectedGrade(''));
        setTimeout(() => setShow(true), 250);
        return () => {
            var res = new Date().getMonth() + 1;
            dispatch(updateSelectedMonth(res));
        }
    }, []);

    return show && <div className='dashboard' style={{ height: '100%' }}>
        <DashboardHeader />
        <Outlet />
    </div>
}

export default Dashboard;