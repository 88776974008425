// 1 is active, 0 is pending, -1 is inactive
// 9 is ilm, 10 in ia, 11 is project
const LoginStatusIndicator = ({ title, status, count, percentage }) => {
  return (
    <div className="d-flex align-center gap2">
      <div
        style={{
          width: "56px",
          height: "32px",
          background: _getBG(status),
          borderRadius: "6px",
          color: "#1D2025",
        }}
        className="d-flex align-center justify-center large__caption3__bold"
      >
        {percentage ?? 0}%
      </div>
      <div className="d-flex flex-col align-start">
        <div
          className="large__label2__regular"
          style={{ color: "#696F79", lineHeight: "1.1" }}
        >
          {title}
        </div>
        <div
          className="large__label2__bold"
          style={{ color: "#444B57", lineHeight: "1.1" }}
        >
          {count ?? 0} Students
        </div>
      </div>
    </div>
  );
};

export default LoginStatusIndicator;

function _getBG(status) {
  if (status === -1) return "#FF7448";
  if (status === 0) return "#F4A71D";
  if (status === 1) return "#5DDA5D";
  if (status === 2) return "#BCFEBC";
  if (status === 3) return "#FED07E";
  if (status === 9) return "#686EF5";
  if (status === 10) return "#FF7448";
  if (status === 11) return "#EE7ACD";
}
