import LineChartCard from "../../../componets/charts/line-chart-card";
import LoginStatusIndicator from "../../../componets/dashboard/login-status-indicator";
import PerfomanceTrendTooltipContent from "../../../componets/dashboard/tooltip/perfomance-trend-tooltip-content";
import SelectYear from "../../../componets/input/select-year";

const PerformanceTrend = () => {
    return <div className='dashboard__card' style={{ height: '586px' }}>
        <div className='d-flex justify-between'>
            <div className='d-flex align-center justify-center gap2'>
                <h6>Performance Trend</h6>
                <PerfomanceTrendTooltipContent />
            </div>
            <SelectYear />
        </div>
        <div className='d-flex align-center gap4 justify-end gap10'>
            <LoginStatusIndicator status={9} title='ILMs' />
            <LoginStatusIndicator status={10} title='IAs' />
            <LoginStatusIndicator status={11} title='Projects' />
        </div>
        <LineChartCard />
    </div>
}

export default PerformanceTrend;