import { useEffect, useState } from 'react';
import IlmJourneyCard from '../componets/journey/ilm-journey-card';
import Stem from '../assets/stem.png';
import Coin from '../assets/coin.png';
import Certificate from '../assets/certificate.png';
import { Award } from 'lucide-react';
import JourneyGrade from '../componets/journey/journey-grade';
import { useLoaderData } from 'react-router-dom';

const IlmJourney = () => {
    const data = useLoaderData();
    const [ilms, setIlms] = useState([]);

    useEffect(() => {
        if (data) {
            var lists = [];
            if (data['ilms']['bos']) {
                data['ilms']['bos'].forEach(e => lists.push(e));
            }
            if (data['ilms']['ilm']) {
                data['ilms']['ilm'].forEach(e => lists.push(e));
            }
            if (data['ilms']['cap']) {
                data['ilms']['cap'].forEach(e => lists.push(e));
            }
            if (data['ilms']['eos']) {
                data['ilms']['eos'].forEach(e => lists.push(e));
            }
            setIlms(lists);
        }
    }, [data]);
    return <div className="ilm__journey">
        <div className="journey__roadmap">
            <div className="journey__roadmap__content">
                <div className="" />
                <div className='journey__road'>
                    {ilms.map((ilm, i) =>
                        <IlmJourneyCard
                            key={i}
                            index={i + 1}
                            enabled={ilm['is_unlocked'] === '1'}
                            ilm={ilm}
                            style={{ top: `${_tops[i] - 280}px`, left: `${_lefts[i]}px` }} />
                    )}
                </div>

            </div>
            <JourneyGrade />
        </div>
        <div className="journey__detail d-flex flex-col gap3">
            <div className="bg-surface-element radius3 p2 depth4" style={{ aspectRatio: '16 / 9' }}>
                <img src={data['skill_thumbnail']} alt={data['skill_name']} />
            </div>
            <div className="large__header3__bold">{data['skill_name']}</div>
            <div className='journey__rewards d-flex align-center gap7'>
                <img src={Stem} alt='Ulipsu Stem' style={{ height: '60px', width: '40px', }} />
                <div className='radius1 elevation2 bg-surface-element' style={{ height: '24px', width: '4px', }} />
                <div className='d-flex fl1'>
                    <div className='fl1 d-flex gap1 flex-col align-center justify-center'>
                        <div className='bg-surface-element elevation1 d-flex align-center justify-center p1' style={{ height: '32px', width: '32px', borderRadius: '50%', }}>
                            <Award color='#7364EC' />
                        </div>
                        <div className='d-flex gap1 large__body__bold'>
                            <div className='large__body__bold' style={{ color: '#F3A61D' }}>5900</div>
                            <div className='large__body__bold' style={{ color: '#696F79' }}>RP</div>
                        </div>
                    </div>
                    <div className='fl1 d-flex gap1 flex-col align-center justify-center'>
                        <div className='bg-surface-element elevation1 d-flex align-center justify-center p1' style={{ height: '32px', width: '32px', borderRadius: '50%', }}>
                            <img src={Coin} alt='Ulipsu Coin' style={{ objectFit: 'contain' }} />
                        </div>
                        <div className='d-flex gap1 large__body__bold'>
                            <div className='large__body__bold' style={{ color: '#7364EC' }}>2</div>
                            <div className='large__body__bold' style={{ color: '#696F79' }}>Coins</div>
                        </div>
                    </div>
                    <div className='fl1 d-flex gap1 flex-col align-center justify-center'>
                        <div className='bg-surface-element elevation1 d-flex align-center justify-center p1' style={{ height: '32px', width: '32px', borderRadius: '50%', }}>
                            <img src={Certificate} alt='Ulipsu Certificate' style={{ objectFit: 'contain' }} />
                        </div>
                        <div className='d-flex gap1'>
                            <div className='large__body__bold' style={{ color: '#7364EC' }}>Ulipsu</div>
                            <div className='large__body__bold' style={{ color: '#696F79' }}>Certificate</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="large__body__regular">{data['skill_description']}</div>
        </div>
    </div>
}

export default IlmJourney;

const _tops = [401, 571, 788, 996, 1211, 1473, 1728, 2030, 2357, 2507, 2754];
const _lefts = [60, 440, 48, 445, 47, 455, 47, 455, 47, 455, 47]