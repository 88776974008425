const TextareaInput = ({ placeholder, value, setValue }) => {
    return <div className='input__container'>
        <textarea
            id="textarea"
            name="textarea"
            rows="5"
            placeholder={placeholder}
            value={value}
            onChange={e => setValue(e.target.value)}
        />
    </div>
}

export default TextareaInput;