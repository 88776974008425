const PhoneInput = ({ label = 'Phone Number', phone, setPhone, disabled = false }) => {
    return <div className="input__container">
        <label htmlFor="phone">{label}</label>
        <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Enter Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled={disabled}
        />
    </div>
}

export default PhoneInput;