import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularChartCard from "../charts/circular-chart-card";
import LoginStatusIndicator from "./login-status-indicator";
import { dashboardLoginStatusReducer } from "../../redux/reducers";
import { clearDashbaord } from "../../redux/dashboard-slice";

const LoginStatusCard = () => {
  const dispatch = useDispatch();
  const data = useSelector(dashboardLoginStatusReducer);
  useEffect(() => {
    return () => {
      dispatch(clearDashbaord());
    };
  }, []);
  return (
    <div className="dashboard__card animate">
      <h6>Login Status</h6>
      <CircularChartCard />
      <div className="d-flex align-center justify-center gap6">
        <LoginStatusIndicator
          status={1}
          title="Logged In"
          count={data.logged_in}
          percentage={data.per_logged_in}
        />
        <LoginStatusIndicator
          status={0}
          title="Pending Login"
          count={data.pending_logged_in}
          percentage={data.per_pending_logged_in}
        />
      </div>
    </div>
  );
};

export default LoginStatusCard;
