import { useState } from "react";
import NameInput from "../../componets/input/name-input";
import PhoneInput from "../../componets/input/phone-input";
import EmailInput from "../../componets/input/email-input";
import DateInput from "../../componets/input/date-input";
import SelectGender from "../../componets/input/select-gender";
import CountryInput from "../../componets/input/country-input";
import CityInput from "../../componets/input/city-input";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail, updateUserDetailAPI } from "../../service/auth-service";
import { updateUserDetail } from "../../redux/auth-slice";
import { updateLoading } from "../../redux/global-slice";
import { userDetailReducer } from "../../redux/reducers";

const EditProfile = () => {
    const dispatch = useDispatch();
    const detail = useSelector(userDetailReducer);
    const [name, setName] = useState(detail?.fullname ?? '');
    const [phone, setPhone] = useState(detail?.phone ?? '');
    const [email, setEmail] = useState(detail?.email ?? '');
    const [dob, setDob] = useState(detail?.dob ?? '');
    const [gender, setGender] = useState(detail?.gender ?? '');
    const [country, setCountry] = useState(detail?.country ?? '');
    const [city, setCity] = useState(detail?.city ?? '');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (dob.length > 0 && gender.length > 0 && country.length > 0 && city.length > 0 && name.length > 0 && email.length > 0 && phone.length > 0) {
            dispatch(updateLoading(true))
            updateUserDetailAPI(dob, gender, country, city, name, email, phone)
                .then(() => {
                    alert('Profile updated successfully!');
                    const userId = window.localStorage.getItem('userId');
                    getUserDetail(userId).then(res => dispatch(updateUserDetail(res)));
                })
                .finally(() => dispatch(updateLoading(false)));
        }
    }

    return <div className="edit__profile d-flex justify-center">
        <div className="profile d-flex flex-col gap8">
            <div className="bg-surface-element p2 radius4 elevation5">
                <div className="profile__card__content d-flex flex-col align-center justify-center">
                    <div className="large__header5__bold" style={{ color: '#3E444F' }}>{detail.fullname}</div>
                    <div className="large__body__bold" style={{ color: '#5247A8' }}>{detail.school_name}</div>
                </div>
            </div>

            <div className="bg-surface-element radius6 p6 elevation5 d-flex flex-col gap5">
                <NameInput name={name} setName={setName} />
                <PhoneInput phone={phone} setPhone={setPhone} />
                <EmailInput email={email} setEmail={setEmail} />
                <DateInput value={dob} setValue={setDob} />
                <SelectGender value={gender} setValue={setGender} />
                <div className="d-flex gap5">
                    <CountryInput value={country} setValue={setCountry} />
                    <CityInput value={city} setValue={setCity} />
                </div>
                <button className="primary" onClick={handleSubmit}>Confirm Chnages</button>
            </div>
        </div>
    </div>
}
export default EditProfile;