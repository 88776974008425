import { useLoaderData, useNavigate } from "react-router-dom";
import { SUPPORT_PATH } from '../utils/route-constants';
import FaqsList from "../componets/support/faqs-list";
import TutorialVideosList from "../componets/support/tutorial-videos-list";

const SupportVideos = () => {
    const { faqs, tutorial } = useLoaderData();
    const navigate = useNavigate();

    return <div className="d-flex flex-col gap10" style={{ width: '100%' }}>
        {/* tutorial videos */}
        <div className="d-flex flex-col gap4">
            <div>
                <div className="large__header3__bold" style={{ color: '#3A2CB8' }}>Tutorial Videos</div>
                <div className="large__header5__regular" style={{ color: '#4839CD' }}>Explore our features.</div>
            </div>
            <TutorialVideosList lists={tutorial !== null && tutorial['lesson_details'] !== null ? tutorial['lesson_details'] : []} />
        </div>
        {/* FAQS */}
        <div className="d-flex flex-col gap6">
            <div className="fl1">
                <div className="large__header3__bold" style={{ color: '#3A2CB8' }}>Have queries ?</div>
                <div className="large__header5__regular" style={{ color: '#4839CD' }}>Find answers in our FAQ section !</div>
            </div>
            <FaqsList lists={faqs ?? []} />
        </div>
        {/* contact us */}
        <div className="d-flex justify-between align-center">
            <div className="fl1">
                <div className="large__header3__bold" style={{ color: '#3A2CB8' }}>Contact Us</div>
                <div className="large__header5__regular" style={{ color: '#4839CD' }}>For any queries, reach out to use here</div>
            </div>
            <button
                className="primary neo medium"
                onClick={() => navigate(`/${SUPPORT_PATH}`)}
                style={{ width: '192px' }}
            >Raise a Concern</button>
        </div>
    </div>
}

export default SupportVideos;