import { SKILL_WISE_REPORT_HEADER } from "../../../../utils/constants";
import TableListData from "../../../../componets/dashboard/table-list-data";
import useDashboardData from "../../../../hooks/use-dashboard-data";
import { STUDENTS_REPORT_SKILLWISE_API } from "../../../../utils/api-constants";

const SkillWiseReport = () => {
    const { loading, data, error } = useDashboardData(STUDENTS_REPORT_SKILLWISE_API);

    return <TableListData
        headers={SKILL_WISE_REPORT_HEADER}
        loading={loading}
        error={error}
        lists={data}
        style={{ padding: 0, maxHeight: '49vh' }}
    />
}

export default SkillWiseReport;