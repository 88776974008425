import { Navigate, Outlet, useNavigation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AUTH_PATH, LOGIN_PATH } from "../../utils/route-constants";
import { useAuth } from "../../hooks/use-auth";
import Navbar from '../navbar';
import Loader from "../loader";
import { globalLoadingReducer } from "../../redux/reducers";
import useNetworkHook from '../../hooks/use-network';
import ToastMessage from "../toast-message";
import { useEffect, useState } from "react";

const ProtectedLayout = () => {
    const { user } = useAuth();
    const navigation = useNavigation();
    const loading = useSelector(globalLoadingReducer);
    const [toast, setToast] = useState(false);
    
    const networkState = useNetworkHook();
    const { online } = networkState;

    useEffect(() => { setToast(!online) }, [online]);

    return user ? <div className='app d-flex flex-col px15 pt8'>
        <Navbar />
        <main className='d-flex py8'>
            <Outlet />
            <ToastMessage setToast={setToast} toast={toast} type={-1} title='No Internet' text='You seem to have no internet accessm, please fix your connection to continue using Ulipsu' seconds={5000} />
        </main>
        {(navigation.state === 'loading' || loading) ? <Loader /> : null}
    </div> : <Navigate to={AUTH_PATH + '/' + LOGIN_PATH} replace />;
};

export default ProtectedLayout;