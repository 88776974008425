import { useDispatch, useSelector } from "react-redux";
import { selectedGradeReducer, userClassesReducer } from "../../redux/reducers";
import { useEffect, useState } from "react";
import { updateSelectedGrade, updateSelectedSection } from "../../redux/global-slice";
import { getParsedGrade } from '../../utils/constants'
import { ChevronDown } from "lucide-react";
import { removeDuplicatesGrade } from "../../utils/utils";

const SelectGrade = ({ select = false, isMass = true, title = true, dashboard = false }) => {
    const dispatch = useDispatch();
    const grade = useSelector(selectedGradeReducer);
    const classes = useSelector(userClassesReducer);
    const [options, setOptions] = useState([]);

    // get & set filtered grades based on is mass implementation grade
    useEffect(() => {
        const unique = removeDuplicatesGrade(classes);
        if (isMass) {
            const updated = unique.filter(e => e['is_mass'] === '1');
            setOptions(updated);
        } else {
            setOptions(unique);
        }
    }, [classes, isMass]);

    // initialize grade lists
    useEffect(() => {
        if (options.length > 0 && select) {
            const x = options[options.length - 1];
            dispatch(updateSelectedGrade(x['grade']));
            dispatch(updateSelectedSection(x['section']));
        } else {
            dispatch(updateSelectedGrade(''));
        }
    }, [options, select]);

    return <div className={`input__container ${dashboard && 'dashboard__input__container'}`} style={{ minWidth: grade.length < 1 ? '140px' : '118px' }}>
        {title && <label htmlFor="grade">{dashboard ? 'Grade' : 'Select Grade'}</label>}
        <select onChange={(e) => { dispatch(updateSelectedGrade(e.target.value)) }} id="grade" value={grade}>
            <option value='' disabled>Select Grade</option>
            {options.map((option, index) =>
                <option key={index} value={option['grade']}>Grade {getParsedGrade(option['grade'])}</option>
            )}
        </select>
        <ChevronDown color='#3F3782' />
    </div>
}

export default SelectGrade;
