import { useLoaderData } from 'react-router-dom';
import DailyQuestCard from '../../componets/daily-quest-card';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTabs } from '../../redux/lesson-slice';

const DailyQuest = () => {

    const detail = useLoaderData();
    const dispatch = useDispatch();

    useEffect(() => {
        if (detail) {
            dispatch(updateTabs({ classes: false, minCount: detail.length, maxCount: 3 }))
        }
    }, [detail]);

    return detail != null ? <div className='d-flex flex-col gap6'>
        <div className='d-flex flex-col gap1'>
            <div className='large__header3__bold'>Daily Quest</div>
            <div className='large__header5__regular'>Daily Quests unlock after completion of ILM</div>
        </div>
        <div className='d-grid gap6' style={{ gridTemplateColumns: 'repeat( auto-fill, minmax(400px, 1fr))' }}>
            {[0, 1, 2].map(e => <DailyQuestCard key={e} index={e + 1} quest={detail[e]} />)}
        </div>
    </div> : <div className="d-flex justify-center align-center">No data found</div>
}

export default DailyQuest;