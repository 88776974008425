import { X } from 'lucide-react';
const Dialog = ({ setDialog, dialog = false, children, title, maxWidth = '1000px', close = true }) => {
    return dialog && <div className="dialog f-flex justify-center align-center" onClick={() => {
        if (close) setDialog(false);
    }}>
        <div className='d-flex align-center justify-center p6' style={{ height: '100%', width: '100%' }}>
            <div className='' style={{ overflow: 'visible', position: 'relative' }} onClick={e => e.stopPropagation()}>
                <div className={`dialog__inner d-flex flex-col gap5 ${title && 'px20 py12'}`} style={{ maxWidth: maxWidth }}>
                    {title && <div className='large__header3__bold' style={{ textAlign: 'center' }}>{title}</div>}
                    {children}
                </div>
                {close && <div
                    className='d-flex align-center justify-center bg-surface-element radius1 elevation3'
                    style={{ height: '32px', width: '32px', cursor: 'pointer', position: 'absolute', top: '-48px', right: 0, zIndex: 1000000 }}
                    onClick={() => setDialog(false)}
                >
                    <X size={20} color='#696F79' />
                </div>}
            </div>
        </div>
    </div>;
}

export default Dialog;