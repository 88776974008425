import { useEffect, useRef, useState } from "react";
import ProjectConformSelection from '../projects/project-confirm-selection';
import ProjectCard from './project-card';
import ProjectViewCard from "./project-view-card";

// pending is 0, approved is 1, rejected is 2
const ProjectContent = ({ projects = [], selected, type }) => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedLists, setSelectedLists] = useState([]);

    // update list for approving & rejecting
    const updateLists = (item, status) => {
        var latestLists = [...selectedLists];
        const index = latestLists.findIndex(e => e['id'] === item['id']);
        if (index > -1) {
            if (latestLists[index]['status'] === status) {
                latestLists.splice(index, 1);
            } else {
                latestLists[index] = { ...item, status: status }
            }
        } else {
            latestLists.push({ ...item, status: status })
        }
        setSelectedLists(latestLists);
    }

    // select first project
    useEffect(() => {
        if (projects.length > 0) {
            setSelectedProject(projects[0]);
        } else {
            setSelectedProject(null);
        }
        setTimeout(() => setSelectedLists([]), 250);
    }, [projects]);

    // determine current scroll position of project list container
    const [scrollTopPosition, setScrollTopPosition] = useState(0);
    const scrollDemoRef = useRef(null);

    const handleScroll = () => {
        if (scrollDemoRef.current) {
            const { scrollTop } = scrollDemoRef.current;
            setScrollTopPosition(scrollTop);
        }
    };

    if (selected) {
        return <div className='project__content radius4 elevation5' style={{ gridTemplateColumns: projects.length > 0 ? '646px auto' : 'auto' }}>
            <div className='d-flex flex-col gap4 p3' style={{ height: '100%', overflow: 'hidden' }}>
                {(projects.length > 0 && type === 0) && <ProjectConformSelection lists={selectedLists} />}
                {projects.length > 0 ? <div className='project__lists fl1 d-flex flex-col gap3' ref={scrollDemoRef} onScroll={handleScroll}>
                    {
                        projects.map((item, i) => <ProjectCard
                            top={scrollTopPosition} key={i} item={item}
                            type={type} updateLists={updateLists} lists={selectedLists}
                            setSelectedProject={setSelectedProject} selected={selectedProject}
                        />)
                    }
                </div> : <div className="d-flex flex-col align-center justify-center gap3" style={{ height: '100%' }}>
                    <div className='large__header5__bold' style={{ color: '#7364EC' }}>No Projects  Found</div>
                    <div className='large__body__regular' style={{ color: '#696F79' }}>Please guide your students to submit and check back at a later time.</div>
                </div>}
            </div>
            {/* selected project view */}
            {selectedProject !== null && <ProjectViewCard project={selectedProject} type={type} />}
        </div>
    } else {
        return <div className='radius4 elevation5' style={{ height: '64vh' }}>
            <div className="d-flex align-center justify-center large__header5__bold" style={{ height: '100%', color: '#7364EC' }}>Please select a grade to view projects</div>
        </div>
    }
}

export default ProjectContent;

