import Logo from '../assets/logo.png'
import ProfileMenuCard from './profile-menu-card';
import { NavLink, useLocation } from 'react-router-dom';
import { DASHBOARD_PATH, GRADE_PATH, OVERVIEW_PATH, ROOT_PATH, SCHOOL_PATH } from '../utils/route-constants';
import { NAVBAR_MENUS } from '../utils/constants';
import { useSelector } from 'react-redux';
import useComponentVisible from '../hooks/use-component-visible';
import { useEffect, useState } from 'react';
import { userDetailReducer } from '../redux/reducers';
import { ChevronDown, ChevronUp } from 'lucide-react';
import ComingSoonTag from './coming-soon-tag';

const Navbar = () => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const detail = useSelector(userDetailReducer);

    const location = useLocation();
    useEffect(() => { setIsComponentVisible(false) }, [location]);
    const [menus, setMenus] = useState(NAVBAR_MENUS);

    useEffect(() => {
        if (detail) {
            var items = [...menus];
            if (detail['role_id'] === '10') {
                items[2] = { ...items[2], path: DASHBOARD_PATH + '/' + GRADE_PATH + '/' + OVERVIEW_PATH }
            } else {
                items[2] = { ...items[2], path: DASHBOARD_PATH + '/' + SCHOOL_PATH + '/' + OVERVIEW_PATH }
            }
            setMenus(items);
        }
    }, [detail]);

    return (
        <header className='py4 px8 radius3 elevation6 d-flex align-center justify-between' ref={ref}>
            <div className='header__logo d-flex align-center gap6'>
                <NavLink to={ROOT_PATH}>
                    <img src={Logo} alt='Ulipsu Logo' />
                </NavLink>
                <div className='header__divider elevation3' />
                <div style={{ maxWidth: '264px' }}>
                    <div className='large__body__bold text__elipsis' style={{ WebkitLineClamp: 2 }}>{detail.school_name}</div>
                </div>
                <div className='d-flex gap2'>
                    {menus.map((menu, i) =>
                        <NavLink
                            key={i}
                            // className={` ${location.pathname.split('/').includes(menu.path) && 'active'}`}
                            to={menu.path}
                            style={{ position: 'relative' }}
                            className={({ isActive }) => `large__body__regular p2 d-flex align-center justify-center ${(isActive || _isActive(location.pathname, menu.path)) && 'active'}`}
                        >
                            {menu.title}
                            {menu.comingSoon === true && <ComingSoonTag />}
                        </NavLink>
                    )}
                </div>
            </div>
            <div className='pos-relative d-flex align-center gap3'>
                <div
                    className='header__dp__card d-flex align-center justify-between gap2 radius2 px4'
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                >
                    <div className='large__body__regular' style={{ flex: 1, color: '#444B57', textAlign: 'center' }}>{detail.fullname}</div>
                    {isComponentVisible ? <ChevronUp size={20} color='#0F172A' /> : <ChevronDown size={20} color='#0F172A' />}
                </div>
                {isComponentVisible && <ProfileMenuCard name={detail.fullname} />}
            </div>
        </header>
    );
}

export default Navbar;

// is menu active for
const _isActive = (location, path) => {
    const splitedLocation = location.split('/').filter(e => e !== '');
    const splitedPath = path.split('/').filter(e => e !== '');
    return splitedLocation[0] === splitedPath[0];
}