import { Download } from "lucide-react";
import useComponentVisible from "../../hooks/use-component-visible";
import { downloadPDF, downloadPDFWithGeneratedtable, downloadTableAsCsv, downloadTableAsCsvWithGeneratedTable } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import {graphTableRoutes} from '../../utils/constants';

const DownloadDropdown = ({ data }) => {
    const location = useLocation();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    return <div className='download__dropdown' ref={ref}>
        <div className='download__dropdown__button' onClick={() => setIsComponentVisible(true)}>
            <Download color='#F4A71D' size={16} />
            <span>Download</span>
        </div>
        {isComponentVisible && <div className='download__dropdown__options d-flex flex-col gap1 animate'>
            <div
                className='px4 py2'
                style={{
                    fontFamily: 'Source Sans 3',
                    fontSize: '13px',
                    fontWeight: '600',
                    color: '#A9ACB2'
                }}
            >FILE TYPE</div>
            <div className='d-flex flex-col'>
                <div className='px4 py3 d-flex align-center justify-between download__dropdown__options__menu' onClick={() => {
                        if(location.pathname.includes(graphTableRoutes[0]) ||  location.pathname.includes(graphTableRoutes[1])) {
                            downloadTableAsCsvWithGeneratedTable(location.pathname, data);
                        } else {
                            downloadTableAsCsv('dashboard__table')
                        }
                    }}>
                    <p className='large__label2__bold'>EXCEL SHEET</p>
                    <span className='large__caption3__regular'>Data</span>
                </div>
                <div className='px4 py3 d-flex align-center justify-between download__dropdown__options__menu' onClick={() => {
                    if(location.pathname.includes(graphTableRoutes[0]) ||  location.pathname.includes(graphTableRoutes[1])) {
                        downloadPDFWithGeneratedtable(location.pathname, data);
                    } else {
                        downloadPDF('dashboard__table');
                    }
                }}>
                    <p className='large__label2__bold'>PDF</p>
                    <span className='large__caption3__regular'>Data</span>
                </div>
            </div>
        </div>}
    </div >
}

export default DownloadDropdown;