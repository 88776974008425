const IndividualTopPerfomanceCard = ({ rank, name, grade, points, large, isEmpty = false }) => {
    return <div className='fl1 d-flex flex-col align-center justify-center gap2'>
        <label style={{ fontSize: large ? '72px' : '48px' }}>{rank}</label>
        {!isEmpty && <div className='d-flex flex-col align-center justify-center'>
            <p className='large__header6__bold text__elipsis' style={{ textAlign: 'center', color: '#3E444F', WebkitLineClamp: 1 }}>{name}</p>
            <span className='large__label2__bold' style={{ color: '#696F79' }}>{grade}</span>
        </div>}
        {!isEmpty && <span className='large__label1__regular' style={{ color: '#696F79' }}>{Number(points).toFixed(0)} RP</span>}
        {isEmpty && <div className='large__body__regular d-flex flex-col' style={{ color: '#696F79', textAlign: 'center', lineHeight: '1.3' }}>
            <span>Rank</span>
            <span>not assigned</span>
        </div>}
    </div>;
}

export default IndividualTopPerfomanceCard;