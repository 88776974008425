import { useNavigate } from 'react-router';
import ActiveIlmCloud from '../../assets/active-ilm-cloud.png';
import DisabledIlmCloud from '../../assets/disabled-ilm-cloud.png';
import CircularProgress from '../progress/circular-progress';
import { CLASSES_PATH, CLASSROOM_PATH } from '../../utils/route-constants';
import ToastMessage from '../../componets/toast-message';
import { useState } from 'react';

const IlmJourneyCard = ({ index, ilm, style, enabled }) => {
    const navigate = useNavigate();
    const [toast, setToast] = useState(false);

    return <>
        <div
            className='animate ilm__journey__card' style={{ ...style, cursor: enabled ? 'pointer' : 'not-allowed' }}
            onClick={() => {
                if (enabled) {
                    navigate(`/${CLASSROOM_PATH}/${ilm['id']}/${CLASSES_PATH}`, { state: true })
                } else {
                    setToast(true);
                }
            }}
        >
            <img alt='ILM Name' src={enabled ? ActiveIlmCloud : DisabledIlmCloud} style={{ transform: `${enabled ? 'scale(1.25)' : 'scale(1.15)'}` }} />
            <div className='ilm__journey__card__content d-flex flex-col align-center gap2'>
                <CircularProgress fontSize='50px' size='48px' progress={ilm['progress_percentage'] ?? 0} text={index} disabled={!enabled} />
                <div className='ilm__title' style={{ color: enabled ? '#3A404A' : ' #6B6B6B' }}>{ilm['name']}</div>
                <div className={`${enabled ? 'ilm__class__detail__active' : 'ilm__class__detail'} d-flex gap1 radius1`}>
                    <div className='large__caption3__bold' style={{ color: enabled ? '#3A404A' : ' #6B6B6B' }}>{ilm['completed_classes']}</div>
                    <div className='large__caption3__regular' style={{ color: '#515762' }}> /{ilm['total_classes']} Classes</div>
                </div>
            </div>
        </div>
        <ToastMessage setToast={setToast} toast={toast} title='ILM Locked' text='Complete previous ILMs and come back soon!' />
    </>
}

export default IlmJourneyCard;