import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    events: [],
    selectedEvent: null,
    trendsList: null,
    selectedYear: "",
    loginStatus: {
      logged_in: 0,
      pending_logged_in: 0,
      per_logged_in: 0.0,
      per_pending_logged_in: 0.0,
    },
    userStats: {
      active: 0,
      fairy_active: 0,
      inactive: 0,
      per_active: 0.0,
      per_fairy_active: 0.0,
      per_inactive: 0.0,
    },
  },
  reducers: {
    updateDashboardEvents: (state, action) => {
      state.events = action.payload;
    },
    updateDashboardSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    updateDashboardTrendsList: (state, action) => {
      state.trendsList = action.payload;
    },
    updateDashboardSelectedYear: (state, action) => {
      state.selectedYear = action.payload;
    },
    updateLoginStatus: (state, action) => {
      state.loginStatus = action.payload;
    },
    updateUserStats: (state, action) => {
      state.userStats = action.payload;
    },
    clearDashbaord: (state) => {
      state.loginStatus = {
        logged_in: 0,
        pending_logged_in: 0,
        per_logged_in: 0.0,
        per_pending_logged_in: 0.0,
      };
      state.userStats = {
        active: 0,
        fairy_active: 0,
        inactive: 0,
        per_active: 0.0,
        per_fairy_active: 0.0,
        per_inactive: 0.0,
      };
    },
  },
});

export const {
  updateDashboardEvents,
  updateDashboardSelectedEvent,
  updateDashboardTrendsList,
  updateDashboardSelectedYear,
  updateLoginStatus,
  updateUserStats,
  clearDashbaord,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
