import { useEffect } from "react";
import { useLoaderData, useLocation } from "react-router-dom";
import LessonPlayer from "../../componets/classroom/lesson-player";
import LessonList from "../../componets/classroom/lesson-list";
import { useDispatch, useSelector } from "react-redux";
import { init } from "../../redux/lesson-slice";
import { selectedGradeReducer } from "../../redux/reducers";

// -1 is locked, 0 is playing, 1 is watched, 2 is unlocked
const ClassesList = () => {
    const dispatch = useDispatch();
    const detail = useLoaderData();
    const location = useLocation();
    const grade = useSelector(selectedGradeReducer);

    useEffect(() => {
        if (detail !== null) {
            const isLevel0 = (grade && grade.length > 0) ? ['1', '2'].includes(grade) : false;
            dispatch(init({ data: detail, conductSession: location.state, isLevel0: isLevel0 }));
        }
    }, [detail, location.state]);

    return detail != null ? <div className="classroom">
        <div className="classroom__left">
            <LessonPlayer />
            <div className="large__header3__bold mt2">{detail?.class_details?.name}</div>
            <div className="large__body__regular">{detail?.class_details?.description}</div>
        </div>
        <LessonList />
    </div> : <div className="d-flex justify-center align-center">No data found</div>
}

export default ClassesList;
