import { useSelector } from "react-redux";
import { schoolSkillsReducer } from "../../redux/reducers";
import { ChevronDown } from "lucide-react";

const SelectSkill = ({ value, setValue }) => {
    const options = useSelector(schoolSkillsReducer) ?? [];
    return (
        <div className="input__container">
            <label htmlFor="skills">Skill</label>
            <select onChange={(e) => setValue(e.target.value)} id="skills" value={value}>
                <option value='' disabled> Select a Skill</option>
                {options.map((option, index) =>
                    <option key={index} value={option['program_id']}>{option['program_name']} - Level({option['levels']})</option>
                )}
            </select>
            <ChevronDown color='#3F3782' />
        </div>
    );
}

export default SelectSkill;