import DashboardTableList from "../../../componets/dashboard/dashboard-list-table";
import { USAGE_SUMMARY_HEADER } from '../../../utils/constants';
import useDashboardData from "../../../hooks/use-dashboard-data";
import { SCHOOL_USAGE_SUMMARY_API } from "../../../utils/api-constants";
import { useEffect, useState } from "react";

const UsageSummary = () => {
    const [footer, setFooter] = useState([]);
    const { loading, error, data } = useDashboardData(SCHOOL_USAGE_SUMMARY_API);

    useEffect(() => {
        if (data && data.length > 0) {
            const t1 = data.reduce((acc, obj) => acc + Number(obj['ilm_watch_completed']), 0);
            const t2 = data.reduce((acc, obj) => acc + Number(obj['ia_completed_count']), 0);
            const t3 = data.reduce((acc, obj) => acc + Number(obj['projects_submitted']), 0);
            const t4 = data.reduce((acc, obj) => acc + Number(obj['project_approved']), 0);
            const t5 = data.reduce((acc, obj) => acc + Number(obj['ilm_completed']), 0);
            const t6 = data.reduce((acc, obj) => acc + Number(obj['skills_completed']), 0);
            var foo = ['Total', t1, t2, t3, t4, t5, t6];
            setFooter(foo);
        }
    }, [data]);

    return <DashboardTableList
        title='Usage Summary'
        headers={USAGE_SUMMARY_HEADER}
        footer={footer}
        lists={data}
        loading={loading}
        error={error}
        dateRange={true}
    >
        <div className='d-flex align-center gap3'>
            <div style={{ color: '#696F79' }}>Skills Completed</div>
            <div style={{ fontWeight: '600', color: '#3A2CB8' }}>Data takes 2.5+ months to populate after implementation starts.</div>
        </div>
    </DashboardTableList>
}

export default UsageSummary;