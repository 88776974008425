import { useNavigate } from "react-router-dom";
import { getFormattedDate, getFormattedTime, isEventEnded, isEventStarted } from "../../utils/utils";
import { EVENTS_PATH } from "../../utils/route-constants";
import EventJoinButton from "./event-join-button";
import { useEffect, useState } from "react";

const EventCard = ({ event, type }) => {
    const navigate = useNavigate();
    const enrolled = event['is_enrolled'] === '1';
    const [ended, setEnded] = useState(false);
    const [started, setStarted] = useState(false);

    const init = () => {
        const x = isEventEnded(event['scheduled_date'], event['duration']);
        setEnded(x);
        const y = isEventStarted(event['scheduled_date']);
        setStarted(y);
    }

    useEffect(() => { init() }, [event]);

    useEffect(() => {
        const timer = window.setInterval(() => { init() }, 1000);
        return () => {
            window.clearInterval(timer);
        };
    }, [event]);

    return <div
        className="event__card animate"
        onClick={(e) => {
            e.stopPropagation();
            navigate(`/${EVENTS_PATH}/${event.id}`, {
                state: type
            });
        }}
    >
        <div className="event__card__img">
            <img src={event.thumbnail} alt={event.name} />
        </div>
        <div className="event__card__content d-flex flex-col justify-between">
            <div>
                <div className="large__header5__bold">Event Name : {event.name}</div>
                <p className="large__body__regular text__elipsis" style={{ color: 'var(--neutral400)', WebkitLineClamp: '3' }}>{event.description}</p>
            </div>
            <div className="event__card__actions">
                <div className="d-flex flex-col align-start gap1">
                    {
                        (type === 4 || ended) ?
                            <div className='large__label2__bold'>Event Date</div> :
                            enrolled ?
                                <div className="event__card__status">{started && !ended ? 'Event is Live' : ended ? 'Event Expired' : 'Enrolled'}</div> :
                                <div className='enroll_to_attend large__caption3__bold'>{started && !ended ? 'Event is Live' : ended ? 'Event Expired' : 'Enroll to Attend'}</div>
                    }
                    <div className="event__card__time">
                        <span>{getFormattedDate(event['scheduled_date'])}</span>
                        <div />
                        <span>{getFormattedTime(event['scheduled_date'])}</span>
                    </div>
                </div>
                <div style={{ width: '185px' }}>
                    <EventJoinButton type={type} event={event} eventId={event.id} />
                </div>
            </div>
        </div>
    </div>
}

export default EventCard;