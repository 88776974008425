import { createContext, useContext, useEffect, useId, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_PATH, LOGIN_PATH, SELECT_MODE_PATH } from '../utils/route-constants';
import { useDispatch, useSelector } from "react-redux";
import { loginService, getUserDetail, getListOfSkillsAssignedToSchoolService } from '../service/auth-service';
import { updateUserId, updateUserDetail, updateSchoolSkills } from '../redux/auth-slice';
import { updateLoading } from '../redux/global-slice';
import { userDetailReducer, userIdReducer } from "../redux/reducers";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const userId = useSelector(userIdReducer);
    const userDetail = useSelector(userDetailReducer);
    const user = userId !== null && userDetail != null;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (useId !== null && userId !== 'null' && (typeof userId === "string" || typeof userId === "number")) {
            getDetail(userId)
        }
    }, [userId]);

    // login user using username & password
    const login = async (username, password) => {
        try {
            if (username.length > 0 && password.length > 0) {
                setError(null);
                setLoading(true);
                loginService(username, password).then(async res => {
                    if (res && String(res['status']) === '200' && res['details'] != null) {
                        const roleId = res['details']['role_id'];
                        if (['9', '10', '11'].includes(roleId)) {
                            dispatch(updateUserId((res['details']['id'])));
                        } else {
                            setError('You are not authorized to login!');
                        }
                    } else {
                        setError('Wrong credentials!');
                    }
                }).finally(() => setLoading(false));
            } else {
                setError('Please enter your username & password');
            }
        } catch (error) {
            setLoading(false);
            setError('Something went wrong!');
        }
    }

    // get user detail based by userID & save to redux
    const getDetail = async (id) => {
        try {
            dispatch(updateLoading(true));
            await getUserDetail(id)
                .then(async detail => {
                    if (detail && ['9', '10', '11'].includes(detail['role_id'])) {
                        dispatch(updateUserId(id));
                        dispatch(updateUserDetail(detail));
                        await getListOfSkillsAssignedToSchoolService(id)
                            .then(skills => dispatch(updateSchoolSkills(skills)));
                        var isMass = false;
                        if ('class' in detail) {
                            const res = detail.class.filter(e => e['is_mass'] === '1');
                            isMass = res.length > 0;
                        } else if ('is_mass' in detail && detail['is_mass'] === '1') {
                            isMass = true;
                        }
                        if (isMass) navigate(`/${SELECT_MODE_PATH}`);
                    } else {
                        logout();
                    }
                })
                .finally(() => dispatch(updateLoading(false)));
        } catch (error) {
            dispatch(updateLoading(false));
        }
    }

    // logout user & redirect to login
    const logout = () => {
        dispatch(updateUserId(null));
        dispatch(updateUserDetail(null))
        navigate(AUTH_PATH + '/' + LOGIN_PATH, { replace: true });
    };

    const value = useMemo(
        () => ({
            user,
            loading,
            error,
            login,
            logout,
        }),
        [user, loading]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
