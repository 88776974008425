import { useState } from "react";
import StackedChartCard from "../../../componets/charts/stacked-chart-card";
import LoginStatusIndicator from "../../../componets/dashboard/login-status-indicator";
import TableHeaderCard from "../../../componets/dashboard/table-header-card";
import useDashboardData from "../../../hooks/use-dashboard-data";
import { SCHOOL_LOGIN_STATUS_API } from "../../../utils/api-constants";

const SchoolLoginDetails = () => {
    const { loading, error, data } = useDashboardData(SCHOOL_LOGIN_STATUS_API);
    const [finalData, setFinalData] = useState([]);
    return <div className='dashboard__card' style={{ height: '586px' }}>
        <TableHeaderCard title='Login Details' padding='' data={finalData} />
        <div className='d-flex align-center justify-end gap10'>
            <LoginStatusIndicator status={2} title='Logged In' />
            <LoginStatusIndicator status={3} title='Yet To Log In' />
        </div>
        <StackedChartCard lists={data} loading={loading} error={error} setFinalData={setFinalData} />
    </div>
}

export default SchoolLoginDetails;