import TopPerfomersCard from '../../componets/dashboard/top-perfomers-card';
import LearningProgressCard from '../../componets/dashboard/learning-progress-card';
import LoginStatusCard from '../../componets/dashboard/login-status-card';
import UserStatsCard from '../../componets/dashboard/user-stats-card';
import { useLocation } from 'react-router-dom';

const DashboardOverview = () => {
    const location = useLocation();
    return <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'start', gap: '24px' }}>
        <TopPerfomersCard viewMore={location.pathname.includes('grade')} />
        <LearningProgressCard />
        <LoginStatusCard />
        <UserStatsCard />
    </div>
}

export default DashboardOverview;