import { useDispatch, useSelector } from "react-redux";
import { dashboardSelectedYearReducer, dashboardTrendsListReducer } from "../../redux/reducers";
import { updateDashboardSelectedYear } from "../../redux/dashboard-slice";
import { useEffect, useState } from "react";
import { ChevronDown } from "lucide-react";

const SelectYear = () => {
    const dispatch = useDispatch();
    const trends = useSelector(dashboardTrendsListReducer);
    const year = useSelector(dashboardSelectedYearReducer);
    const [years, setYears] = useState([]);

    useEffect(() => {
        if (trends !== null && typeof trends === 'object' && !Array.isArray(trends)) {
            const res = Object.entries(trends).map(([key]) => key);
            setYears(res);
            dispatch(updateDashboardSelectedYear(res[0]));
        } else {
            setYears([]);
            dispatch(updateDashboardSelectedYear(''));
        }
    }, [trends]);

    useEffect(() => {
        return () => {
            dispatch(updateDashboardSelectedYear(''));
        }
    }, []);

    return years.length > 0 && <div className={`input__container dashboard__input__container`} style={{ maxWidth: '160px' }}>
        <label htmlFor='grade'>Year</label>
        <select onChange={(e) => dispatch(updateDashboardSelectedYear(e.target.value))} value={year} disabled={years.length <= 1}>
            {years.map(y => <option key={y} value={y}>{y}</option>)}
        </select>
        <ChevronDown color={years.length <= 1 ? '#82868E' : '#3F3782'} />
    </div>
}

export default SelectYear;
