import BlurActionMenuItem from './blur-action-menu-item';

const BlurActionCard = ({ toggle, children, login = true, contact = false }) => {
    return <div style={{ position: 'fixed', top: '40px', bottom: '40px', right: '40px', left: '40px' }} className='d-flex'>
        <div
            className='fl1'
            style={{
                zIndex: 1,
                backdropFilter: 'blur(10px)',
                borderTopLeftRadius: '24px',
                borderBottomLeftRadius: '24px',
                boxShadow: '16px 16px 15px 0px #E7E7E7, 0px 8px 8px 0px #E7E7E799,-16px -16px 15px 0px #FFFFFF, 0px -8px 13.9px 0px #FFFFFF99',

            }}
        >
            <div style={{ height: '100%', width: '100%' }} className='d-flex flex-col align-end justify-center gap8'>
                {contact && <BlurActionMenuItem title='Contact Support' onClick={() => toggle(false, false, true)} />}
                {login && <BlurActionMenuItem title='Login' onClick={() => toggle(false, false, false)} />}
            </div>
        </div>
        <div
            className='fl1 bg-surface-element elevation6'
            style={{
                borderTopRightRadius: '24px',
                borderBottomRightRadius: '24px',
            }}
        >
            <div style={{ padding: '85px 126px', height: '100%' }}>
                {children}
            </div>
        </div>
    </div >
}

export default BlurActionCard;