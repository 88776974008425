import TooltipButton from "./tooltip-button";
import rpBadge from '../../assets/rp-badge.png';

const DashboardTooltipContentCard = () => {
    return <TooltipButton style={{ width: '352px' }}>
        <div className='d-flex gap1'>
            <img src={rpBadge} alt='RP badge' style={{ height: '16px', width: '16px' }} />
            <div className='large__label2__bold' style={{ color: '#4839CD' }}>Rank Points</div>
        </div>

        <div className='d-flex flex-col' style={{ gap: '6px' }}>
            <div className='d-flex gap1'>
                <div className='d-flex align-center justify-center large__caption3__bold' style={{ height: '16px', minWidth: '16px', color: '#7364EC' }}>1</div>
                <div className='large__caption3__regular' style={{ color: '#696F79' }}>Rank points are accumulated as students interact with the platform and can be earned from various sources.</div>
            </div>
            <div className='d-flex gap1'>
                <div className='d-flex align-center justify-center large__caption3__bold' style={{ height: '16px', minWidth: '16px', color: '#7364EC' }}>2</div>
                <div className='large__caption3__regular' style={{ color: '#696F79' }}>
                    These points are collected by watching
                    <strong>Interactive Learning Modules (ILMs)</strong>,
                    completing <strong>Daily Quests</strong>,
                    participating in <strong>Interactive Assessments (IAs)</strong>,
                    approval of <strong>Projects</strong>, and from achieving new <strong>Learning Streaks</strong>.
                </div>
            </div>
        </div>
    </TooltipButton>
}

export default DashboardTooltipContentCard;