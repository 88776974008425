import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLoading } from '../../redux/global-slice';
import { getWatchHistoryILMs } from '../../service/favourite-service';
import { useNavigate } from "react-router-dom";
import LinearProgress from "../progress/linear-progress";
import FavouriteILMButton from "../favourite-ilm-button";
import { CLASSES_PATH, CLASSROOM_PATH } from "../../utils/route-constants";
import UnlockIcon from '../../assets/unlock-icon.png';

const RecentlyViewedCourses = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const getRecentCourses = () => {
        dispatch(updateLoading(true));
        getWatchHistoryILMs('home')
            .then(res => setData(res))
            .finally(() => dispatch(updateLoading(false)));
    }
    useEffect(() => { getRecentCourses() }, []);

    return data.length > 0 && <div className="d-flex flex-col gap4">
        <div className="d-flex flex-col gap1">
            <div className="large__header3__bold" style={{ color: '#3A2CB8' }}>Recently Viewed</div>
            <div className="large__header5__regular" style={{ color: '#4839CD' }}>Quicky get back to what you were previously watching</div>
        </div>
        {/* TODO: schollbar should be hidden */}
        {<div className="d-flex align-center gap6 p2 hide__scrollbar" style={{ flexWrap: 'nowrap' }}>
            {
                data.map(ilm => <div
                    key={ilm['id']}
                    className="bg-surface-element radius2 elevation3 d-flex flex-col p2 gap3 animate"
                    style={{ minWidth: '342px', maxWidth: '342px', overflow: 'hidden', cursor: 'pointer' }}
                    onClick={e => {
                        e.stopPropagation();
                        navigate(`/${CLASSROOM_PATH}/${ilm['ilm_id']}/${CLASSES_PATH}`)
                    }}
                >
                    <div
                        className="radius2"
                        style={{
                            aspectRatio: '16 / 9',
                            width: '100%',
                            position: 'relative',
                            overflow: 'hidden',
                            // background: 'linear-gradient(132.44deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 99.71%)',
                            // boxShadow: '-2.05px - 2.05px 1.54px 0px #92929240 inset, 4.1px 4.1px 3.59px 0px #92929240 inset, 2.05px 2.05px 1.54px 0px #FFFFFF4D inset',
                        }}
                    >
                        <img src={ilm['thumbnail']} alt={ilm['name']} />
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                borderRadius: '8px',
                                background: 'linear-gradient(132.44deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, .02) 99.71%)',
                                boxShadow: '-2.05px -2.05px 1.54px 0px #92929240 inset, 4.1px 4.1px 3.59px 0px #92929240 inset,2.05px 2.05px 1.54px 0px #FFFFFF80 inset',
                            }}
                        />
                        <div
                            className='px3 py2 d-flex'
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                // TODO: blur backdrop
                                background: 'linear-gradient(0deg, #502693 0%, rgba(80, 38, 147, 0.78153) 28.65%, rgba(80, 38, 147, 0.599732) 61.58%, rgba(80, 38, 147, 0.312445) 80.46%, rgba(80, 38, 147, 0.191304) 91.15%, rgba(80, 38, 147, 0) 100%)',
                            }}
                        >
                            <div className="fl1 text__elipsis" style={{ fontFamily: 'Manrope', fontSize: '17.42px', fontWeight: '600', color: '#FBE3B6', WebkitLineClamp: 1 }}>{ilm['name']}</div>
                            <FavouriteILMButton
                                isFavourites={ilm['is_favorites'] === '1'}
                                skillId={ilm['skill_id']}
                                themeId={ilm['theme_id']}
                                ilmId={ilm['ilm_id']}
                                callback={getRecentCourses}
                            />
                        </div>
                        {/* <div
                            className='d-flex align-center justify-center'
                            style={{ background: '#FC63F680', height: '32px', width: '32px', position: 'absolute', top: 0, right: 0, borderBottomLeftRadius: '8px' }}>
                            {ilm['is_unlocked'] === '1' ? <LockOpen color='white' size={20} /> : <Lock color='white' size={20} />}
                        </div> */}
                        {ilm['is_unlocked'] === '1' && <img
                            src={UnlockIcon} alt='ILM unlock icon'
                            style={{ height: '32px', width: '32px', position: 'absolute', top: 0, right: 0 }}
                        />}
                    </div>
                    <LinearProgress percent={ilm['progress_percentage'] !== null ? Number(ilm['progress_percentage']) : 0} />
                </div>)
            }

        </div>}
    </div>
}

export default RecentlyViewedCourses;