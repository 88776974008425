import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer
} from "react-router-dom";

import AuthLayout from './componets/layout/auth-layout';
import PublicLayout from './componets/layout/public-layout';
import ProtectedLayout from './componets/layout/protected-layout';
import Login from './pages/auth/login';
import ForgotPassword from './pages/auth/forgot-password';
import SetNewPassword from './pages/auth/set-new-password';
import ModeSelection from './pages/auth/mode-selection';
import Home from './pages/home';
import Events from './pages/event/events';
import UpcomingEvents from "./pages/event/upcoming-events";
import EnrolledEvents from "./pages/event/enrolled-events";
import RecordingsEvents from "./pages/event/recording-events";
import EventDetail from "./pages/event/event-detail";
import EventPreview from "./pages/event/event-preview";
import Dashboard from "./pages/dashboard/dashboard";
import GradeDashboard from './pages/dashboard/grade/grade-dashboard';
import SchoolDashboard from './pages/dashboard/school/school-dashboard';
import DashboardOverview from "./pages/dashboard/overview";
import GradeLoginDetails from './pages/dashboard/grade/grade-login-details';
import StudentReport from './pages/dashboard/grade/students-report/student-report';
import SkillWiseReport from './pages/dashboard/grade/students-report/skill-wise-report';
import StudentWiseReport from './pages/dashboard/grade/students-report/student-wise-report';
import UserTypeSummary from './pages/dashboard/grade/user-type-summary';
import SkillCompletion from './pages/dashboard/grade/skills-completion';
import PopularIlms from './pages/dashboard/grade/popular-ilms';
import PerformanceTrend from './pages/dashboard/grade/performance-trend';
import UserTrend from './pages/dashboard/grade/user-trend';
import DashboardEvents from './pages/dashboard/grade/dashboard-events';
import SchoolLoginDetails from './pages/dashboard/school/school-login-details';
import TopPerfomers from './pages/dashboard/school/top-perfomers';
import UsageSummary from './pages/dashboard/school/usage-summary';
import UserStats from './pages/dashboard/school/user-stats';
import SelectedSkills from './pages/dashboard/school/school-skills/seleted-skills';
import SchoolOptedSkills from './pages/dashboard/school/school-skills/school-opted-skills';
import StudentEnrolledSkills from './pages/dashboard/school/school-skills/student-enrolled-skills';
import Projects from './pages/projects/projects';
import PendingProjects from './pages/projects/pending-projects';
import ApprovedProjects from './pages/projects/approved-projects';
import RejectedProjects from './pages/projects/rejected-projects';
import CertificationProgram from './pages/certification-program';
import SkillProgramIlms from './pages/skill-programs-ilms';
import ConductSession from './pages/conduct-session';
import IlmJourney from './pages/ilm-journey';
import Profile from './pages/profile/profile';
import EditProfile from './pages/profile/edit-profile';
import WatchHistory from './pages/watch-history';
import Favourites from './pages/favourites';
import SupportVideos from './pages/support-videos';
import TicketList from './pages/ticket/ticket-list';
import RaiseTicket from './pages/ticket/raise-ticket';
import PageNotFound from './pages/page-not-found';
import Classroom from './pages/classroom/classroom';
import ClassesList from './pages/classroom/classes-list';
import DailyQuest from './pages/classroom/daily-quest';
import {
  APPROVED_PROJECTS_PATH,
  AUTH_PATH,
  CERTIFICATION_PROGRAM_PATH,
  CLASSES_PATH,
  CLASSROOM_PATH,
  CONDUCT_SESSION_PATH,
  DAILYQUEST_PATH,
  DASHBOARD_PATH,
  EDIT_PROFILE_PATH,
  ENROLLED_EVENTS_PATH,
  EVENTS_PATH,
  FAVOURITES_PATH,
  FORGOT_PASSWORD_PATH,
  GRADE_PATH,
  LOGIN_DETAIL_PATH,
  LOGIN_PATH,
  OVERVIEW_PATH,
  PENDING_PROJECTS_PATH,
  PERFORMANCE_TREND_PATH,
  POPULAR_ILMS_PATH,
  PROFILE_PATH,
  PROJECTS_PATH,
  RAISE_TICKET_PATH,
  RECORDING_EVENTS_PATH,
  REJECTED_PROJECTS_PATH,
  ROOT_PATH,
  SCHOOL_OPTED_SKILLS_PATH,
  SCHOOL_PATH,
  SELECTED_SKILLS_PATH,
  SELECT_MODE_PATH,
  SET_NEW_PASSWORD_PATH,
  SKILLS_COMPLETION_PATH,
  SKILL_PROGRAM_ILMS_PATH,
  SKILL_WISE_REPORT_PATH,
  STUDENTS_REPORT_PATH,
  STUDENT_ENROLLED_SKILLS_PATH,
  STUDENT_WISE_REPORT_PATH,
  SUPPORT_PATH,
  SUPPORT_VIDEOS_PATH,
  TOP_PERFOMERS_PATH,
  UPCOMING_EVENTS_PATH,
  USAGE_SUMMARY_PATH,
  USER_STATS_PATH,
  USER_TREND_PATH,
  USER_TYPE_SUMMARY_PATH,
  WATCH_HISTORY_PATH
} from './utils/route-constants';
import { getAccessTokenService } from './service/auth-service';
import { getClassroomDailyQuest, getClassroommDetails, getJourneyILMS } from "./service/ilm-service";
import { getIndividualEventDetailService } from "./service/event-service";
import { getFaqsListAPI, getTutorialDetilAPI } from "./service/support-service";
import store from './redux/store';
import { updateSelectedGrade, updateSelectedSection } from "./redux/global-slice";

const getUserData = () => new Promise((resolve) =>
  getAccessTokenService().then(token => {
    if (typeof token === 'string') {
      window.localStorage.setItem('accessToken', token)
      resolve(token)
    } else {
      resolve(null);
    }
  }).catch((e) => resolve(null))
);

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} loader={() => defer({ token: getUserData() })}>
      <Route path={AUTH_PATH} element={<PublicLayout />}>
        <Route index path={LOGIN_PATH} element={<Login />} />
        <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
        <Route path={SET_NEW_PASSWORD_PATH} element={<SetNewPassword />} />
      </Route>
      <Route path={ROOT_PATH} element={<ProtectedLayout />} >
        <Route path={SELECT_MODE_PATH} element={<ModeSelection />} />
        <Route index element={<Home />} />
        <Route path={`${EVENTS_PATH}/*`} element={<Events />}>
          <Route index path={UPCOMING_EVENTS_PATH} element={<UpcomingEvents />} />
          <Route path={ENROLLED_EVENTS_PATH} element={<EnrolledEvents />} />
          <Route path={RECORDING_EVENTS_PATH} element={<RecordingsEvents />} />
        </Route>
        <Route
          path={`${EVENTS_PATH}/:eventId`}
          element={<EventDetail />}
          errorElement={<PageNotFound />}
          loader={param => {
            if (param.params !== null && param.params['eventId'] !== null) {
              return getIndividualEventDetailService(param.params['eventId']);
            }
            return null;
          }}
        />
        <Route
          path={`${EVENTS_PATH}/:eventId/view`}
          element={<EventPreview />}
          errorElement={<PageNotFound />}
          loader={param => {
            if (param.params !== null && param.params['eventId'] !== null) {
              return getIndividualEventDetailService(param.params['eventId']);
            }
            return null;
          }}
        />
        <Route path={`${DASHBOARD_PATH}/*`} element={<Dashboard />}>
          <Route path={`${GRADE_PATH}/*`} element={<GradeDashboard />}>
            <Route index path={OVERVIEW_PATH} element={<DashboardOverview />} />
            <Route path={LOGIN_DETAIL_PATH} element={<GradeLoginDetails />} />
            <Route path={`${STUDENTS_REPORT_PATH}/*`} element={<StudentReport />}>
              <Route index path={SKILL_WISE_REPORT_PATH} element={<SkillWiseReport />} />
              <Route path={STUDENT_WISE_REPORT_PATH} element={<StudentWiseReport />} />
            </Route>
            <Route path={USER_TYPE_SUMMARY_PATH} element={<UserTypeSummary />} />
            <Route path={SKILLS_COMPLETION_PATH} element={<SkillCompletion />} />
            <Route path={POPULAR_ILMS_PATH} element={<PopularIlms />} />
            <Route path={PERFORMANCE_TREND_PATH} element={<PerformanceTrend />} />
            <Route path={USER_TREND_PATH} element={<UserTrend />} />
            <Route path={EVENTS_PATH} element={<DashboardEvents />} />
          </Route>
          <Route path={`${SCHOOL_PATH}/*`} element={<SchoolDashboard />}>
            <Route index path={OVERVIEW_PATH} element={<DashboardOverview />} />
            <Route path={LOGIN_DETAIL_PATH} element={<SchoolLoginDetails />} />
            <Route path={TOP_PERFOMERS_PATH} element={<TopPerfomers />} />
            <Route path={USAGE_SUMMARY_PATH} element={<UsageSummary />} />
            <Route path={USER_STATS_PATH} element={<UserStats />} />
            <Route path={`${SELECTED_SKILLS_PATH}/*`} element={<SelectedSkills />}>
              <Route index path={SCHOOL_OPTED_SKILLS_PATH} element={<SchoolOptedSkills />} />
              <Route path={STUDENT_ENROLLED_SKILLS_PATH} element={<StudentEnrolledSkills />} />
            </Route>
          </Route>
        </Route>
        <Route 
          path={`${PROJECTS_PATH}/`} element={<Projects />}
          loader={ async () => {
            store.dispatch(updateSelectedGrade(''));
            store.dispatch(updateSelectedSection(''));
            return new Promise(resolve => setTimeout(() => resolve(true), 250));
          }}
        >
          <Route index path={PENDING_PROJECTS_PATH} element={<PendingProjects />} />
          <Route path={APPROVED_PROJECTS_PATH} element={<ApprovedProjects />} />
          <Route path={REJECTED_PROJECTS_PATH} element={<RejectedProjects />} />
        </Route>
        <Route path={CERTIFICATION_PROGRAM_PATH} element={<CertificationProgram />} />
        <Route path={SKILL_PROGRAM_ILMS_PATH} element={<SkillProgramIlms />} />
        <Route path={CONDUCT_SESSION_PATH} element={<ConductSession />} />
        <Route
          path={`${CONDUCT_SESSION_PATH}/:ilmId`}
          element={<IlmJourney />}
          loader={param => {
            if (param.params !== null && param.params['ilmId'] !== null) {
              const state = store.getState();
              const grade = state.global.selectedGrade;
              const section = state.global.selectedSection;
              return getJourneyILMS(param.params['ilmId'], section, grade);
            }
            return null;
          }}
        />
        <Route path={PROFILE_PATH} element={<Profile />} />
        <Route path={EDIT_PROFILE_PATH} element={<EditProfile />} />
        <Route path={WATCH_HISTORY_PATH} element={<WatchHistory />} />
        <Route path={FAVOURITES_PATH} element={<Favourites />} />
        <Route
          path={SUPPORT_VIDEOS_PATH}
          element={<SupportVideos />}
          loader={async () => {
            try {
              var faqs = await getFaqsListAPI();
              var tutorial = await getTutorialDetilAPI();
              return {
                faqs: faqs,
                tutorial: tutorial
              }
            } catch (error) {
              console.log(error);
            }
          }}
        />
        <Route path={SUPPORT_PATH} element={<TicketList />} />
        <Route path={SUPPORT_PATH + '/' + RAISE_TICKET_PATH} element={<RaiseTicket />} />
        <Route path={`${CLASSROOM_PATH}/:id`} element={<Classroom />}>
          <Route
            index path={CLASSES_PATH}
            element={<ClassesList />}
            errorElement={<PageNotFound />}
            loader={param => {
              if (param.params !== null && 'id' in param.params) {
                const state = store.getState();
                const grade = state.global.selectedGrade;
                const section = state.global.selectedSection;
                return getClassroommDetails(param.params['id'], grade, section);
              }
              return null;
            }}
          />
          <Route
            path={DAILYQUEST_PATH}
            element={<DailyQuest />}
            errorElement={<PageNotFound />}
            loader={param => {
              if (param.params !== null && 'id' in param.params) {
                return getClassroomDailyQuest(param.params['id']);
              }
              return null;
            }}
          />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound height={100} />} />
    </Route>
  )
);

export { appRouter };
