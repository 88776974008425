import { ACCESS_TOKEN_API, apiRequestOptions, LOGIN_API, SCHOOL_SKILLS_LIST_API, UPDATE_USER_DETAIL_API, USER_DETAIL_API } from '../utils/api-constants';

// get access token
export async function getAccessTokenService() {
    return new Promise((resolve, reject) => {
        var options = apiRequestOptions({});
        fetch(ACCESS_TOKEN_API, options)
            .then(res => res.json())
            .then(json => resolve(json['data']))
            .catch(e => reject(e));
    });
}

// login user using username & password 
export async function loginService(username, password) {
    try {
        const options = apiRequestOptions({
            username: username,
            password: password,
        });
        return await fetch(LOGIN_API, options)
            .then(res => res.json())
            .then(json => json)
            .catch(e => { throw new Error(e) });
    } catch (e) {
        console.error(e)
    }
}

// get user details
export async function getUserDetail(userId) {
    try {
        const options = apiRequestOptions({ user_id: userId });
        return await fetch(USER_DETAIL_API, options)
            .then(res => res.json())
            .then(json => json['details'])
            .catch(e => { throw new Error(e) });
    } catch (e) {
        console.error(e)
    }
}

// update user detail 
export async function updateUserDetailAPI(dob, gender, country, city, fullname, email, phone) {
    const userId = window.localStorage.getItem('userId');
    try {
        const options = apiRequestOptions({
            user_id: userId,
            dob: dob,
            gender: gender,
            country: country,
            city: city,
            fullname: fullname,
            email: email,
            phone: phone,
        });
        return await fetch(UPDATE_USER_DETAIL_API, options)
            .then(res => res.json())
            .then(json => json)
            .catch(e => { throw new Error(e) });
    } catch (e) {
        console.error(e)
    }
}

// get list of all skills assigned to school
export async function getListOfSkillsAssignedToSchoolService(id) {
    try {
        const options = apiRequestOptions({ user_id: id });
        return await fetch(SCHOOL_SKILLS_LIST_API, options)
            .then(res => res.json())
            .then(json => json['details'])
            .catch(e => { throw new Error(e) });
    } catch (e) {
        console.error(e)
    }
}