import { useState } from 'react';
import EmailInput from '../input/email-input';
import PhoneInput from '../input/phone-input';
import TextareaInput from '../input/textarea-input';

const ContactusCard = () => {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    return <div className='d-flex flex-col justify-between' style={{ height: '100%', width: '100%' }}>
        <div className='d-flex flex-col gap10'>
            <div>
                <div className='large__header1'>Contact Us</div>
                <div className='large__header6__regular'>Choose your registered email or phone number</div>
            </div>
            <div className='d-flex flex-col gap4'>
                <PhoneInput label='Registered Email ID' phone={phone} setPhone={setPhone} />
                <EmailInput label='Email Address' email={email} setEmail={setEmail} />
                <TextareaInput placeholder='Write your concern' value={message} setValue={setMessage} />
            </div>
        </div>
        <button className='primary'>Submit</button>
    </div>
}

export default ContactusCard;