import { useEffect, useState } from 'react';
import ConductSessionList from '../componets/consuct-session/conduct-session-list';
import SelectGrade from '../componets/input/select-grade';
import SelectSection from '../componets/input/select-section';
import { getConductSessionILMs } from '../service/ilm-service';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoading } from '../redux/global-slice';
import { selectedGradeReducer, selectedSectionReducer } from '../redux/reducers';

// gradees & section assigned to techer will be in select
const ConductSession = () => {
    const grade = useSelector(selectedGradeReducer);
    const section = useSelector(selectedSectionReducer);
    const [lists, setLists] = useState([]);
    const disabled = grade.length === 0 || section.length === 0;
    const dispatch = useDispatch();

    const getList = () => {
        if (grade.length > 0 && section.length > 0) {
            dispatch(updateLoading(true))
            getConductSessionILMs(grade, section)
                .then(res => setLists(res))
                .finally(() => dispatch(updateLoading(false)));
        }
    }

    useEffect(() => { getList() }, [grade, section]);

    return <div className='d-flex flex-col gap6' style={{ width: '100%' }}>
        <div>
            <div className="large__header3__bold mb1">Conduct Session</div>
            <div className="large__header5__regular">Select a grade and skill to conduct a session</div>
        </div>
        <div className="d-flex align-end gap6" style={{ maxWidth: '900px' }}>
            <SelectGrade />
            <SelectSection />
            <button className='primary neo' style={{ minWidth: '224px' }} onClick={() => getList()} disabled={disabled}>View Skill Programs</button>
        </div>
        <ConductSessionList lists={lists} selected={grade.length > 0 && section.length > 0} />
    </div>

}

export default ConductSession;