import ProjectSubmissionDate from './project-submission-date';

const _pending = 'radial-gradient(100% 121.84% at 0% 12.42%, #FEF3CA 0%, #FFFFFF 100%)';
const _approved = 'radial-gradient(100% 121.84% at 0% 12.42%, #BCFCCA 0%, #FFFFFF 100%)';
const _rejected = 'radial-gradient(100% 121.84% at 0% 12.42%, #FDD2D2 0%, #FFFFFF 100%)'

// type: pending is 0, approved is 1, rejected is 2
const ProjectCard = ({ top, item, type, selected, setSelectedProject, updateLists, lists = [] }) => {
    const isRejected = lists.filter(e => e['id'] === item['id'] && e['status'] === 2).length > 0;
    const isApproved = lists.filter(e => e['id'] === item['id'] && e['status'] === 1).length > 0;

    return <div
        className={`p3 d-flex align-start gap3 ${selected?.id === item.id && 'elevation3'} project${item.id}`}
        style={{
            cursor: 'pointer',
            borderRadius: '10px',
            background: selected?.id === item.id ? type === 0 ? _pending : type === 1 ? _approved : _rejected : 'none',
            position: item.id === selected?.id && top >= 0 ? 'sticky' : 'relative',
            top: 0,
            zIndex: item.id === selected?.id && top >= 0 ? 1 : 0,
        }}
        onClick={() => setSelectedProject(item)}
    >
        <div className='fl1 d-flex flex-col gap2'>
            <div className='large__header6__bold' style={{ color: '#3E444F' }}>{item['student_name']}</div>
            {
                type === 0 ?
                    <ProjectSubmissionDate date={item['created']} /> :
                    <div className='d-flex gap8'>
                        <div className='d-flex flex-col' style={{ gap: '2px', minWidth: '232px' }}>
                            <div className='large__label2__regular' style={{ color: '#696F79' }}>Skill Program Name</div>
                            <div className='large__body__bold' style={{ color: '#594BDD' }}>{item['program_name']}</div>
                        </div>
                        <div className='fl1 d-flex flex-col' style={{ gap: '2px' }}>
                            <div className='large__label2__regular' style={{ color: '#696F79' }}>ILM Name</div>
                            <div className='large__body__bold text__elipsis' style={{ color: '#594BDD', WebkitLineClamp: 1 }}>{item['ilm_name'] ?? item['title']}</div>
                        </div>
                    </div>
            }
        </div>

        {type === 0 ? <div className='d-flex flex-col gap3'>
            <div className='d-flex align-center jusify-center' style={{ gap: '10px', cursor: 'pointer' }} onClick={e => {
                e.stopPropagation();
                updateLists(item, 1);
            }}>
                <div style={{ height: '24px', width: '24px', padding: '5px' }} className={`radius3 bg-surface-element ${isApproved ? 'depth3' : 'elevation2'}`}>
                    <div style={{ height: '100%', width: '100%', borderRadius: '50%', background: `${isApproved ? '#5DDA5D' : '#C5C7CB'}` }} />
                </div>
                <div className='large__body__regular' style={{ color: '#696F79' }}>Approve Project</div>
            </div>
            <div className='d-flex align-center jusify-center' style={{ gap: '10px', cursor: 'pointer' }} onClick={e => {
                e.stopPropagation();
                updateLists(item, 2);
            }}>
                <div style={{ height: '24px', width: '24px', padding: '5px' }} className={`radius3 bg-surface-element ${isRejected ? 'depth3' : 'elevation2'}`}>
                    <div style={{ height: '100%', width: '100%', borderRadius: '50%', background: `${isRejected ? '#FF7448' : '#C5C7CB'}` }} />
                </div>
                <div className='large__body__regular' style={{ color: '#696F79' }}>Reject Project</div>
            </div>
        </div> : <ProjectSubmissionDate date={item['created']} />}
    </div>
}

export default ProjectCard;

