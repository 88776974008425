import { X } from "lucide-react";
import useDashboardData from "../../hooks/use-dashboard-data";
import topPerofmersDialogTableBG from "../../assets/dashboard/top-perofmers-dialog-table-bg.png";
import { downloadPDF } from "../../utils/utils";
import { getParsedGrade } from "../../utils/constants";
import { TOP_PERFOMERS_API } from "../../utils/api-constants";

const TopPerfomersViewMore = ({ closeDialog }) => {
    const { data, loading, userDetail, grade, section } = useDashboardData(TOP_PERFOMERS_API);
    return <div className='d-flex align-center justify-center view__more__table' onClick={() => closeDialog(false)}>
        <div style={{ background: 'var(--surfaceELementWhite)', position: 'relative' }} onClick={e => e.stopPropagation()}>
            <div className='d-flex flex-col align-center gap8 view__more__content'>
                <div className='large__header3__bold'>Top Perfomers</div>
                <div
                    style={{
                        flex: 1,
                        width: '100%',
                        borderRadius: '8px',
                        boxShadow: 'var(--depth3)',
                        padding: '12px',
                        background: `url(${topPerofmersDialogTableBG})`,
                        overflowY: 'scroll',
                    }}
                    className='d-flex flex-col gap3'
                >
                    <div className='d-flex align-center justify-between py1 px4 view__more__header'                    >
                        <div className='large__body__bold'>{userDetail['school_name']}</div>
                        <div className='d-flex align-center justify-center gap4'>
                            <div className='d-flex gap3'>
                                <div className='large__body__regular' style={{ color: '#696F79' }}>Grade</div>
                                <div className='large__body__bold' style={{ color: '#3E444F' }}>{getParsedGrade(grade)}</div>
                            </div>
                            <div className='d-flex gap3'>
                                <div className='large__body__regular' style={{ color: '#696F79' }}>Section</div>
                                <div className='large__body__bold' style={{ color: '#3E444F' }}>{section}</div>
                            </div>
                        </div>
                    </div>
                    <div className='view__more__header d-flex align-center table__header'>
                        <div style={{ width: '100px' }}>Rank</div>
                        <div className='fl1' style={{ borderLeft: '1px solid #D0CBFD', borderRight: '1px solid #D0CBFD' }}>Student Name</div>
                        <div style={{ width: '134px' }}>Rank Point</div>
                    </div>

                    <div className='fl1 d-flex' style={{ overflowY: 'scroll', width: '100%', border: '1px solid #D0CBFD', borderRadius: '10px', position: 'relative' }}>
                        {
                            loading ? <div
                                style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rbga(0, 0, 0, .4)' }}
                                className='d-flex align-center justify-center'
                            ><div className='mini__loader' /></div> :
                                data.length > 0 ?
                                    <table id='viewmore'>
                                        <tbody>
                                            {data.map((e, i) => <tr key={i}>
                                                <td style={{ width: '100px' }}>{e['rank_no']}</td>
                                                <td>{e['fullname']}</td>
                                                <td style={{ width: '134px' }}>{e['points']}</td>
                                            </tr>)}
                                        </tbody>
                                    </table> :
                                    <div className='d-flex justify-center align-center' style={{ width: '100%' }}>Inadequate Product Usage to Display Data</div>
                        }
                    </div>
                </div>
                <div className='d-flex gap6' style={{ width: '100%' }}>
                    <button className='fl1 secondary' onClick={() => closeDialog(false)}>Go Back</button>
                    <button className='fl1 primary' onClick={() => downloadPDF('viewmore')}>Download Data</button>
                </div>
            </div>
            {/* close button */}
            <div
                style={{
                    position: 'absolute',
                    top: '24px',
                    right: '32px',
                    height: '32px',
                    width: '32px',
                    borderRadius: '4px',
                    background: 'var(--surfaceELementWhite)',
                    boxShadow: 'var(--elevation3)',
                    cursor: 'pointer'
                }}
                className='d-flex align-center justify-center'
                onClick={() => closeDialog(false)}
            >
                <X size={20} color='#545F71' />
            </div>
        </div>
    </div >
}

export default TopPerfomersViewMore;


