import { ChevronLeft } from "lucide-react";

const BlurActionMenuItem = ({ title, onClick }) => {
    return <div
        className='px5 py3 elevation2 d-flex align-center gap2'
        style={{
            minWidth: '226px',
            cursor: 'pointer',
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px',
            background: 'linear-gradient(132deg, #F7F7F8 -2.9%, #F4F4F6 102.91%)',
        }}
        onClick={onClick}
    >
        <ChevronLeft color='#6F61E9' />
        <span className='large__header6__bold' style={{ color: '#6F61E9' }}>{title}</span>
    </div>
}

export default BlurActionMenuItem;