import { createSlice } from '@reduxjs/toolkit'

export const eventSlice = createSlice({
    name: 'events',
    initialState: {
        upcoming: [], // TYPE 1
        enrolled: [], // TYPE 3
        recordings: [], // TYPE 4
    },
    reducers: {
        setEventsList: (state, action) => {
            if (String(action.payload.type) === "1") {
                state.upcoming = action.payload.data;
            } else if (String(action.payload.type) === "3") {
                state.enrolled = action.payload.data;
            } else {
                state.recordings = action.payload.data;
            }
        },
    },
});

export const upcomingEvents = (state) => state.events.upcoming;
export const enrolledEvents = (state) => state.events.enrolled;
export const recordingsEvents = (state) => state.events.recordings;

export const { setEventsList } = eventSlice.actions

export default eventSlice.reducer;


