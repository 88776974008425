import TableListData from './table-list-data';
import TableHeaderCard from './table-header-card';

const DashboardTableList = ({
    children,
    error = null,
    loading = false,
    title,
    headers,
    footer = [],
    lists,
    download = true,
    dateRange = false,
    month = false,
    grade = false,
    event = false,
    schoolToppers = false,
    tooltip,
}) => {
    return <div className='dashboard__table__list d-flex flex-col gap4'>
        {title && <TableHeaderCard
            title={title}
            download={download}
            month={month}
            dateRange={dateRange}
            grade={grade}
            event={event}
            tooltip={tooltip}
        />}
        <TableListData
            schoolToppers={schoolToppers}
            headers={headers}
            footer={footer}
            lists={lists}
            loading={loading}
            error={error}
        >{children}</TableListData>
    </div>
}

export default DashboardTableList;