import { ChevronDown } from "lucide-react";

// 1 is male, 2 is female
const SelectGender = ({ value, setValue, disabled = false }) => {
    const options = [
        {
            title: "Male",
            value: "1",
        },
        {
            title: "Female",
            value: "2",
        }
    ];
    return (
        <div className="input__container">
            <label htmlFor="gender">Select Gender</label>
            <select onChange={(e) => setValue(e.target.value)} id="gender" value={value} disabled={disabled}>
                {options.map(option =>
                    <option key={option.value} value={option.value}>
                        {option.title}
                    </option>
                )}
            </select>
            <ChevronDown color='#3F3782' />
        </div>
    );
}

export default SelectGender;