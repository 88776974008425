const DateInput = ({ value, setValue, disabled = false }) => {
    return <div className="input__container">
        <label htmlFor="date">Date of Birth</label>
        <input
            type="date"
            id="date"
            name="date"
            placeholder="Date of Birth"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
        />
    </div>
}

export default DateInput;