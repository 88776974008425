import { Users } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CONDUCT_SESSION_PATH } from "../../utils/route-constants";

const ConductSessionButton = () => {
    const navigate = useNavigate();
    return <div className="bg-surface-element radius4 p3 elevation4 d-flex justify-between">
        <div className="d-flex gap6">
            <div style={{ height: '48px', width: '48px', borderRadius: '8.73px' }} className="depth3 d-flex justify-center align-center">
                <Users size={30} color="#7364EC" />
            </div>
            <div>
                <div className="large__header6__bold" style={{ color: '#3E444F' }}>Conduct Session</div>
                <div className="large__header6__regular" style={{ color: '#696F79' }}>Choose the class and conduct a session from here.</div>
            </div>
        </div>
        <button
            className="primary medium neo"
            style={{ width: '400px' }}
            onClick={() => navigate(CONDUCT_SESSION_PATH)}
        >Conduct Session</button>
    </div>;
}
export default ConductSessionButton;