const CityInput = ({ value, setValue, disabled = false }) => {
    return <div className="input__container">
        <label htmlFor="city">City</label>
        <input
            type="text"
            id="city"
            name="city"
            placeholder="Enter City"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
        />
    </div>
}

export default CityInput;