import { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { monthsList } from '../../utils/constants';
import LoadingError from '../dashboard/loading-error';
import { PERFORMANCE_TREND_API, USER_TREND_API } from '../../utils/api-constants';
import useDashboardData from '../../hooks/use-dashboard-data';
import { useDispatch, useSelector } from 'react-redux';
import { updateDashboardSelectedYear, updateDashboardTrendsList } from '../../redux/dashboard-slice';
import { dashboardSelectedYearReducer } from '../../redux/reducers';

const LineChartCard = ({ perfomance = true, height = 500 }) => {
    const dispatch = useDispatch();
    const { loading, data, error } = useDashboardData(perfomance ? PERFORMANCE_TREND_API : USER_TREND_API);
    const [lists, setLists] = useState([]);
    const selectedYear = useSelector(dashboardSelectedYearReducer);

    useEffect(() => { dispatch(updateDashboardTrendsList(data)) }, [data]);

    useEffect(() => {
        const getData = () => {
            const x = data[selectedYear];
            const res = monthsList.map((m, j) => {
                const i = Number(j) + 1;
                const value = x[i];
                return {
                    name: m.short,
                    [!perfomance ? 'Active' : 'ILMs']: i in x ? _getValue(value, !perfomance ? 'active' : 'ilm') : 0,
                    [!perfomance ? 'Fairly Active' : 'IAs']: i in x ? _getValue(value, !perfomance ? 'fairly_active' : 'ia') : 0,
                    [!perfomance ? 'Inactive' : 'Projects']: i in x ? _getValue(value, !perfomance ? 'in_active' : 'project') : 0,
                }
            });
            setLists(res);
        }
        if (data !== null && typeof data === 'object' && !Array.isArray(data) && selectedYear !== null && selectedYear.length > 0) {
            getData();
        }
    }, [data, selectedYear, perfomance]);

    useEffect(() => {
        return () => {
            dispatch(updateDashboardTrendsList(null));
            dispatch(updateDashboardSelectedYear(''));
        }
    }, []);

    return (loading || error) ?
        <LoadingError loading={loading} error={error} height={height} /> :
        <ResponsiveContainer width="100%" height="100%">
            {
                Object.values(lists).every(e => e === 0) ?
                    <div className='d-flex align-center justify-center' style={{ height: height + 'px' }}>Inadequate Product Usage to Display Data</div> :
                    <LineChart width={500} height={300} data={lists}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey={!perfomance ? 'Active' : 'ILMs'} stroke={perfomance ? '#686EF5' : '#5DDA5D'} activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey={!perfomance ? 'Fairly Active' : 'IAs'} stroke={perfomance ? '#FF7448' : '#F4A71D'} activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey={!perfomance ? 'Inactive' : 'Projects'} stroke={perfomance ? '#EE7ACD' : '#FF7448'} activeDot={{ r: 8 }} />
                    </LineChart>
            }
        </ResponsiveContainer >
}

export default LineChartCard;

const _getValue = (obj, key) => {
    if (key in obj && (typeof obj[key] === 'number' || typeof obj[key] === 'string')) {
        return Number(obj[key]);
    }
    return 0;
}