import useDashboardData from "../../hooks/use-dashboard-data";
import { GET_LEARNING_PROGRESS_API } from "../../utils/api-constants";
import LoadingError from "./loading-error";

const LearningProgressCard = () => {
    const { loading, error, data } = useDashboardData(GET_LEARNING_PROGRESS_API);

    return <div className='dashboard__card animate'>
        <h6>Learning Progress</h6>
        {(loading || error) ?
            <LoadingError error={error} loading={loading} /> :
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '7px' }}>
                <div>
                    <span className='large__label1__regular' style={{ color: '#696F79' }}>ILMs Watched</span>
                    <p className='large__header6__bold'>{data['ilms_watched'] ?? '00'}</p>
                </div>
                <div>
                    <span className='large__label1__regular' style={{ color: '#696F79' }}>ILMs Completed</span>
                    <p className='large__header6__bold'>{data['ilm_completed'] ?? '00'}</p>
                </div>
                <div>
                    <span className='large__label1__regular' style={{ color: '#696F79' }}>IAs Watched</span>
                    <p className='large__header6__bold'>{data['ia_completed'] ?? '00'}</p>
                </div>
                <div>
                    <span className='large__label1__regular' style={{ color: '#696F79' }}>Projects Submitted</span>
                    <p className='large__header6__bold'>{data['proj_submitted'] ?? '00'}</p>
                </div>
                <div>
                    <span className='large__label1__regular' style={{ color: '#696F79' }}>Projects Approved</span>
                    <p className='large__header6__bold'>{data['proj_approved'] ?? '00'}</p>
                </div>
                <div>
                    <span className='large__label1__regular' style={{ color: '#696F79' }}>Skill Prog. Completed</span>
                    <p className='large__header6__bold'>{data['skills_completed'] ?? '00'}</p>
                </div>
            </div>
        }
    </div>
}

export default LearningProgressCard;