import { Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { CLASSES_PATH, CLASSROOM_PATH } from '../utils/route-constants';
import FavouriteILMButton from './favourite-ilm-button';

const SkillprogramsList = ({ lists = [], getList }) => {
    const navigate = useNavigate();
    return lists.length > 0 ? <div className="skill__programs__list d-flex align-end gap6">
        {lists.map((list, i) => {
            var exists = Number(list['class_exist']) > 0;
            return <div
                key={list.ilm_id}
                className="bg-surface-element p2 radius3 elevation3 animate"
                style={{ cursor: exists ? 'pointer' : 'not-allowed' }}
                onClick={e => {
                    e.stopPropagation();
                    if (exists) {
                        navigate(`/${CLASSROOM_PATH}/${list['ilm_id']}/${CLASSES_PATH}`, { state: false })
                    }
                }}
            >
                <div className="skill__programs__item__card__content radius2 pos-relative">
                    <img src={list.thumbnail} alt={list.name} />
                    {!exists && <div
                        className='d-flex align-center justify-center'
                        style={{
                            position: 'absolute', top: '0', left: '0', right: '0', bottom: '0',
                            background: 'radial-gradient(60.16% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 100%), radial-gradient(109.56% 74.57% at 50.14% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.24) 100%)',
                        }}
                    >
                        <div style={{ position: 'absolute', filter: 'blur(30px)', backgroundColor: '#000000', height: '54px', width: '54px' }} />
                        <Lock size={40} color='#FCC000' style={{ zIndex: 1000 }} />
                    </div>}
                    <div
                        className='large__header6__bold d-flex align-center justify-center'
                        style={{
                            position: 'absolute',
                            top: 0,
                            background: '#FC63F680',
                            height: '32px',
                            width: '32px',
                            borderBottomRightRadius: '8px',
                            color: 'white',
                        }}
                    >
                        {(i + 1).toString().padStart(2, '0')}
                    </div>
                    <div className="skill__programs__item__card__content__title large__body__bold d-flex">
                        <div className='fl1 large__body__bold text__elipsis' style={{ color: '#FBE3B6', WebkitLineClamp: 1 }}>{list.name}</div>
                        {exists && <FavouriteILMButton
                            isFavourites={list['is_favorites'] === '1'}
                            skillId={list['skill_id']}
                            themeId={list['theme_id']}
                            ilmId={list['ilm_id']}
                            callback={getList}
                        />}
                    </div>
                </div>
            </div>
        })}
    </div> : <div className='d-flex flex-col gap6' style={{ width: '100%' }}>
        <div className='d-flex gap6' style={{ width: '100%' }}>
            {_list1.map(i => <div
                key={i}
                className='p3 radius3 elevation3 bg-surface-element d-flex align-center justify-center large__header2 animate'
                style={{ height: '200px', width: '100%', color: '#A9ACB2' }}
            >{i}</div>)}
        </div>
        <div className='large__header5__bold d-flex align-center justify-center' style={{ color: '#7364EC' }}>Please select a Skill & Level to view the ILMs</div>
        <div className='d-flex gap6' style={{ width: '100%' }}>
            {_list2.map(i => <div
                key={i}
                className='p3 radius3 elevation3 bg-surface-element d-flex align-center justify-center large__header2 animate'
                style={{ height: '200px', width: '100%', color: '#A9ACB2' }}
            >{i}</div>)}
        </div>
    </div>
}

export default SkillprogramsList;

const _list1 = ['BOS', 'ILM1', 'ILM2', 'ILM3'];
const _list2 = ['ILM4', 'ILM5', 'Capstone', 'EOS'];