import LineChartCard from '../../../componets/charts/line-chart-card';
import LoginStatusIndicator from '../../../componets/dashboard/login-status-indicator';
import UserTrendTooltipContent from '../../../componets/dashboard/tooltip/user-trend-tooltip-content';
import SelectYear from '../../../componets/input/select-year';

const UserTrend = () => {
    return <div className='dashboard__card' style={{ height: '586px' }}>
        <div className='d-flex justify-between'>

            <div className='d-flex align-center justify-center gap2'>
                <h6>User Trend</h6>
                <UserTrendTooltipContent />
            </div>
            <SelectYear />
        </div>
        <div className='d-flex align-center justify-end gap10'>
            <LoginStatusIndicator status={1} title='Active' />
            <LoginStatusIndicator status={0} title='Fairly Active' />
            <LoginStatusIndicator status={-1} title='Inactive' />
        </div>
        <LineChartCard perfomance={false} />
    </div>
}

export default UserTrend;