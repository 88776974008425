import DashboardTableList from '../../../componets/dashboard/dashboard-list-table';
import { LOGIN_DETAILS_HEADER } from '../../../utils/constants';
import useDashboardData from '../../../hooks/use-dashboard-data';
import { GET_LOGIN_STATUS_DETAIL_API } from '../../../utils/api-constants';

const GradeLoginDetails = () => {
    const { loading, error, data } = useDashboardData(GET_LOGIN_STATUS_DETAIL_API);
    return <DashboardTableList
        error={error}
        loading={loading}
        title='Log In Detail'
        headers={LOGIN_DETAILS_HEADER}
        lists={data}
    />
}

export default GradeLoginDetails;