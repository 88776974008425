import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";

const PasswordInput = ({ label = 'Password', password, setPassword }) => {
    const [show, setShow] = useState(false);
    return (
        <div className="input__container">
            <label htmlFor="password">{label}</label>
            <input
                type={show ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div style={{ position: 'absolute', right: '12px', bottom: '4px', cursor: 'pointer' }} onClick={() => setShow(!show)}>
                {!show ? <Eye size={20} color='#82868E' /> : <EyeOff size={20} color='#82868E' />}
            </div>
        </div>
    );
}

export default PasswordInput;