import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLesson, updateWatchedClasses } from "../../redux/lesson-slice";
import {
  updateLessonProgress,
  updateScormProgress,
} from "../../service/ilm-service";
import {
  classDetailsReducer,
  isLevel0Reducer,
  conductSessionReducer,
  lessonsReducer,
  playingLessonReducer,
  watchedClassesReducer,
} from "../../redux/reducers";
import { lessonsToCallAPI } from "../../utils/constants";
import ScormPlayerCard from "../players/scorm-player-card";

const OtherLessonPlayer = ({ grade, section }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [scormData, setScormData] = useState(null);
  const conductSession = useSelector(conductSessionReducer);
  const detail = useSelector(classDetailsReducer);
  const lesson = useSelector(playingLessonReducer);
  const lessons = useSelector(lessonsReducer);
  const watchedClasses = useSelector(watchedClassesReducer);
  const isLevel0 = useSelector(isLevel0Reducer);

  // goto next lesson
  const gotoNextLesson = () => {
    setLoading(false);
    setScormData(null);
    var index = lessons.findIndex((e) => e["id"] === lesson["id"]);
    const nextIndex = index + 1;
    dispatch(updateWatchedClasses(lesson["id"]));
    dispatch(changeLesson(nextIndex));
  };

  // call scorm watch history & update scorm / goto next lesson
  useEffect(() => {
    if (scormData !== null) {
      // call the api for watch history api
      if (
        !watchedClasses.includes(lesson["id"]) &&
        !loading &&
        (isLevel0 ||
          (conductSession && lessonsToCallAPI.includes(lesson.classType)))
      ) {
        setLoading(true);
        updateLessonProgress(
          detail.skillType,
          lesson.themeId,
          lesson.skillId,
          lesson.ilmId,
          100,
          lesson.id,
          lesson.classType,
          lesson.fileType,
          1,
          detail.themeCategory,
          grade,
          section
        ).then(async () => {
          await updateScormProgress(
            lesson.classType,
            detail.skillType,
            lesson.skillId,
            lesson.themeId,
            lesson.ilmId,
            lesson.id,
            grade,
            section,
            scormData
          );
          gotoNextLesson();
        });
      } else {
        gotoNextLesson();
      }
    }
  }, [scormData]);

  // update scorm data
  const handleScormEvent = async (e) => {
    if (typeof e.data === "string") {
      const cleanedData = e.data.replace("!", "").replace(/^_/, "");
      try {
        const data = JSON.parse(cleanedData);
        var incomingScormStatus =
          data !== null &&
          "core" in data &&
          data["core"]["lesson_status"] === "completed";
        if (incomingScormStatus) {
          window.removeEventListener("message", handleScormEvent);
          setScormData(data);
        }
      } catch (error) {
        console.error("Error parsing SCORM data:", error);
      }
    }
  };

  // reset scorm data when lesson changed
  useEffect(() => {
    setLoading(false);
    setScormData(null);
  }, [lesson]);

  // remove listener while leaving the component
  useEffect(() => {
    return () => {
      setScormData(null);
    };
  }, []);

  return (
    <ScormPlayerCard
      scormKey={lesson.id}
      src={lesson.classSource}
      title={lesson.className}
      handleScormEvent={handleScormEvent}
    />
  );
};
export default OtherLessonPlayer;

/* import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLesson, updateWatchedClasses } from "../../redux/lesson-slice";
import { updateLessonProgress, updateScormProgress } from "../../service/ilm-service";
import { Maximize } from "lucide-react";
import { classDetailsReducer, isLevel0Reducer, conductSessionReducer, lessonsReducer, playingLessonReducer, watchedClassesReducer } from "../../redux/reducers";
import { lessonsToCallAPI } from "../../utils/constants";

const OtherLessonPlayer = ({ grade, section }) => {
    const iframeParent = useRef();
    const iframeILM = useRef();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [scormData, setScormData] = useState(null);
    const conductSession = useSelector(conductSessionReducer);
    const detail = useSelector(classDetailsReducer);
    const lesson = useSelector(playingLessonReducer);
    const lessons = useSelector(lessonsReducer);
    const watchedClasses = useSelector(watchedClassesReducer);
    const isLevel0 = useSelector(isLevel0Reducer);

    // goto next lesson
    const gotoNextLesson = () => {
        setLoading(false);
        setScormData(null);
        var index = lessons.findIndex(e => e['id'] === lesson['id']);
        const nextIndex = index + 1;
        dispatch(updateWatchedClasses(lesson['id']));
        dispatch(changeLesson(nextIndex));
    }

    // go to full screen
    const fullScreenFunction = () => {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            if (iframeParent.current.requestFullscreen) {
                iframeParent.current.requestFullscreen();
            } else if (iframeParent.current.mozRequestFullScreen) {
                iframeParent.current.mozRequestFullScreen();
            } else if (iframeParent.current.webkitRequestFullScreen) {
                iframeParent.current.webkitRequestFullScreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    };

    // call scorm watch history & update scorm / goto next lesson
    useEffect(() => {
        if (scormData !== null) {
            // call the api for watch history api
            if (!watchedClasses.includes(lesson['id']) && !loading && (isLevel0 || (conductSession && lessonsToCallAPI.includes(lesson.classType)))) {
                setLoading(true);
                updateLessonProgress(
                    detail.skillType,
                    lesson.themeId,
                    lesson.skillId,
                    lesson.ilmId,
                    100,
                    lesson.id,
                    lesson.classType,
                    lesson.fileType,
                    1,
                    detail.themeCategory,
                    grade,
                    section,
                ).then(_ => {
                    updateScormProgress(
                        detail.skillType,
                        lesson.skillId,
                        lesson.themeId,
                        lesson.ilmId,
                        lesson.id,
                        grade,
                        section,
                        scormData,
                    );
                }).finally(() => gotoNextLesson());
            } else {
                gotoNextLesson();
            }
        }
    }, [scormData]);

    // update scorm data
    const handleScormEvent = async (e) => {
        if (typeof e.data === "string") {
            const data = JSON.parse(e.data);
            var incomingScormStatus = data !== null && 'core' in data && data['core']['lesson_status'] === 'completed';
            if (incomingScormStatus) {
                window.removeEventListener('message', handleScormEvent);
                setScormData(data);
            }
        }
    }

    // reset scorm data when lesson changed
    useEffect(() => {
        setLoading(false);
        setScormData(null);
    }, [lesson]);

    // remove listener while leaving the component
    useEffect(() => {
        return () => {
            setScormData(null);
            window.removeEventListener('message', handleScormEvent);
        }
    }, []);

    return <div ref={iframeParent} style={{ position: 'relative', height: '100%', width: '100%' }}>
        {lesson.classSource !== null && <iframe
            title={lesson.id}
            src={lesson.classSource}
            onLoad={() => window.addEventListener('message', handleScormEvent)}
            ref={iframeILM}
        />}
        {lesson.classSource !== null && <div
            className='d-flex align-center justify-center radius1 p1'
            style={{ position: 'absolute', bottom: '16px', right: '16px', background: 'rgba(0, 0, 0, .4)', cursor: 'pointer' }}
            onClick={() => fullScreenFunction()}
        >
            <Maximize size={20} color="white" />
        </div>}

        {(lesson.classSource === null || lesson.classSource.length <= 0) && <div
            className='d-flex align-center justify-center'
            style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        >Coming Soon!</div>}
    </div>
}
export default OtherLessonPlayer;*/
