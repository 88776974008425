import TableHeaderCard from '../../../componets/dashboard/table-header-card';
import StackedChartCard from "../../../componets/charts/stacked-chart-card";
import LoginStatusIndicator from "../../../componets/dashboard/login-status-indicator";
import useDashboardData from '../../../hooks/use-dashboard-data';
import { SCHOOL_DASHBOARD_USER_STATS_API } from '../../../utils/api-constants';
import { useState } from 'react';

const UserStats = () => {
    const { loading, data, error } = useDashboardData(SCHOOL_DASHBOARD_USER_STATS_API);
    const [finalData, setFinalData] = useState([]);
    return <div className='dashboard__card' style={{ height: '586px' }}>
        <TableHeaderCard title='User Stats' month={true} padding='' data={finalData} />
        <div className='d-flex align-center justify-end gap10'>
            <LoginStatusIndicator status={1} title='Active' />
            <LoginStatusIndicator status={0} title='Fairly Active' />
            <LoginStatusIndicator status={-1} title='Inactive' />
        </div>
        <StackedChartCard detail={false} loading={loading} error={error} lists={data} setFinalData={setFinalData} />
    </div>
}

export default UserStats;