const SchoolTopers = ({ lists, keys }) => {
    return <td style={{ borderBottom: '.5px solid #D0CBFD' }}>
        <table>
            {lists.map((val, j) => <tr key={j}>
                <td>{keys === 'sln' ? String(j + 1).padStart(2, '0') : val[keys]}</td>
            </tr>)}
        </table>
    </td>
}

const SchoolTopPerfomersTable = ({ lists }) => {
    return <tbody>
        {
            Object.keys(lists).map(k => {
                const data = _getLists(lists[k]);
                return <tr key={k}>
                    <SchoolTopers lists={data} keys='sln' />
                    <td style={{ borderBottom: '.5px solid #D0CBFD' }}>{k}</td>
                    <SchoolTopers lists={data} keys='fullname' />
                    <SchoolTopers lists={data} keys='sln' />
                    <SchoolTopers lists={data} keys='rank_point' />
                </tr>
            })
        }
    </tbody>
}

export default SchoolTopPerfomersTable;

function _getLists(lists) {
    if (lists === null || typeof lists === 'undefined' || lists.length <= 0) return [];
    if (lists.length < 3) return lists;
    return lists.slice(0, 3);
}