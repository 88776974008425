import error from '../assets/error.png';

const PageNotFound = ({ height = 80, code = 404 }) => {
    return <div className='d-flex flex-col align-center justify-center gap3' style={{ width: '100%', height: `${height}vh` }}>
        <img src={error} alt='Error Art' style={{ height: '386px', width: '500px' }} />
        <div className='d-flex flex-col align-center justify-center gap1'>
            <div className='large__header4__bold'>Oops! Error {code}</div>
            <div className='large__body__regular' style={{ maxWidth: '576px', color: '#696F79', textAlign: 'center' }}>Sorry! We know you’re shooting for the stars... but our rocketship has crashed! The page you're looking for can’t be found</div>
        </div>
    </div>
}

export default PageNotFound;