import { createSlice } from '@reduxjs/toolkit'
import { getFileSoucePath, getFileType, getFormattedDuration } from '../utils/constants';

export const lessonSlice = createSlice({
    name: 'lesson',
    initialState: {
        tabs: [
            { title: '0 / 0 Classes', path: 'classes' },
            { title: '3 / 3 Daily Quests', path: 'daily-quest' },
        ],
        isLevel0: false,
        conductSession: false,
        isIaDone: false,
        isProjectDone: false,
        isUnlocked: false,
        classDetails: null,
        lessons: [],
        playingLesson: null,
        watchedClasses: [],
        progressPercentage: 0,
        completedLessons: 0,
        totalLessons: 0,
        uploadedProject: 0,
        totalProject: 0,
        completedDailyQuest: 0,
        totalDailyQuest: 0,
    },
    reducers: {
        updateTabs: (state, action) => {
            var tabs = [...state.tabs];
            const { classes, minCount, maxCount } = action.payload;
            if (classes) {
                tabs[0] = { ...tabs[0], title: `${minCount} / ${maxCount}  Classes` }
            } else {
                tabs[1] = { ...tabs[1], title: `${minCount} / ${maxCount} Daily Quests` }
            }
            state.tabs = tabs;
        },
        init: (state, action) => {
            if (action.payload !== null) {
                const { data, conductSession, isLevel0 } = action.payload;
                state.isLevel0 = isLevel0;
                state.conductSession = conductSession;
                state.isIaDone = data['is_ia_done'] === '1' || data['is_ia_done'] === 1;
                state.isProjectDone = data['is_project_done'] === '1' || data['is_project_done'] === 1;
                state.isUnlocked = data['is_unlocked'] === '1' || data['is_unlocked'] === 1;
                state.classDetails = {
                    id: data['class_details']['id'],
                    skillId: data['class_details']['skill_id'],
                    themeId: data['class_details']['theme_id'],
                    skillType: data['class_details']['skill_type'],
                    name: data['class_details']['name'],
                    thumbnail: data['class_details']['thumbnail'],
                    description: data['class_details']['description'],
                    duration: getFormattedDuration(data['class_details']['duration']),
                    isProjectExists: data['class_details']['is_project_exists'] === '1' || data['class_details']['is_project_exists'] === 1,
                    themeCategory: data['class_details']['theme_category'],
                };
                // set lessons & playing lesson
                var lessonList = [];
                var filtered = data['lesson_details'];
                if (conductSession && !isLevel0) {
                    filtered = filtered.filter(e => !['ia', 'project'].includes(e['class_type']));
                }
                var playing = data['playing_class'];
                if (filtered.length > 0) {
                    lessonList = filtered.map(e => {
                        var fileType = getFileType(e['file_type']);
                        return {
                            id: e['id'],
                            ilmId: e['ilm_id'],
                            themeId: e['theme_id'],
                            skillId: e['skill_id'],
                            className: e['class_name'],
                            classSource: getFileSoucePath(e['file_type'], e['class_source']),
                            classDuration: getFormattedDuration(e['class_duration']),
                            fileType: fileType,
                            classType: e['class_type'],
                            // -1 is locked, 0 is playing, 1 is watched, 2 is unlocked
                            status: (data['watched_classes'] ?? []).includes(e['id']) ? 1 : e['class_type'] === 'supporting_file' ? 2 : -1,
                        }
                    });
                    if (conductSession && !isLevel0 && ['bos', 'eos'].includes(data['class_details']['skill_type'])) {
                        lessonList = lessonList.filter(e => e['fileType'] !== 'ia');
                    }
                    if (playing == null || conductSession !== true) {
                        lessonList[0] = { ...lessonList[0], status: 0 };
                        state.lessons = lessonList;
                        playing = lessonList[0];
                    } else {
                        var index = lessonList.findIndex(e => e['id'] === playing['id']);
                        if (index >= 0) {
                            if (data['watched_classes'].includes(lessonList[index]['id'])) {
                                if (index + 1 < lessonList.length) {
                                    playing = { ...lessonList[index + 1], status: 0 };
                                } else {
                                    playing = { ...lessonList[index], status: 0 };
                                }
                            } else {
                                playing = { ...lessonList[index], status: 0 }
                            }
                        } else {
                            playing = { ...lessonList[0], status: 0 }
                        }
                    }
                    const playIndex = lessonList.findIndex(e => e.id === playing.id);
                    lessonList[playIndex] = playing;
                }
                state.lessons = lessonList;
                state.playingLesson = playing;
                state.watchedClasses = data['watched_classes'] ?? [];
                state.progressPercentage = data['progress_percentage'] !== null ? Number(data['progress_percentage']) : 0;
                state.completedLessons = data['completed_lessons'];
                state.totalLessons = data['total_lessons'];
                state.uploadedProject = data['uploaded_project'];
                state.totalProject = data['total_project'];
                state.completedDailyQuest = data['completed_daily_quest'];
                state.totalDailyQuest = data['total_daily_quest'];
            }
        },
        updateWatchedClasses: (state, action) => {
            var id = action.payload;
            if (!state.watchedClasses.toString().includes(id)) {
                var updated = [...state.watchedClasses, id];
                state.watchedClasses = updated;
            }
        },
        changeLesson: (state, action) => {
            var requestedIndex = action.payload;
            var updatedLessons = [...state.lessons];
            var playingID = state.playingLesson['id'];
            var playingIndex = updatedLessons.findIndex(e => e['id'] === playingID);
            if (requestedIndex < updatedLessons.length && playingID !== updatedLessons[requestedIndex]['id']) {
                var requested = { ...updatedLessons[requestedIndex], status: 0 };
                var watched = state.watchedClasses.toString().includes(playingID);
                var playing = { ...state.playingLesson, status: watched ? 1 : 2 };
                updatedLessons[requestedIndex] = requested;
                updatedLessons[playingIndex] = playing;
                state.playingLesson = requested;
                state.lessons = updatedLessons;

            }
        },
        clearLesson: (state) => {
            state.isLevel0 = false;
            state.conductSession = false;
            state.isIaDone = false;
            state.isProjectDone = false;
            state.isUnlocked = false;
            state.classDetails = null;
            state.lessons = [];
            state.playingLesson = null;
            state.watchedClasses = [];
            state.progressPercentage = 0;
            state.completedLessons = 0;
            state.totalLessons = 0;
            state.uploadedProject = 0;
            state.totalProject = 0;
            state.completedDailyQuest = 0;
            state.totalDailyQuest = 0;
        }
    },
});

export const { updateTabs, init, changeLesson, updateWatchedClasses, clearLesson } = lessonSlice.actions

export default lessonSlice.reducer;
