import DashboardTableList from "../../../componets/dashboard/dashboard-list-table";
import { POPULAR_ILMS_HEADER } from "../../../utils/constants";
import useDashboardData from "../../../hooks/use-dashboard-data";
import { POPULAR_ILMS_API } from "../../../utils/api-constants";

const PopularIlms = () => {
    const { loading, error, data } = useDashboardData(POPULAR_ILMS_API);
    return <DashboardTableList
        // extra={['Very Very Long Event Name Goes Here']}
        title='Popular ILMs'
        headers={POPULAR_ILMS_HEADER}
        lists={data}
        loading={loading}
        error={error}
        download={false}
        month={true}
    />
}

export default PopularIlms;