import { useSelector } from "react-redux";
import { upcomingEvents } from "../../redux/event-slice";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import EventInfoCard from "../../componets/event/event-info-card";
import { EVENTS_PATH } from "../../utils/route-constants";

const EventDetil = () => {
    const navigate = useNavigate();
    const event = useLoaderData();
    const upcoming = useSelector(upcomingEvents);
    const location = useLocation();
    const type = location.state;
    const params = useParams();

    return event ? <div className="event__preview d-flex flex-col gap12">
        {/* event info */}
        <div className="d-flex gap8">
            <EventInfoCard event={event} type={type} eventId={params['eventId']} />
            <div className="bg-surface-element fl8 radius3 p4 elevation5">
                <div className="depth3 radius2 p4 d-flex flex-col gap5" style={{ height: '100%', background: 'linear-gradient(118.69deg, rgba(244, 167, 29, 0.5) 0%, rgba(254, 211, 133, 0.5) 0.01%, rgba(255, 255, 255, 0.5) 52%, rgba(192, 184, 250, 0.5) 100%)', }}>
                    <div className="large__header3__bold">Event Name : {event['name']} </div>
                    <div className="large__body__regular">{event['description']}</div>
                </div>
            </div>
        </div>
        {/* upcoming events */}
        <div className="upcoming__info">
            <div className="large__header3__bold mb1">Other Upcoming Events</div>
            <div className="large__header5__regular">Events that might interest you.</div>
            <div className="d-flex gap8" style={{
                flexWrap: 'nowrap',
                overflow: 'scroll',
            }}>
                {
                    upcoming && upcoming.length > 0 ? upcoming.map(e => <div
                        key={e['id']}
                        className="bg-surface-element radius3 p3 elevation5"
                        style={{ height: '327px', width: '536px', flex: '0 0 auto' }}
                        onClick={() => {
                            navigate(`/${EVENTS_PATH}/${e.id}`, {
                                state: type
                            });
                        }}
                    >
                        <div className="bg-surface-element depth4" style={{ borderRadius: '10px', height: '100%', width: '100%', cursor: 'pointer' }}>
                            <img style={{ borderRadius: '4px', overlay: 'hidden' }} src={e['thumbnail']} alt={e['name']} />
                        </div>
                    </div>) : <div className="d-flex align-center justify-center">No upcoming events found</div>
                }
            </div>
        </div>
    </div> : null;
}

export default EventDetil;