const NameInput = ({ name, setName, disabled = false }) => {
    return <div className="input__container">
        <label htmlFor="name">Full Name</label>
        <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={disabled}
        />
    </div>
}

export default NameInput;