import { useState } from "react";

const FaqsList = ({ lists }) => {
    const [selected, setSelected] = useState(null);

    const update = (list) => {
        if (selected === null) {
            setSelected(list);
        } else {
            if (selected.id === list.id) {
                setSelected(null);
            } else {
                setSelected(list);
            }
        }
    }
    return <div className="d-flex flex-col gap4">
        {lists.map(list => <div key={list.id} className="d-flex flex-col align-start p3 elevation3 radius3 animate" style={{ cursor: 'pointer' }} onClick={() => update(list)}>
            <div className="large__body__bold">{list.faqs}</div>
            {(selected !== null && selected.id === list.ld) ? <div className="large__body__regular">{list.solution}</div> : null}
        </div>)}
    </div>
}

export default FaqsList;