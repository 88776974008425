import { Filter } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import useComponentVisible from '../hooks/use-component-visible';
import SearchFilter from "./search-filter";
import { filtersReducer, skillsReducer } from '../redux/reducers';
import { useEffect, useState } from 'react';
import { groupBy } from "../utils/utils";
import { resetFilters, updateFilters } from '../redux/global-slice';

const HistoryFilter = () => {
    const filters = useSelector(filtersReducer);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const options = useSelector(skillsReducer) ?? [];
    const [skills, setSkills] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (options && options.length > 0) {
            const result = groupBy(options, 'theme_category')
            setSkills(result);
        } else {
            setSkills([]);
        }
    }, [options]);

    return <div className="d-flex gap8 align-center justify-center">
        <SearchFilter />
        <div className="radius3 elevation3 d-flex gap4 align-center history__filter" ref={ref}>
            <div className="d-flex align-center justify-between" onClick={() => setIsComponentVisible(true)} style={{ cursor: 'pointer', height: '54px', width: '125px', padding: '6px 12px' }}>
                <div className="large__label1__bold" style={{ color: '#444B57' }}>Filter By</div>
                <Filter color="#3A2CB8" size={20} />
            </div>
            {isComponentVisible ? <div className="filter__card radius3 elevation3 px3 py4 bg-surface-element d-flex flex-col gap3">
                <div className="d-flex justify-between align-center">
                    <div className="d-flex align-center gap4">
                        <div className="large__label1__bold" style={{ color: '#3A2CB8' }}>Filter By</div>
                        <Filter color="#3A2CB8" size={20} />
                    </div>
                    <button className="link medium" onClick={() => dispatch(resetFilters())}>Reset All</button>
                </div>
                <div className="elevation1 bg-surface-element radius1" style={{ height: '4px' }} />
                {
                    Object.entries(skills).map((key, k) => {
                        const title = key[0] === '1' ? 'Core' : key[0] === '2' ? 'Special' : 'Creative';
                        return <div key={k} className="d-flex flex-col gap2">
                            <div className="large__label2__bold" style={{ color: '#4839CD' }}>{title}</div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '12px', rowGap: '8px' }}>
                                {
                                    key[1].map((theme, i) => <div
                                        key={i}
                                        className={`py1 px2 radius1 elevation1 ${filters.includes(theme['id']) ? 'large__caption3__regular' : 'large__caption3__bold'}`}
                                        style={{
                                            border: '0.6px solid #A197F2',
                                            cursor: 'pointer',
                                            background: filters.includes(theme['id']) ? '#A197F2' : 'var(--surfaceELementWhite)',
                                            color: filters.includes(theme['id']) ? '#ffffff' : '#696F79',
                                        }}
                                        onClick={() => dispatch(updateFilters(theme['id']))}
                                    >
                                        {theme['name']}
                                    </div>)
                                }
                            </div>
                        </div>
                    })
                }
            </div> : null}
        </div>
    </div>
}

export default HistoryFilter;
