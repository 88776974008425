import CircularChartCard from "../charts/circular-chart-card";
import LoginStatusIndicator from "../dashboard/login-status-indicator";
import { useSelector } from "react-redux";
import { dashboardUserStatsReducer } from "../../redux/reducers";
const HomeUserStats = () => {
  const data = useSelector(dashboardUserStatsReducer);
  return (
    <div
      className="d-flex flex-col gap4 depth4 bg-surface-element p3 radius3"
      style={{ width: "40%" }}
    >
      <div className="large__header6__bold">User Stats</div>
      <div className="fl1">
        <CircularChartCard height={120} loginStatus={false} />
      </div>
      <div className="d-flex align-center justify-center gap6">
        <LoginStatusIndicator
          status={1}
          title="Active Users"
          count={data.active}
          percentage={data.per_active}
        />
        <LoginStatusIndicator
          status={0}
          title="Fairly Active Users"
          count={data.fairy_active}
          percentage={data.per_fairy_active}
        />
        <LoginStatusIndicator
          status={-1}
          title="Inactive Users"
          count={data.inactive}
          percentage={data.per_inactive}
        />
      </div>
    </div>
  );
};

export default HomeUserStats;
