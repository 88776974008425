import { useState } from "react";
import PasswordInput from "../input/password-input";

const SetNewPasswordCard = () => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    return <div className='d-flex flex-col justify-between' style={{ height: '100%', width: '100%' }}>
        <div className='d-flex flex-col gap10'>
            <div>
                <div className='large__header1'>Set New Password</div>
                <div className='large__header6__regular'>Enter new password</div>
            </div>
            <PasswordInput label='New Password' password={password} setPassword={setPassword} />
            <PasswordInput label='Confirm New Password' password={newPassword} setPassword={setNewPassword} />
        </div>
        <button className='primary'>Confirm</button>
    </div>
}

export default SetNewPasswordCard;