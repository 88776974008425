import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabbar from "../../componets/tabbar";
import SelectGrade from "../../componets/input/select-grade";
import SelectSection from "../../componets/input/select-section";
import { getProjectListAPI } from "../../service/project-service";
import { updateLoading, updateRelodProjects } from "../../redux/global-slice";
import {
  APPROVED_PROJECTS_PATH,
  PENDING_PROJECTS_PATH,
  REJECTED_PROJECTS_PATH,
} from "../../utils/route-constants";
import {
  reloadProjectsReducer,
  selectedGradeReducer,
  selectedSectionReducer,
  userDetailReducer,
} from "../../redux/reducers";

// pending is 0, approved is 1, rejected is 2
const Projects = () => {
  const dispatch = useDispatch();
  const user = useSelector(userDetailReducer);
  const location = useLocation();
  const grade = useSelector(selectedGradeReducer);
  const section = useSelector(selectedSectionReducer);
  const [projects, setProjects] = useState([]);
  const reloadProjects = useSelector(reloadProjectsReducer);
  const [tabs, setTabs] = useState([
    { title: "- Pending Review", path: PENDING_PROJECTS_PATH },
    { title: "- Rejected Projects", path: REJECTED_PROJECTS_PATH },
    { title: "- Approved Projects", path: APPROVED_PROJECTS_PATH },
  ]);

  // get list of projects
  const getProjects = () => {
    if (grade.length > 0 && section.length > 0) {
      dispatch(updateLoading(true));
      getProjectListAPI(grade, section, user.school_code)
        .then((res) => {
          // var lists = res.filter(
          //   (e) =>
          //     !e["project_src"].endsWith(".png") &&
          //     !e["project_src"].endsWith(".gif") &&
          //     !e["project_src"].endsWith(".jpeg") &&
          //     !e["project_src"].endsWith(".jpg")
          // );
          setProjects(res);
          var items = [...tabs];
          items[0] = {
            ...items[0],
            title: `${
              res.filter((e) => e["project_status"] === "0").length
            } Pending Review`,
          };
          items[1] = {
            ...items[1],
            title: `${
              res.filter((e) => e["project_status"] === "2").length
            } Rejected Projects`,
          };
          items[2] = {
            ...items[2],
            title: `${
              res.filter((e) => e["project_status"] === "1").length
            } Approved Projects`,
          };
          setTabs(items);
        })
        .finally(() => {
          dispatch(updateLoading(false));
          dispatch(updateRelodProjects(false));
        });
    } else {
      setProjects([]);
    }
  };

  // recall the getProjects
  useEffect(() => {
    getProjects();
  }, [reloadProjects, location.pathname, grade, section]);

  return (
    <div className="projects d-flex flex-col gap6">
      <div className="projects__header d-flex align-center justify-between">
        <div className="large__header3__bold">Project Assessement</div>
        <div className="d-flex gap4">
          <SelectGrade isMass={false} title={false} />
          <SelectSection title={false} />
        </div>
      </div>
      <Tabbar tabs={tabs} />
      <Outlet
        context={{
          selected: grade && section,
          pendingProjects: projects.filter((e) => e["project_status"] === "0"),
          rejectedProjects: projects.filter((e) => e["project_status"] === "2"),
          approvedProjects: projects.filter((e) => e["project_status"] === "1"),
        }}
      />
    </div>
  );
};

export default Projects;
