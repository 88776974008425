import TooltipButton from "../tooltip-button"

const PerfomanceTrendTooltipContent = () => {
    return <TooltipButton style={{ width: '174px', left: '-80px' }}>
        <div className='large__caption3__regular' style={{ color: '#696F79' }}>
            Creative Skills not included
        </div>
    </TooltipButton>
}

export default PerfomanceTrendTooltipContent;