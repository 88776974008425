import { useLoaderData, useLocation, useParams } from "react-router-dom";
import EventInfoCard from "../../componets/event/event-info-card";
import { youtubeEmbedURL } from "../../utils/utils";
import ScormPlayerCard from "../../componets/players/scorm-player-card";

const EventPreview = () => {
    const event = useLoaderData();
    const location = useLocation();
    const type = location.state;
    const params = useParams();

    return event && <div className="d-flex align-start gap8" style={{ width: '100%' }}>
        <div className='fl10 d-flex flex-col gap4'>
            <div className="bg-surface-element radius6 p3 elevation3" style={{ aspectRatio: '16 / 9' }}>
                {/* <iframe
                    style={{ height: '100%', width: '100%', borderRadius: '16px' }}
                    title={event['name']}
                    src={youtubeEmbedURL(event['event_link'])}
                /> */}
                <ScormPlayerCard title={event['name']} src={youtubeEmbedURL(event['event_link'])} />
            </div>
            <div className='d-flex flex-col gap3'>
                <div className="large__header3__bold">Event Name : {event['name']} </div>
                <div className="large__body__regular">{event['description']}</div>
            </div>
        </div>
        <EventInfoCard event={event} type={type} eventId={params['eventId']} fromDetail={false} />
    </div>;
}

export default EventPreview;
