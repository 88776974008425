import LoginStatusChip from "../chips/login-status-chip"
import SkillOptionChip from "../chips/skill-option-chip"
import WatchStatusChip from "../chips/watch-status-chip"
import UserActiveStatus from "./user-active-status"
import IaStatusChip from "../chips/is-status-chip";
import ProjectSubmissionStatusChip from "../chips/project-submission-status-chip";
import ProjectApprovalStatusChip from "../chips/project-approval-status-chip";
import SchoolTopPerfomersTable from './school-top-perfomers-table';
import { getParsedGrade } from "../../utils/constants";
import { convertToPercentage, getDashboardDate, isObjectEmptyExcludingKeys } from "../../utils/utils";

const TableListData = ({ schoolToppers = false, headers, footer = [], lists, loading, error, style, children }) => {
    return <div className='table__wrapper' style={style}>
        <table id='dashboard__table' style={{ height: footer.length > 0 ? '100%' : 'auto' }}>
            <thead>
                {
                    children && <tr className='extra__th'>
                        <td className='large__body__bold' colSpan={headers.length}>{children}</td>
                    </tr>
                }
                <tr>
                    {/* sln should be max of 100px */}
                    {headers.map((e, i) => <th key={i} style={{ maxWidth: e.key === 'sln' ? '30px' : 'auto' }}>{e.title}</th>)}
                </tr>
            </thead>
            {
                schoolToppers ?
                    <SchoolTopPerfomersTable lists={lists} /> :
                    <tbody>
                        {
                            lists.map((list, i) => {
                                const isEmpty = isObjectEmptyExcludingKeys(list);
                                return isEmpty ? <tr key={i}>
                                    <td className='large__label2__regular' style={{ color: '#3E444F' }}>
                                        {'student_name' in list ? list['student_name'] : `${getParsedGrade(list['class'])} ${list['section']}`}
                                    </td>
                                    <td colSpan={headers.length - 1} className='large__label2__regular' style={{ color: '#3E444F' }}>NO SHOW</td>
                                </tr> : <tr key={i}>
                                    {headers.map((k, j) => <td key={j}>
                                        {
                                            k['key'] === 'sln' ? String(i + 1).padStart(2, '0') :
                                                k['key'] === 'logged_in_status' ?
                                                    <LoginStatusChip status={Number(list['logged_in_status'])} /> :
                                                    k['key'] === 'user_status' ?
                                                        <UserActiveStatus status={list['user_status'] === 'active' ? 1 : list['user_status'] === 'in_active' ? 0 : -1} /> :
                                                        k['key'] === 'no_of_views' ?
                                                            <div className='no__of__views__progress__card' style={{ width: `${convertToPercentage(Number(list['no_of_views']))}%` }}>{list['no_of_views']}</div> :
                                                            k['key'] === 'skill_option' ?
                                                                <SkillOptionChip status={Number(list['skill_option'])} /> :
                                                                k['key'] === 'watch_status' ?
                                                                    <WatchStatusChip status={list['watch_status']} /> :
                                                                    k['key'] === 'ia_status' ?
                                                                        <IaStatusChip status={list['ia_status']} /> :
                                                                        k['key'] === 'proj_submission_status' ?
                                                                            <ProjectSubmissionStatusChip status={list['proj_submission_status']} /> :
                                                                            k['key'] === 'proj_approval_status' ?
                                                                                <ProjectApprovalStatusChip status={list['proj_approval_status']} /> :
                                                                                k['key'] === 'created_date' ? <div className='large__label2__regular' style={{ textAlign: 'center', color: '#3E444F' }}>{getDashboardDate(new Date(list['created_date']))}</div> :
                                                                                    k['key'] === 'class_section' ?
                                                                                        <div className='large__label2__regular' style={{ textAlign: 'center', color: '#3E444F' }}>{getParsedGrade(list['class'])} {list['section']}</div> :
                                                                                        <div className='large__label2__regular' style={{ textAlign: 'center', color: '#3E444F' }}>{String(list[k.key]).padStart(2, '0')}</div>
                                        }
                                    </td>)}
                                </tr>
                            })
                        }
                    </tbody>
            }
            {
                lists && lists.length > 0 && footer && footer.length > 0 && <tfoot>
                    <tr>
                        {footer.map((e, i) => <td key={i}>{e}</td>)}
                    </tr>
                </tfoot>
            }
        </table>
        {(!loading && !error && lists.length <= 0) && <div className='d-flex align-center justify-center' style={{ ..._style, background: 'none' }}>Inadequate Product Usage to Display Data</div>}
        {(error || loading) && <div className='d-flex align-center justify-center radius3' style={{ ..._style, background: loading ? 'rgba(255, 255, 255, .5)' : 'none' }}>
            {error ? <span>{error}</span> : <div className='mini__loader' />}
        </div>}
    </div>
}

export default TableListData;

const _style = { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(255, 255, 255, .5)' }
