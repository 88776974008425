const EmailInput = ({ label = 'Email Address', email, setEmail, disabled = false }) => {
    return <div className="input__container">
        <label htmlFor="name">{label}</label>
        <input
            type="email"
            id="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={disabled}
        />
    </div>
}

export default EmailInput;