import IlmList from '../componets/ilms-list';
import Tabbar from "../componets/tabbar";
import HistoryFilter from '../componets/history-filter';
import { HISTORY_FAVOURITE as tabs } from '../utils/constants';
import { useEffect, useState } from "react";
import { getFavouriteILMs } from "../service/favourite-service";
import { useDispatch } from "react-redux";
import { updateLoading } from "../redux/global-slice";

const Favourites = () => {
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const getList = () => {
        dispatch(updateLoading(true));
        getFavouriteILMs()
            .then(res => setData(res))
            .finally(() => dispatch(updateLoading(false)));
    }

    useEffect(() => { getList() }, []);
    return <div className="d-flex flex-col gap8" style={{ width: '100%' }}>
        <Tabbar tabs={tabs} />
        <HistoryFilter />
        <IlmList lists={data} isFavourites={true} getList={getList} />
    </div>
}

export default Favourites;