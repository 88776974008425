const LinearProgress = ({ percent, isLesson }) => {
    return <div
        className={`${isLesson ? '' : 'elevation1'}`}
        style={{
            flex: isLesson ? 1 : '',
            padding: isLesson ? '0' : '2px',
            borderRadius: '12px',
            background: 'linear-gradient(132deg, #F7F7F8 -2.9%, #F4F4F6 102.91%)',
        }}>
        <div className="linear__progress">
            <div className="linear__empty__progress">
                <div className="linear__filled__progress" style={{ left: percent - 100 + "%" }} />
            </div>
        </div>
    </div>
}
export default LinearProgress;