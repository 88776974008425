import { X } from 'lucide-react';
import MeshBG from '../assets/mesh-bg.png';
import EmailInput from './input/email-input';
import { useState } from 'react';
const ResetPassword = ({ closeDialog }) => {
    const [email, setEmail] = useState('');
    return <div className="reset__password">
        <div className="reset__password__card d-flex flex-col justify-center">
            <img src={MeshBG} alt='Mesh BG' className='radius6' />
            <div className='d-flex flex-col gap16' style={{ padding: '32px 120px' }}>
                <div>
                    <div className='large__header3__bold'>Reset Password</div>
                    <div className='large__header6__regular'>Recieve password reset link.</div>
                </div>
                <EmailInput email={email} setEmail={setEmail} />
                <button className='primary'>Send Reset Link</button>
            </div>
            <button className='close__button' onClick={closeDialog}>
                <X size={20} color='#696F79' />
            </button>
        </div>
    </div>
}

export default ResetPassword;