import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import useComponentVisible from '../../hooks/use-component-visible';
import { Calendar } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { endDateReducer, startDateReducer } from '../../redux/reducers';
import { getDashboardDate } from '../../utils/utils';
import { useState } from 'react';
import { updateRangeDates } from '../../redux/global-slice';

const DateRangeInput = () => {
    const dispatch = useDispatch();
    const startDate = useSelector(startDateReducer);
    const endDate = useSelector(endDateReducer);
    const [dates, setDates] = useState({
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    })
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const handleSelect = (ranges) => {
        dispatch(updateRangeDates(ranges.selection));
        setDates({
            ...dates,
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
        });
    }

    return <div style={{ position: 'relative' }} ref={ref}>
        <div style={{ height: '44px', width: '262px' }} className='d-flex gap4 align-center'>
            <div className='large__body__bold' style={{ color: '#3E444F' }} >Range</div>
            <div
                style={{ padding: '10px 12px', boxShadow: 'var(--elevation2)', background: '#D0CBFD', cursor: 'pointer', height: '100%' }}
                className='d-flex align-center gap4 radius2'
                onClick={() => setIsComponentVisible(true)}
            >
                <div className='d-flex gap1'>
                    <span className='large__body__bold' style={{ color: '#3F3782', lineHeight: 1 }}>{getDashboardDate(startDate)}</span>
                    <span className='large__body__bold' style={{ color: '#3F3782', lineHeight: 1 }}>-</span>
                    <span className='large__body__bold' style={{ color: '#3F3782', lineHeight: 1 }}>{getDashboardDate(endDate)}</span>
                </div>
                <Calendar color='#3F3782' size={20} />
            </div>
        </div>
        {isComponentVisible && <DateRangePicker
            ranges={[dates]}
            onChange={handleSelect}
        />}
    </div>
}

export default DateRangeInput;