// 1 is core, 2 is special, 3 is creative
const ThemeCategory = ({ theme, shadow = false }) => {
    return <div
        className={`large__body__bold theme__category radius1 ${shadow ? 'elevation2' : ''}`}
        style={{ background: String(theme) === '1' ? '#EDE0FE' : String(theme) === '2' ? '#F3FEE0' : '#FEE0E0' }}
    >
        {String(theme) === '1' ? 'Core' : String(theme) === '2' ? 'Special' : 'Creative'}
    </div>
}

export default ThemeCategory;