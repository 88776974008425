import DashboardTableList from "../../../componets/dashboard/dashboard-list-table";
import { USER_TYPE_SUMMARY_HEADER } from "../../../utils/constants";
import useDashboardData from "../../../hooks/use-dashboard-data";
import { USER_TYPE_SUMMARY_API } from "../../../utils/api-constants";
import UserTypeSummaryTooltipContent from "../../../componets/dashboard/tooltip/user-type-summary-tooltip-content";

const UserTypeSummary = () => {
    const { loading, error, data } = useDashboardData(USER_TYPE_SUMMARY_API);
    return <DashboardTableList
        title='User Type Summary'
        headers={USER_TYPE_SUMMARY_HEADER}
        lists={data}
        loading={loading}
        error={error}
        month={true}
        tooltip={<UserTypeSummaryTooltipContent />}
    />
}

export default UserTypeSummary;