const ProjectSubmissionStatusChip = ({ status }) => {
  return (
    <div
      className={`d-flex align-center justify-center large__label2__${
        ["1", "2"].includes(status) ? "bold" : "regular"
      }`}
      style={{
        borderRadius: "4px",
        width: "100%",
        padding: "6px 5px",
        background: ["1", "2"].includes(status) ? "#E9EDFE" : "",
        color: ["1", "2"].includes(status) ? "#1E4FEE" : "#3E444F",
      }}
    >
      {status === "1" ? "Not Submitted" : status === "2" ? "Submitted" : "NA"}
    </div>
  );
};

export default ProjectSubmissionStatusChip;
