import { useDispatch, useSelector } from "react-redux";
import { dashboardEventsReducer, dashboardSelectedEventReducer } from "../../redux/reducers";
import { updateDashboardSelectedEvent } from '../../redux/dashboard-slice';
import { useEffect } from "react";
import { ChevronDown } from "lucide-react";

const SelectEvent = () => {
    const dispatch = useDispatch();
    const events = useSelector(dashboardEventsReducer);
    const selected = useSelector(dashboardSelectedEventReducer);

    useEffect(() => {
        return () => {
            dispatch(updateDashboardSelectedEvent(null));
        }
    }, []);

    return <div className={`input__container dashboard__input__container`}>
        <label htmlFor="event">Event</label>
        <select
            id="event"
            value={selected !== null ? selected['event_id'] : ''}
            onChange={e => {
                const val = e.target.value;
                const res = events.find(x => x['event_id'] === val);
                if (res) {
                    dispatch(updateDashboardSelectedEvent(res))
                }
            }}
            style={{ textTransform: 'capitalize', minWidth: '200px', maxWidth: '320px' }}
        >
            <option value='' disabled>Select Event</option>
            {events.map(e => <option key={e['event_id']} value={e['event_id']}>{e['event_name']}</option>)}
        </select>
        <ChevronDown color='#3F3782' />
    </div>
}

export default SelectEvent;