import { Outlet } from "react-router-dom";
import Tabbar from "../../../../componets/tabbar";
import { STUDENTS_REPORT_TABS } from "../../../../utils/constants";
import TableHeaderCard from "../../../../componets/dashboard/table-header-card";

const SelectedSkills = () => {
    return <div className='dashboard__card'>
        <TableHeaderCard title='Student Report' dateRange={true} padding='pt2 pb2' />
        <Tabbar tabs={STUDENTS_REPORT_TABS} />
        <Outlet />
    </div>
}

export default SelectedSkills;