import { AreaChart, Play } from "lucide-react";
import { NavLink, useLocation } from "react-router-dom";

const DashboardSideMenu = ({ menus }) => {
    const location = useLocation();
    return <div className='d-flex flex-col gap3'>
        <div className='metric__title radius2 d-flex align-center gap2'>
            <AreaChart size={24} color='#3A2CB8' />
            <div className='large__header5__bold' style={{ color: '#3A2CB8' }}>Metrics</div>
        </div>
        <div className='dasboard__side__menu d-flex flex-col gap2'>
            {menus.map((e, i) => {
                const parent = location.pathname.includes(e.path.split('/')[0]);
                return <NavLink key={i} to={e.path} className={`${parent && 'active'}`}>
                    {({ isActive, isPending, isTransitioning }) => (
                        <div className='d-flex align-center justify-between'>
                            <span className='large__body__bold'>{e.title}</span>
                            {(isActive || parent) && <Play fill='#6E9DF9' color='#6E9DF9' size={20} />}
                        </div>
                    )}
                </NavLink>
            })}
        </div>
    </div>
}

export default DashboardSideMenu;
