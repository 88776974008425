import { configureStore } from '@reduxjs/toolkit';
import globalSlice from './global-slice';
import authReducer from './auth-slice';
import lessonSlice from './lesson-slice';
import eventSlice from './event-slice';
import historySlice from './history-filter-slice';
import dashboardSlice from './dashboard-slice';
import { dashboardAPIService } from '../service/dashboard-service';

const store = configureStore({
  reducer: {
    global: globalSlice,
    auth: authReducer,
    lesson: lessonSlice,
    events: eventSlice,
    history: historySlice,
    dashboard: dashboardSlice,
    [dashboardAPIService.reducerPath]: dashboardAPIService.reducer,
  },
  middleware: (middleware) => middleware({ serializableCheck: false })
    .concat(dashboardAPIService.middleware),
});

export default store;