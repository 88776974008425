import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedGradeReducer, selectedSectionReducer, userClassesReducer } from "../../redux/reducers";
import { updateSelectedSection } from "../../redux/global-slice";
import { ChevronDown } from "lucide-react";

const SelectSection = ({ title = true, disabled = false, dashboard = false }) => {
    const dispatch = useDispatch();
    const grade = useSelector(selectedGradeReducer);
    const section = useSelector(selectedSectionReducer);
    const classes = useSelector(userClassesReducer);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        var filtered = classes.filter(e => e['grade'] === grade);
        setOptions(filtered);
    }, [grade, classes]);

    useEffect(() => {
        if (options.length > 0 && grade.length > 0) {
            dispatch(updateSelectedSection(options[0]['section']));
        } else {
            dispatch(updateSelectedSection(''));
        }
    }, [options, grade]);

    return (
        <div className={`input__container ${dashboard && 'dashboard__input__container'}`} style={{ minWidth: section?.length < 1 ? '150px' : '70px' }}>
            {title && <label htmlFor="themes">Select Section</label>}
            <select onChange={(e) => dispatch(updateSelectedSection(e.target.value))} id="themes" value={section} disabled={disabled}>
                <option value='' disabled>Select Section</option>
                {options.map((option, index) =>
                    <option key={index} value={option['section']}> {option['section']} </option>
                )}
            </select>
            <ChevronDown color='#3F3782' />
        </div>
    );
}

export default SelectSection;