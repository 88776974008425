import { apiRequestOptions, ENROLL_EVENT_API, EVENT_DETAILS_API, FETCH_EVENTS_API, GET_INDIVIDUAL_EVENT_API, JOIN_EVENT_API } from '../utils/api-constants';

// get list of events based on 
export async function getEventListAPI(userId, month, type, roleId, classGroup) {
    try {
        const options = apiRequestOptions({
            user_id: userId,
            month: month,
            type: type,
            role_id: roleId,
            class_group: classGroup,
        });
        return await fetch(FETCH_EVENTS_API, options)
            .then(res => res.json())
            .then(json => json['details'])
            .catch(e => { throw new Error(e) });
    } catch (e) {
        console.error(e);
    }
}

// enroll in event
export async function enrollEventService(eventId) {
    try {
        const _userId = window.localStorage.getItem('userId');
        const options = apiRequestOptions({
            user_id: _userId,
            event_id: eventId,
        });
        return await fetch(ENROLL_EVENT_API, options)
            .then(res => res.json())
            .then(json => json['success'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}

// join event
export async function joinEventService(eventId) {
    try {
        const _userId = window.localStorage.getItem('userId');
        const options = apiRequestOptions({
            user_id: _userId,
            event_id: eventId,
        });
        return await fetch(JOIN_EVENT_API, options)
            .then(res => res.json())
            .then(json => json['status'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}

// get individual event detail
export async function getIndividualEventDetailService(id) {
    try {
        const _userId = window.localStorage.getItem('userId');
        const options = apiRequestOptions({
            user_id: _userId,
            event_id: id,
        });
        return await fetch(GET_INDIVIDUAL_EVENT_API, options)
            .then(res => res.json())
            .then(json => json['details'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}

// get event attendees list 
export async function getEventAttendeesListService(eventId, schoolCode, grade, section) {
    try {
        const _userId = window.localStorage.getItem('userId');
        const options = apiRequestOptions({
            user_id: _userId,
            event_id: eventId,
            school_code: schoolCode,
            grade: grade,
            section: section,
        });
        return await fetch(EVENT_DETAILS_API, options)
            .then(res => res.json())
            .then(json => json['details'])
            .catch(e => { throw new Error(e) })
    } catch (e) {
        console.error(e)
    }
}