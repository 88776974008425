import CloudCard from './cloud-card';
const ConductSessionList = ({ lists = [], selected = false }) => {
    return (
        <div className="conduct__session__list radius4 p3 elevation4">
            <div className="conduct__session__list__content pos-relative radius3 ">
                <div className='conduct__session__list__mountain' />
                <div className='cloud__card__lists'>
                    {
                        selected ? lists && lists.length > 0 ? lists.map((list, i) =>
                            <CloudCard
                                key={list.id}
                                item={list}
                                style={{ top: `${_tops[i]}px`, left: `${_lefts[i]}px` }}
                            />)
                            : <div className='conduct__session__list__empty pos-absolute d-flex flex-col align-center justify-center'>
                                <div>No Skill Programs Found</div>
                            </div> : <div className='conduct__session__list__empty pos-absolute d-flex flex-col align-center justify-center'>
                            <div>Select Class & Section</div>
                            <div>To View Skill Programs</div>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
}

const _tops = [190, 90, 280, 190, 75];
const _lefts = [20, 300, 450, 740, 1020];

export default ConductSessionList;

