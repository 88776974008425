
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabbar from "../../componets/tabbar";
import { EVENTS_TABS as tabs } from '../../utils/constants';
import SelectMonth from "../../componets/input/select-month";
import { ENROLLED_EVENTS_PATH, UPCOMING_EVENTS_PATH } from "../../utils/route-constants";
import { updateLoading } from "../../redux/global-slice";
import { getEventListAPI } from "../../service/event-service";
import { setEventsList } from "../../redux/event-slice";
import { selectedMonthReducer, userClassesReducer, userDetailReducer } from '../../redux/reducers';
import { isEventEnded } from "../../utils/utils";

// 1 is upcoming, 3 is enrolled, 4 is recording
const Events = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [type, setType] = useState(1);
    const month = useSelector(selectedMonthReducer);
    const detail = useSelector(userDetailReducer);
    const classes = useSelector(userClassesReducer);

    useEffect(() => {
        if (location.pathname.includes(UPCOMING_EVENTS_PATH)) {
            setType(1);
        } else if (location.pathname.includes(ENROLLED_EVENTS_PATH)) {
            setType(3);
        } else {
            setType(4);
        }
    }, [location]);



    useEffect(() => {
        // get events list
        const getList = () => {
            dispatch(updateLoading(true))
            const filtered = classes.map(e => e['level']);
            const levels = [...new Set(filtered)].toString();
            getEventListAPI(detail['id'], month, type, detail['role_id'], levels)
                .then(res => {
                    var data = [];
                    if (res) {
                        const filtered = res.filter(e => ['teacher', 'principal', 'all'].includes(e['role']));
                        if (filtered && filtered.length > 0) {
                            for (let i = 0; i < filtered.length; i++) {
                                const ended = isEventEnded(filtered[i]['scheduled_date'], filtered[i]['duration']);
                                if (type === 4) data.push(filtered[i]);
                                else if (!ended) data.push(filtered[i])
                            }
                        } else {
                            data = [];
                        }
                    }
                    dispatch(setEventsList({ type: type, data: data }))
                })
                .finally(() => dispatch(updateLoading(false)));
        }
        getList()
    }, [detail, type, month, classes]);

    return <div className="events" style={{ width: '100%' }}>
        <Tabbar tabs={tabs} />
        <div className="mt8" style={{ width: '210px' }}>
            <SelectMonth title={null} />
        </div>
        <Outlet />
    </div>
}

export default Events;