import DateRangeInput from "../input/date-range-input";
import SelectMonth from '../input/select-month';
import SelectEvent from '../input/select-event';
import SelectGrade from '../input/select-grade';
import DownloadDropdown from "./download-dropdown";

const TableHeaderCard = ({
    title,
    download = true,
    month = false,
    event = false,
    grade = false,
    dateRange = false,
    padding = 'pt5 px3 pb2',
    tooltip,
    data = [],
}) => {
    return <div className={`d-flex align-center justify-between ${padding}`}>
        <div className='d-flex gap2 align-center'>
            <span className='large__header6__bold' style={{ color: '#3A2CB8' }}>{title}</span>
            {tooltip}
        </div>
        <div className='d-flex align-center gap10'>
            {dateRange && <DateRangeInput />}
            {month && <SelectMonth dashboard={true} isPrevious={true} />}
            {event && <SelectEvent />}
            {grade && <SelectGrade dashboard={true} />}
            {download && <DownloadDropdown data={data} />}
        </div>
    </div>
}

export default TableHeaderCard;