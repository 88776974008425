import EmailInput from '../input/email-input';
import { useState } from 'react';

const ResetPasswordCard = () => {
    const [email, setEmail] = useState('');
    return <div className='d-flex flex-col justify-between' style={{ height: '100%', width: '100%' }}>
        <div className='d-flex flex-col gap10'>
            <div>
                <div className='large__header1'>Reset Password</div>
                <div className='large__header6__regular'>Recieve password reset link.</div>
            </div>
            <EmailInput label='Registered Email ID' email={email} setEmail={setEmail} />
        </div>
        <button className='primary'>Send Reset Link</button>
    </div>
}

export default ResetPasswordCard;