import { Mail, Phone } from "lucide-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateLoading } from "../../redux/global-slice";
import { createTicketAPI } from "../../service/support-service";
import { useNavigate } from "react-router-dom";
import { SUPPORT_PATH } from "../../utils/route-constants";

const RaiseTicket = () => {
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (query.trim().length > 0) {
            dispatch(updateLoading(true));
            createTicketAPI(query.trim()).then(res => {
                if (String(res['status']) === '200') {
                    alert('Ticket submitted successfully! Ticket ID:- ' + res['details']);
                    navigate(`/${SUPPORT_PATH}`);
                } else {
                    alert('Something went wrong!');
                }
            }).finally(() => dispatch(updateLoading(false)));
        }
    }

    return <div className="p6 elevation5 bg-surface-element radius6 d-flex flex-col gap6" style={{ margin: '0 auto', width: '700px', maxHeight: '508px' }}>
        <div>
            <div className="large__header3__bold">Contact Us</div>
            <div className="large__header5__regular">For any enquiries</div>
        </div>
        <div className="d-flex justify-between" style={{ width: '100%' }}>
            <div className="fl1 d-flex align-center gap4">
                <div className="elevation2 radius1 d-flex align-center justify-center" style={{ height: '32px', width: '32px', background: '#F2F2F2' }}>
                    <Phone color="#7364EC" size={22} />
                </div>
                <div className="large__body__bold">+91-8884149888</div>
            </div>
            <div className="fl1 d-flex align-center gap4">
                <div className="elevation2 radius1 d-flex align-center justify-center" style={{ height: '32px', width: '32px', background: '#F2F2F2' }}>
                    <Mail color="#7364EC" size={22} />
                </div>
                <div className="large__body__bold">support@ulipsu.com</div>
            </div>
        </div>
        <div>
            <div className="large__header3__bold">Raise a Support Ticket</div>
            <div className="large__header5__regular">Write your concern to us.</div>
        </div>
        <div className='input__container'>
            <textarea
                id="message"
                name="message"
                placeholder="Write your concern here"
                rows="5" cols="50"
                value={query}
                onChange={(e) => setQuery(e.target.value)} />
        </div>
        <button className="primary" onClick={handleSubmit}>Submit</button>
    </div>
}
export default RaiseTicket;