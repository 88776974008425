const UserActiveStatus = ({ status }) => {
    return <div
        className='large__label2__bold'
        style={{
            padding: '6px 5px 6px 5px',
            borderRadius: '4px',
            background: status === 1 ? '#E2EFE2' : status === 0 ? '#FBE3B9' : '#FBE2DA',
            color: status === 1 ? '#059D05' : status === 0 ? '#F3A61D' : '#FF7448',
            textAlign: 'center',
        }}
    >
        {status === 1 ? 'Active' : status === 0 ? 'Fairly Active' : 'Inactive'}
    </div>
}

export default UserActiveStatus;