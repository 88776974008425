import DashboardTableList from "../../../componets/dashboard/dashboard-list-table";
import { EVENTS_HEADER } from "../../../utils/constants";
import useDashboardData from "../../../hooks/use-dashboard-data";
import { EVENT_DETAILS_API } from "../../../utils/api-constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardEvents, updateDashboardSelectedEvent } from '../../../redux/dashboard-slice';
import { dashboardSelectedEventReducer } from "../../../redux/reducers";
import { getEventAttendeesListService } from "../../../service/event-service";
import { updateLoading } from "../../../redux/global-slice";

const DashboardEvents = () => {
    const dispatch = useDispatch();
    const selectedEvent = useSelector(dashboardSelectedEventReducer);
    const { loading, error, data, schoolCode, grade, section } = useDashboardData(EVENT_DETAILS_API);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            dispatch(updateDashboardEvents(data));
            dispatch(updateDashboardSelectedEvent(data[0]));
        }
    }, [data]);

    useEffect(() => {
        const getLists = () => {
            dispatch(updateLoading(true));
            getEventAttendeesListService(selectedEvent['event_id'], schoolCode, grade, section)
                .then(res => {
                    if (res !== null && res.length > 0) {
                        var items = res.split(',').map(e => ({ name: e }));
                        setUsers(items);
                    } else {
                        setUsers([]);
                    }
                }).finally(() => dispatch(updateLoading(false)))
        }

        if (selectedEvent !== null && grade.length > 0 && section.length > 0) {
            getLists();
        } else {
            setUsers([]);
        }
    }, [selectedEvent, grade, section]);

    return <DashboardTableList
        title='Events'
        headers={EVENTS_HEADER}
        lists={users}
        loading={loading}
        error={error}
        event={true}
    >{selectedEvent ? selectedEvent['event_name'] : 'Event Name'}</DashboardTableList>
}

export default DashboardEvents;