const TicketStatus = ({ status }) => {
    const val = _getStatus(status);
    return <div className="small__caption__caption px2 py1 radius1" style={{
        background: `${val === -1 ? '#FEEAE4' : val === 1 ? '#E7FEE7' : '#FEF8EC'}`,
        color: `var(${val === -1 ? '--alertDander' : val === 1 ? '--alertSuccess' : '--alertWarning'})`,
        border: `0.4px solid var(${val === -1 ? '--alertDander' : val === 1 ? '--alertSuccess' : '--alertWarning'})`
    }}>
        {val === 1 ? 'Resolved' : val === -1 ? 'Pending' : 'Open'}
    </div>
}

export default TicketStatus;

function _getStatus(status) {
    switch (status) {
        case "closed":
            return 1;
        case "pending":
            return -1;
        default:
            return 0;
    }
}