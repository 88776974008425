import { useState } from 'react'
import LoginArrow from '../../assets/login-arrow.png'
import UserIdInput from '../../componets/input/userid-input'
import PasswordInput from '../../componets/input/password-input'
import { useAuth } from '../../hooks/use-auth'
import ResetPasswordCard from '../../componets/auth/reset-password-card'
import BlurActionCard from '../../componets/auth/blur-action-card'
import SetNewPasswordCard from '../../componets/auth/set-new-password-card'
import ContactusCard from '../../componets/auth/contactus-card'
import LoginArt from '../../assets/login_art.png'

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [resetPassword, setResetPassword] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [conatct, setContact] = useState(false);

    const toggle = (reset, newPass, contact) => {
        setResetPassword(reset);
        setNewPassword(newPass);
        setContact(contact);
    }

    const { login, error, loading } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        login(username.trim(), password.trim());
    }

    return (
        <div className="login__screen m3">
            <div className="login__card p3 radius6 elevation6 d-flex">
                <div className="login__card__form d-flex flex-col pt10 px6 radius4">
                    <div className='login__overlay' />
                    <div className='mb6 d-flex flex-col align-center justify-center'>
                        <img style={{ height: '80px', objectFit: 'contain' }} src={LoginArrow} alt='Login Arrow' />
                        <p className="large__header1">Welcome to Ulipsu !</p>
                        <p className="large__header6__regular">Please login with your school credentials</p>
                    </div>
                    <form onSubmit={handleLogin} className='d-flex flex-col justify-center align-center gap4'>
                        <UserIdInput userId={username} setUserId={setUsername} />
                        <PasswordInput password={password} setPassword={setPassword} />
                        <div className='mt6' />
                        {error && <div className='small__caption__label__small' style={{ color: 'red' }}>{error}</div>}
                        <div className='d-flex flex-col gap3' style={{ width: '100%' }}>
                            <button className='primary' style={{ width: '100%' }} type='submit' disabled={loading}>Login</button>
                            {/* <div className='d-flex align-center justify-center gap2 py2'>
                                <span className='large__body__regular' style={{ color: '#82868E' }}>Having  Trouble ?</span>
                                <span className='large__body__bold' style={{ color: '#7364EC', cursor: 'pointer' }} onClick={() => setResetPassword(true)}>Contact Us</span>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
            <img src={LoginArt} alt='Login Art' style={{ objectFit: 'contain', maxHeight: '100vh' }} />

            {/* reset password && set new password */}
            {(resetPassword || newPassword || conatct) && <BlurActionCard toggle={toggle} contact={resetPassword}>
                {resetPassword && <ResetPasswordCard />}
                {newPassword && <SetNewPasswordCard />}
                {conatct && <ContactusCard />}
            </BlurActionCard>}
        </div>
    );
}
export default Login;