import { SELECTED_OPTED_SKILLS_HEADER } from "../../../../utils/constants";
import useDashboardData from "../../../../hooks/use-dashboard-data";
import TableListData from "../../../../componets/dashboard/table-list-data";
import { SCHOOL_OPTED_SKIILS_API } from "../../../../utils/api-constants";

const SchoolOptedSkills = () => {
    const { loading, data, error } = useDashboardData(SCHOOL_OPTED_SKIILS_API);
    return <TableListData
        headers={SELECTED_OPTED_SKILLS_HEADER}
        loading={loading}
        lists={data}
        error={error}
        style={{ padding: 0, maxHeight: '49vh' }}
    />
}

export default SchoolOptedSkills;