import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom';
import store from './redux/store';
import { appRouter } from './App.js';
import ScrollToTop from './utils/scroll-to-top.js';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={appRouter}>
        <ScrollToTop />
      </RouterProvider>
    </Provider>
  </React.StrictMode>
);
