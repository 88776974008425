// global
export const globalLoadingReducer = (state) => state.global.loading;
export const carouselsListReducer = (state) => state.global.carousels;
export const selectedGradeReducer = (state) => state.global.selectedGrade;
export const selectedSectionReducer = (state) => state.global.selectedSection;
export const filtersReducer = (state) => state.global.filteres;
export const reloadProjectsReducer = (state) => state.global.reloadProjects;
export const startDateReducer = (state) => state.global.startDate;
export const endDateReducer = (state) => state.global.endDate;
export const selectedMonthReducer = (state) => state.global.selectedMonth;
// auth
export const isMassReducer = (state) => state.auth.isMass;
export const userIdReducer = (state) => state.auth.userId;
export const userDetailReducer = (state) => state.auth.userDetail;
export const userSchoolCodeReducer = (state) => state.auth.schoolCode;
export const userClassesReducer = (state) => state.auth.classes;
export const skillsReducer = (state) => state.auth.skills;
export const schoolSkillsReducer = (state) => state.auth.schoolSkills;

// lesson
export const isLevel0Reducer = (state) => state.lesson.isLevel0;
export const classroomTabsReducer = (state) => state.lesson.tabs;
export const conductSessionReducer = (state) => state.lesson.conductSession;
export const isIaDoneReducer = (state) => state.lesson.isIaDone;
export const isProjectDoneReducer = (state) => state.lesson.isProjectDone;
export const isUnlockedReducer = (state) => state.lesson.isUnlocked;
export const classDetailsReducer = (state) => state.lesson.classDetails;
export const lessonsReducer = (state) => state.lesson.lessons;
export const playingLessonReducer = (state) => state.lesson.playingLesson;
export const watchedClassesReducer = (state) => state.lesson.watchedClasses;
export const progressPercentageReducer = (state) =>
  state.lesson.progressPercentage;
export const completedLessonsReducer = (state) => state.lesson.completedLessons;
export const totalLessonsReducer = (state) => state.lesson.totalLessons;
export const uploadedProjectReducer = (state) => state.lesson.uploadedProject;
export const totalProjectReducer = (state) => state.lesson.totalProject;
export const completedDailyQuestReducer = (state) =>
  state.lesson.completedDailyQuest;
export const totalDailyQuestReducer = (state) => state.lesson.totalDailyQuest;

// dashboard
export const dashboardEventsReducer = (state) => state.dashboard.events;
export const dashboardSelectedEventReducer = (state) =>
  state.dashboard.selectedEvent;
export const dashboardTrendsListReducer = (state) => state.dashboard.trendsList;
export const dashboardSelectedYearReducer = (state) =>
  state.dashboard.selectedYear;
export const dashboardLoginStatusReducer = (state) =>
  state.dashboard.loginStatus;
export const dashboardUserStatsReducer = (state) => state.dashboard.userStats;
