import { useNavigate } from 'react-router-dom';
import CompletedCloud from '../../assets/cloud/completed-cloud.png';
import ActiveSmallCloud from '../../assets/cloud/active-small-cloud.png';
import ActiveSmallCloud0 from '../../assets/cloud/active-small-cloud0.png';
import { CONDUCT_SESSION_PATH } from '../../utils/route-constants';
import ActiveBottomLayerCloud from '../../assets/cloud/active-bottom-layer-cloud.png';
import ActiveTopLayerCloud from '../../assets/cloud/active-top-layer-cloud.png';
const CloudCard = ({ item, style }) => {
    const navigate = useNavigate();
    const unlocked = item['is_unlocked'] === '1';
    const active = item['is_active'] === '1';
    return <div
        className={`animate cloud__card ${unlocked ? '' : 'disabled__cloud'}`}
        style={{ ...style, cursor: unlocked ? 'pointer' : 'not-allowed' }}
        onClick={() => {
            if (unlocked) {
                navigate(`/${CONDUCT_SESSION_PATH}/${item.program_id}`);
            }
        }}
    >
        <div className='cloud__card_content'>
            <div className='cloud__content__small__cloud' style={{ height: unlocked && active ? '80px' : '65px' }}>
                <img
                    src={unlocked && active ? ActiveSmallCloud : ActiveSmallCloud0}
                    alt={item.name} style={{ position: 'relative' }}
                />
                <div
                    className='d-flex justify-center align-center pos-absolute'
                    style={{
                        top: '16px',
                        right: unlocked && active ? '38px' : '22px',
                        color: unlocked ? '#996491' : '#6B6B6B'
                    }}
                >{item['priority']}</div>
            </div>
            <img
                className='bottom__layer'
                src={active ? ActiveBottomLayerCloud : CompletedCloud}
                alt={item.name}
            />
            <img
                className='cloud__icon'
                src={item['icon']}
                alt={item.name}
                style={{ left: !active ? 'calc(50% - 38px)' : 'calc(50% - 48px)' }}
            />
            <img
                className='top__layer'
                src={ActiveTopLayerCloud}
                alt={item.name}
                style={{ left: !active ? '2px' : '-11px' }}
            />
            <div className='d-flex justify-center align-center cloud__card_content__title px6'>{item.name}</div>
        </div>
    </div>
}
export default CloudCard;