import { Info } from "lucide-react";
import { useEffect } from "react";

// type -1 danger, 0 is success, 1 is warning
const ToastMessage = ({ type = 0, setToast, toast = false, title, text, seconds = 2500 }) => {

    useEffect(() => {
        if (toast === true) {
            setTimeout(() => setToast(false), seconds);
        }
    }, [toast]);

    return toast && <div className={`d-flex align-start gap3 radius4 ${type === 0 ? 'toast__message__neutral' : 'toast__message__danger'}`}>
        <Info size={32} fill={`${type === 0 ? '#6E9DF9' : '#FF7448'}`} color='white' />
        <div className='d-flex flex-col gap1'>
            <div className='large__header6__bold' style={{ color: '#3E444F' }}>{title}</div>
            <div className='large__body__regular' style={{ color: '#3E444F' }}>{text}</div>
        </div>
    </div>;
}

export default ToastMessage;