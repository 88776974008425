import TableListData from "../../../../componets/dashboard/table-list-data";
import useDashboardData from "../../../../hooks/use-dashboard-data";
import { STUDENTS_REPORT_DETAIL_API } from "../../../../utils/api-constants";
import { STUDENTS_REPORT_HEADER } from "../../../../utils/constants";

const StudentWiseReport = () => {

    const { loading, data, error } = useDashboardData(STUDENTS_REPORT_DETAIL_API);

    return <TableListData
        headers={STUDENTS_REPORT_HEADER}
        loading={loading}
        error={error}
        lists={data}
        style={{ padding: 0, maxHeight: '49vh' }}
    />
}

export default StudentWiseReport;