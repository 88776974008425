import { useState } from "react";
import TopPerfomersViewMore from './top-perfomers-view-more';
import useDashboardData from "../../hooks/use-dashboard-data";
import { TOP_PERFOMERS_API } from "../../utils/api-constants";
import { getParsedGrade } from '../../utils/constants';
import LoadingError from './loading-error';
import IndividualTopPerfomanceCard from "./individual-top-performance-card";
import { ChevronRight } from "lucide-react";
import { rearrangeFirstThreeRank } from '../../utils/utils'

const TopPerfomersCard = ({ viewMore = false }) => {
    const { loading, error, data } = useDashboardData(TOP_PERFOMERS_API, { type: '1' });
    const [dialog, setDialog] = useState(false);

    return <div className='dashboard__card animate' style={{ minHeight: '228px' }}>
        <div className='d-flex align-center justify-between'>
            <h6>Top Performers</h6>
            {viewMore && <div
                className='d-flex align-center justify-center gap1'
                style={{ cursor: 'pointer' }}
                onClick={() => setDialog(true)}
            >
                <span className='large__body__bold' style={{ color: '#E539B5' }}>View More</span>
                <ChevronRight color='#E539B5' size={20} />
            </div>}
        </div>
        <div className='top__perfomers__content fl1'>
            {
                (loading || error) ?
                    <LoadingError error={error} loading={loading} /> :
                    data.length > 0 ?
                        rearrangeFirstThreeRank(data).map((user, i) => <IndividualTopPerfomanceCard
                            large={user['rank_no'] === '1' || user['rank_no'] === 1}
                            key={user['user_id']}
                            rank={user['rank_no']}
                            name={user['fullname']}
                            grade={`Grade ${getParsedGrade(user['grade'] ?? '1')} ${user['section']}`}
                            points={user['points']}
                        />) :
                        [2, 1, 3].map(e => <IndividualTopPerfomanceCard
                            large={e === 1}
                            key={e}
                            rank={e}
                            isEmpty={true}
                        />)
            }
        </div>
        {dialog && <TopPerfomersViewMore closeDialog={setDialog} />}
    </div >
}

export default TopPerfomersCard;
