import { useSelector } from "react-redux";
import { getParsedGrade } from '../../utils/constants';
import { selectedGradeReducer, selectedSectionReducer } from "../../redux/reducers";

const JourneyGrade = () => {
    const grade = useSelector(selectedGradeReducer);
    const section = useSelector(selectedSectionReducer);
    return <div className="radius3 d-flex gap2 align-center justify-center journey__grade__indicator">
        <div className="large__header6__bold">Grade</div>
        <div className="large__header6__bold">{getParsedGrade(grade)}</div>
        <div className="large__header6__bold">{section}</div>
    </div>
}

export default JourneyGrade;