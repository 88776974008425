import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GET_GRADE_SECTION_DATA_API, GET_LEARNING_PROGRESS_API, GET_LOGIN_STATUS_DETAIL_API, TOP_PERFOMERS_API } from '../utils/api-constants';

export const dashboardAPIService = createApi({
    reducerPath: 'dashboardAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().global.accessToken ?? 'f582adc593fe72fd6ff9aa85d446395c';
            headers.set('access_token', token)
            headers.set('Accept', 'application/json');
            headers.set('Content-Type', 'application/json');
            return headers
        },
    }),
    prepareHeaders: (headers) => headers,
    tagTypes: ['Dashboard'],
    endpoints: (builder) => ({
        // get dashboard header info
        getGradeSectionData: builder.query({
            query: (userId) => ({
                url: GET_GRADE_SECTION_DATA_API,
                method: 'POST',
                body: JSON.stringify({ 'user_id': userId }),
            }),
            transformResponse: (response) => response['details'],
        }),
        // get top perfomers list for grade dashboard
        getTopPerfomersGradeDashboard: builder.query({
            query: (userId, schoolCode, grade, section, type) => ({
                url: TOP_PERFOMERS_API,
                method: 'POST',
                body: JSON.stringify({
                    'user_id': userId,
                    'school_code': schoolCode,
                    'grade': grade,
                    'section': section,
                    'type': type,
                }),
            }),
            transformResponse: (response) => response['details'],
        }),
        // dashboard learning progress
        getLearningProgressGradeDashboard: builder.query({
            query: (userId, schoolCode, grade, section, type) => ({
                url: GET_LEARNING_PROGRESS_API,
                method: 'POST',
                body: JSON.stringify({
                    'user_id': userId,
                    'school_code': schoolCode,
                    'grade': grade,
                    'section': section,
                    'type': type,
                }),
            }),
            transformResponse: (response) => response['details'],
        }),
        // get grade dashboard login details
        getGradeDashboardLoginDetails: builder.query({
            query: (userId, schoolCode, grade, section) => ({
                url: GET_LOGIN_STATUS_DETAIL_API,
                method: 'POST',
                body: JSON.stringify({
                    'user_id': userId,
                    'school_code': schoolCode,
                    'grade': grade,
                    'section': section,
                }),
            }),
            transformResponse: (response) => response['details'],
        }),
    }),
});

export const {
    useGetGradeSectionDataQuery,
    useGetTopPerfomersGradeDashboardQuery,
    useGetGradeDashboardLoginDetailsQuery,
} = dashboardAPIService;


// const _getQuery = (url, body) => {
//     return {
//         url: url,
//         method: 'POST',
//         body: JSON.stringify({
//             user_id: body['id'],
//             school_code: body['school_code'],
//             grade: body['grade'],
//             section: body['section'],
//             month: body['month'],
//             from: body['from'],
//             to: body['to'],
//             from_date: body['from'],
//             to_date: body['to'],
//             type: body['type'],
//         })
//     }
// }